import React, { useEffect, useState } from "react";

import Comp_overview from "./Comp_overview";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { StoreSideBar } from "../store/Slices/SIdeSlice";
import Comp_profile from "./Comp_profile";
import {
  addInstagramCompetitor,
  getCompetitorProfiles,
} from "../services/competitor";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/DD/MM";
const MonthsString = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export default function Competitor() {
  const [profileTab, setProfileTab] = useState("Overview");

  const [openCompetitor, setOpenCompetitor] = useState();
  const [complatform, setCompPlatform] = useState("FB");
  const [searchBrand, setsearchBrand] = useState();

  const [showFilter, setshowFilter] = useState(false);
  const [until, setUntil] = useState(null);
  const [since, setSince] = useState(null);

  const [InstaProfiledate, setInstaPrfileData] = useState(null);
  const dispatch = useDispatch();

  const d = new Date();
  var months = (d.getMonth() + 1).toString().padStart(2, "0");
  var day = d.getDate().toString().padStart(2, "0");

  const Sinceselector = useSelector((state) => state.SinceDates);
  const untilSelector = useSelector((state) => state.UntilDates);

 
  const fetchCompetitorProfiles = async () => {
    try {
      let date={from:since,to:until}
      if(!since && !until){
       
        const d = new Date();

        // Set date to the first day of the current month
        d.setDate(1);
        date={from:d,to:new Date()}
        setSince(d);
        setUntil(new Date());
      }
      const response = await getCompetitorProfiles(date);
      setInstaPrfileData(response.data);
    } catch (error) {
      console.error("❌ Failed to fetch competitor profiles:", error);
    }
  };

  const searchCompetitor = async () => {
    try {
      const response = await addInstagramCompetitor(searchBrand);
      if (response) {
        setOpenCompetitor(false);
        fetchCompetitorProfiles();
      }
    } catch (err) {
      console.error("Error in searchCompetitor:", err);
    }
  };

  useEffect(() => {
    dispatch(StoreSideBar("Report"));
    fetchCompetitorProfiles();
  }, [untilSelector, Sinceselector]);

  return (
    <div>
      <div className="approval_header performace_header">
        <div className="performance_inner">
          <div className="performace_subhead align-items-center">
            <div>
              <h4>Profile Performance</h4>
            </div>
            <div className="mx-2">
              <i class="fa fa-star fa-1x" aria-hidden="true"></i>
            </div>
          </div>

          <span className="date_txt">
  Activity from{" "}
  {since
    ? `${new Date(since ).toLocaleString("default", { month: "long" })} 
       ${new Date(since).getDate()}, 
       ${new Date(since ).getFullYear()}`
    : "Loading..."}
  {" - "}
  {until  
    ? `${new Date( until).toLocaleString("default", { month: "long" })} 
       ${new Date( until).getDate()}, 
       ${new Date( until).getFullYear()}`
    : "Loading..."}
  <label className="highlight_txt"> (multiple time zones)</label>
</span>
        </div>

        <div className="d-flex align-items-center">
          <div>
            <span className="Inbox_remaining performace_date">
              <RangePicker
                format={dateFormat}
                style={{ backgroundColor: "transparent", border: "none" }}
                value={
                  since && until
                    ? console.log([dayjs(new Date(since), dateFormat), dayjs(until, dateFormat),since])
                    : []
                }
                onChange={(e) => {
                    console.log(e)
                    if(e){

                        setSince(new Date(e[0].$d));
                        setUntil(new Date(e[1].$d));
                    }
                }}
              />
            </span>
          </div>

          <div>
            <span className="Inbox_remaining">
              <i class="fa fa-ellipsis-h"></i>
            </span>
          </div>

          <div>
            <span className="Inbox_remaining">
              <i class="fa fa-upload" style={{ marginRight: "5px" }}></i>
              Export
            </span>
          </div>

          <div>
            <button
              className="Inbox_remaining Filter_btn"
              onClick={() => {
                setshowFilter(!showFilter);
              }}
            >
              <img src={require("../Assets/Filter.png")} />
              Filters
            </button>
          </div>
        </div>
      </div>

      {showFilter ? (
        <table className="filter_table">
          <tr>
            <td>
              <label>Sources</label>
              <select>
                <option>Viewing all</option>
              </select>
            </td>

            <td></td>

            <td></td>

            <td className="reset_message">
              <button className="btn reset_btn">Clear</button>
            </td>
          </tr>
        </table>
      ) : null}

      <hr />

      <div className="text-right">
        <button
          className="add_competitor"
          onClick={() => {
            setOpenCompetitor(!openCompetitor);
          }}
        >
          Add Competitors
        </button>
      </div>

      {openCompetitor ? (
        <div className="competitor_search">
          <input
            type="text"
            onChange={(e) => {
              setsearchBrand(e.target.value);
            }}
          />
          <button
            className="competitor_Search_btn"
            onClick={() => {
              searchCompetitor();
            }}
          >
            <i class="fa fa-plus"></i>
          </button>

          <div className="d-flex align-items-center justify-content-between mt-2">
            <button
              className={
                complatform === "FB"
                  ? "active_social_platform_logo_btn social_platform_logo_btn"
                  : "social_platform_logo_btn"
              }
              onClick={() => {
                setCompPlatform("FB");
              }}
            >
              <i
                className="fa-brands fa-facebook"
                style={{ color: "#4267B2" }}
              ></i>
            </button>

            <button
              className={
                complatform === "INST"
                  ? "active_social_platform_logo_btn social_platform_logo_btn"
                  : "social_platform_logo_btn"
              }
              onClick={() => {
                setCompPlatform("INST");
              }}
            >
              <i
                class="fa-brands fa-instagram"
                style={{ color: "#E1306C" }}
              ></i>
            </button>

            <button
              className={
                complatform === "TW"
                  ? "active_social_platform_logo_btn social_platform_logo_btn"
                  : "social_platform_logo_btn"
              }
              onClick={() => {
                setCompPlatform("TW");
              }}
            >
              <i class="fa-brands fa-twitter" style={{ color: "#1DA1F2" }}></i>
            </button>

            <button
              className={
                complatform === "YT"
                  ? "active_social_platform_logo_btn social_platform_logo_btn"
                  : "social_platform_logo_btn"
              }
              onClick={() => {
                setCompPlatform("YT");
              }}
            >
              <i class="fa-brands fa-youtube" style={{ color: "#FF0000" }}></i>
            </button>
          </div>
        </div>
      ) : null}

      <div className="mode-box">
        <div style={{ fontSize: 14, fontWeight: "bold" }}>Report Demo Mode</div>
        <div style={{ fontSize: 12 }}>
          These are sample analytics to show what this report looks like fully
          rendered. Toggle off to switch back to your own data.
        </div>
      </div>

      <div className="d-flex w-100">
        <button
          className={
            profileTab === "Overview"
              ? "w-50 tab_btn active_tab"
              : "w-50 tab_btn active_btn"
          }
          onClick={() => {
            setProfileTab("Overview");
          }}
        >
          Overview
        </button>

        <button
          className={
            profileTab === "Profiles"
              ? "w-50 tab_btn active_tab"
              : "w-50 tab_btn active_btn"
          }
          onClick={() => {
            setProfileTab("Profiles");
          }}
        >
          Profiles
        </button>
      </div>

      {profileTab === "Overview" ? <Comp_overview competitorData={InstaProfiledate} /> : <Comp_profile competitorData={InstaProfiledate} />}
    </div>
  );
}
