import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Preview() {
  const [image, setImage] = useState(null);
  const [videoUrl,setVideoUrl]=useState(null);

  useEffect(() => {
    var token;

    if (localStorage.getItem('user_Token')) {
      token = localStorage.getItem('user_Token');
    }
    else {
      token = sessionStorage.getItem('user_Token');
    }

    axios.post(`${process.env.REACT_APP_BACKEND_API}/library/decrypt`, {
      "encryptedUrl": (window.location.href).split("?")[1]
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {
        // console.log()
        if((res.data.decryptedUrl).split(".").splice(-1)=='png'||(res.data.decryptedUrl).split(".").splice(-1)=="jpg"||(res.data.decryptedUrl).split(".").splice(-1)=="jpeg")
        {
          setImage(res.data.decryptedUrl)
        }
        else{
          setVideoUrl(res.data.decryptedUrl)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <>
    <div>
      {
        image !== null
          ?
          <img src={image}/>
          :
          <video src={videoUrl} controls className='video_preview' controlsList="nodownload"></video>
      }
    </div>
    </>
  )
}
