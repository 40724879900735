import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TimezoneChange } from '../store/Slices/TimezoneSlice';

export default function TimezoneDropdown({ Timezonelist }) {
    const [listShow, setListShow] = useState(false);
    const [ListData, setListData] = useState(Timezonelist);
    const [search, setSearch] = useState('');
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setListShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearch(query);

        // Filter timezone list as user types in the search box
        const filtered = Timezonelist.filter((timezone) =>
            timezone.city.toLowerCase().includes(query) || timezone.name.toLowerCase().includes(query)
        );
        setListData(filtered);
    };

    const handleSelectTimezone = (timezone) => {
        const selectedTimezone = `${timezone.city} ${timezone.name}`;
        dispatch(TimezoneChange(selectedTimezone));
        setSearch(''); // Clear search on selection
        setListShow(false); // Close the dropdown
        setListData(Timezonelist); // Reset list to full timezone list
    };

    const handleDropdownToggle = (e) => {
        e.preventDefault(); // Prevent default action (form submission or page refresh)
        setListShow(!listShow);
    };

    // Check if the dropdown would overflow and adjust its position accordingly
    const checkPosition = () => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                return { bottom: '100%' }; // Open upwards
            }
        }
        return { top: '100%' }; // Default behavior, open downwards
    };

    return (
        <div className="relative w-full">
            <button
                ref={buttonRef}
                onClick={handleDropdownToggle}  // Use custom handler here
                className="w-full py-3 px-4 flex justify-between items-center bg-white border rounded-lg shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
            >
                <div>{selector.Timezone[0] || 'Select Timezone'}</div>
                <div className="text-gray-600">
                    <i className="fa fa-angle-down fa-xs"></i>
                </div>
            </button>

            {listShow && (
                <div
                    ref={dropdownRef}
                    className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto"
                    style={checkPosition()} // Dynamically adjust the position
                >
                    <input
                        type="text"
                        value={search}
                        onChange={handleSearchChange}
                        placeholder="Search Timezones"
                        className="w-full py-2 px-4 border-b-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <ul className="max-h-40 overflow-y-auto">
                        {ListData.length > 0 ? (
                            ListData.map((elem, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => handleSelectTimezone(elem)}
                                        className="w-full py-2 px-4 text-left hover:bg-indigo-600 hover:text-white focus:outline-none transition duration-150 ease-in-out"
                                    >
                                        {elem.city} {elem.name}
                                    </button>
                                </li>
                            ))
                        ) : (
                            <li>
                                <span className="w-full py-2 px-4 text-gray-500">No timezones found</span>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}
