import React, { useEffect, useState } from 'react';

import { fetchInstagramEngagment } from '../../services/group';
import { Card, Spin } from 'antd';

const PostEngagementDetails = ({ elem }) => {





  return (

    <table className="post_details table-auto w-full text-left border-collapse mt-2">
      <tbody>
        <tr>
          <td className="font-semibold py-2">Public Engagements</td>
          <td>
            {elem?.platform === 'instagram' &&
              (elem?.comments_count + elem?.comments_count)}
            {elem?.platform === 'facebook' &&
              elem?.likes?.summary.total_count +
              elem?.comments?.summary.total_count}
            {elem?.platform === 'pinterest' && 'NA'}
          </td>
        </tr>
        <tr>
          <td className="font-semibold py-2">Likes</td>
          <td>
            {elem?.platform === 'instagram' && elem?.likes}
            {elem?.platform === 'facebook' &&
              elem?.likes?.summary.total_count}
            {elem?.platform === 'pinterest' && 'NA'}
          </td>
        </tr>
        <tr>
          <td className="font-semibold py-2">Comments</td>
          <td>
            {elem?.platform === 'instagram' && elem?.comments}
            {elem?.platform === 'facebook' &&
              elem?.comments?.summary.total_count}
            {elem?.platform === 'pinterest' && 'NA'}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PostEngagementDetails;
