import React, { useState } from 'react';
import HeadLogo from '../Assets/Icons/sign-logo.png';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';

export default function Signup() {
 
    const [Details, setDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
    });
    const [Mobile, setMobile] = useState();
    const [isLoader, setIsLoader] = useState(false);
    const navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const handel = (e) => {
        const newData = { ...Details };
        newData[e.target.name] = e.target.value;
        setDetails(newData);
    };

    const isPasswordStrong = (password) => {
        // Check for minimum password strength: 8+ characters, at least 1 number, 1 letter, and 1 special character
        const strongRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongRegex.test(password);
    };

    const Register = () => {
        setIsLoader(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!Details.firstname.trim()) {
            toast.error('First Name is required');
            setIsLoader(false);
            return;
        }
        if (!Details.lastname.trim()) {
            toast.error('Last Name is required');
            setIsLoader(false);
            return;
        }
        if (!Details.email.trim() || !emailRegex.test(Details.email)) {
            toast.error('Enter a valid Email Address');
            setIsLoader(false);
            return;
        }
        if (!Mobile || Mobile.trim() === '') {
            toast.error('Phone number is required');
            setIsLoader(false);
            return;
        }
        if (!Details.password.trim()) {
            toast.error('Password is required');
            setIsLoader(false);
            return;
        }
        if (!isPasswordStrong(Details.password)) {
            toast.error('Password must be at least 8 characters long, contain a number, a letter, and a special character');
            setIsLoader(false);
            return;
        }

        axios
            .post(`${process.env.REACT_APP_BACKEND_API}/user/register`, {
                first_name: Details.firstname,
                last_name: Details.lastname,
                email_id: Details.email,
                password: Details.password,
                mobile: Mobile,
            })
            .then((res) => {
                console.log(res.data.data, ' res.data.data res.data.data');

                toast.success('Account created successfully!');
                sessionStorage.setItem('Email', Details.email);
                sessionStorage.setItem('contact', Mobile);

                sessionStorage.setItem('firstName', Details.firstname);

                sessionStorage.setItem('user_Token', res.data.data.token);
                sessionStorage.setItem('razorpay_customer_id', res?.data?.data?.user?.razorpay_customer_id);
                 navigate('/Onboarding');
            })
            .catch((err) => {
                toast.error(err.response.data.message || 'Registration failed. Please try again later.');
                console.error(err);
                console.log(err.response.data);

            })
            .finally(() => {
                setIsLoader(false);
            });
    };

    return (
        <div className="flex flex-col min-h-screen">
            <ToastContainer />
            <div className="flex items-center justify-between p-4 bg-gray-100">
                <div>
                    <img src={HeadLogo} className="h-8" alt="Logo" />
                </div>
                <div className="text-sm">
                    <span>Already have an account? </span>
                    <Link to="/" className="text-blue-500 underline">
                        Log in
                    </Link>
                </div>
            </div>

            <div className="flex flex-1 flex-col items-center justify-center bg-gray-50">
                <div className="w-full max-w-md p-6 bg-white shadow rounded-lg">
                    <h2 className="text-xl font-semibold text-center mb-4">Start your free, 30 day trial</h2>
                    <p className="text-center text-gray-500 mb-6">No credit card required</p>

                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-sm mb-2">First Name</label>
                            <input
                                type="text"
                                placeholder="Enter First Name"
                                name="firstname"
                                value={Details.firstname}
                                onChange={handel}
                                className={`w-full border rounded px-3 py-2 ${!Details.firstname.trim() && 'border-red-500'}`}
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-2">Last Name</label>
                            <input
                                type="text"
                                placeholder="Enter Last Name"
                                name="lastname"
                                value={Details.lastname}
                                onChange={handel}
                                className={`w-full border rounded px-3 py-2 ${!Details.lastname.trim() && 'border-red-500'}`}
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm mb-2">Email Address</label>
                        <input
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            value={Details.email}
                            onChange={handel}
                            className="w-full border rounded px-3 py-2"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm mb-2">Phone</label>
                        <PhoneInput
                            placeholder="Enter Phone No."
                            defaultCountry="IN"
                            value={Mobile}
                            onChange={setMobile}
                            className="w-full border rounded px-3 py-2"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-sm mb-2">Password</label>
                        <input
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            value={Details.password}
                            onChange={handel}
                            className="w-full border rounded px-3 py-2"
                        />
                        {!isPasswordStrong(Details.password) && Details.password.trim() && (
                            <p className="text-sm text-red-500 mt-1">
                                Password must be at least 8 characters, include a number, a letter, and a special character.
                            </p>
                        )}
                    </div>

                    <button
                        onClick={Register}
                        className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition"
                    >
                        {isLoader ? <Spin indicator={antIcon} /> : 'Create Account'}
                    </button>

                    <p className="text-xs text-center text-gray-500 mt-4">
                        By clicking “Create Account”, I agree to Sprout Social’s{' '}
                        <b>Terms of Service</b> and acknowledge its <b>Privacy Policy</b>.
                    </p>
                </div>
            </div>
        </div>
    );
}
