import React, { useEffect } from "react";

import { createGroup, fetchGroups } from "../../store/Slices/GroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { instagramCallback } from "../../services/user.profile";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Instagram() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedGroup } = useSelector((state) => state.groups);
  const AddtoGroup = (pageID) => {
    console.log(pageID);
    const Pages = pageID.map((elem) => ({
      platform: "INSTAGRAM",
      platform_id: elem.page_details[0].accountId,
      profile: elem.page_details[0].profile,
    }));

    dispatch(
      createGroup({ groupName: selectedGroup, connectedAccounts: Pages })
    )
      .then(() => {
        alert("Group created successfully!");
        dispatch(fetchGroups()); // Refresh the groups list
        window.close();
      })
      .catch((err) => {
        console.error("Error creating group:", err);
        alert("Failed to create group. Please try again.");
      });
  };

  // const AddtoGroup = (pageID) => {
  //     var Pages = [];

  //     axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
  //         groupName: localStorage.getItem("selected_group"),
  //         connectedAccounts: Pages
  //     }, {
  //         headers: {
  //             'Authorization': `Bearer ${token}`
  //         }
  //     })
  //         .then((res) => {
  //             window.close()
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await instagramCallback(window.location.href);

        AddtoGroup(response.data.result);
        navigate("/dashboard");
      } catch (err) {
        console.log(err);
        toast.error("unable to connect with your account.");
        navigate("/dashboard");
      }
    };

    fetchData();
  }, []);

  return <div>Redirecting.......</div>;
}
