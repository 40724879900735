import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Cookies from 'js-cookie';
import { Spin, message } from 'antd';
import { Chart, element } from 'react-composable-charts';
import { ImpressionGrowth, ImpressionSlice } from '../store/Slices/ImpressionSlice';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export function Impressionchart() {
    const [InstaImpression, setInstaImpression] = useState([]);
    const [isLoader, setIsloader] = useState(true);
    const [FbImpressrions, setFbImpressions] = useState([]);
    const [PintrestImpression, setPintrestImpression] = useState([]);
    const {analytics}=useSelector((state)=>{
        return state.Analytics
    });
   
    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const Filter=useSelector((state)=>{
        return state.Filter
    })

    const dispatch=useDispatch();


   

    const GetImpression = () => {
        
        const FilterProfile=[];
        setIsloader(true);
        
       

        Filter[0].map((elem)=>{
            if(elem.platform=="FACEBOOK"){
                FilterProfile.push(elem.platform_id)
            }
            else{
                FilterProfile.push(elem.platform_id)
            }
        })

    
                let FacebookImpression = [];
                let InstaImpression = [];
                let PinImpression = [];

                // sort data from response data instagram
                if (analytics.INSTAGRAM !== undefined) {
                    (analytics.INSTAGRAM).map((elem) => {
                        (elem.data).map((datas) => {
                            if (datas.name === "impressions") {
                                InstaImpression.push(datas.values);
                            }
                        })
                    })
                }

                // sort data from response data facebook
                if (analytics.FACEBOOK !== undefined) {
                    (analytics.FACEBOOK).map((elem) => {
                        (elem.data).map((datas) => {
                            if (datas.name === "page_impressions") {
                                FacebookImpression.push(datas.values);
                            }
                        })
                    })
                }


                if (analytics.PINTEREST !== undefined) {
                    (analytics.PINTEREST).map((elem) => {
                        (elem.analytics.all.daily_metrics).map((datas) => {
                            PinImpression.push(datas)
                            // if (datas.name === "page_impressions") {
                            //     FacebookImpression.push(datas.values);
                            // }
                        })
                    })


                }

                if (InstaImpression.length !== 0) {
                    InstaProcess(InstaImpression);
                }
                if (FacebookImpression.length !== 0) {
                    FBProcess(FacebookImpression);
                }

                if (PinImpression.length !== 0) {
                    PinPrcess(PinImpression);
                }

                const ImpressionGrow=data[0].datasets[0].data.reduce((accumulator, currentValue) =>
                    accumulator + currentValue.y, 0
                ) +
                data[0].datasets[1].data.reduce((accumulator, currentValue) =>
                    accumulator + currentValue.y, 0
                ) +
                data[0].datasets[2].data.reduce((accumulator, currentValue) =>
                    accumulator + currentValue.y, 0
                );

                dispatch(ImpressionGrowth(ImpressionGrow));
                


                setIsloader(false);
           
    }

    // Instagram data process in a sort array
    function InstaProcess(InstaImpressions) {
        let newImpressionObj = [];

        for(var i=0;i<InstaImpressions.length;i++){
            newImpressionObj.push(...InstaImpressions[i])
        }

        const InstanewObj = Object.values(newImpressionObj.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = parseInt(String(Number(a[end_time].value) + Number(value)));
            return a;
        }, {}));

        InstanewObj.map((instaObj) => {
            InstaImpression.push({
                x: (instaObj.end_time).split("T")[0].split("-")[1] + "-" + (instaObj.end_time).split("T")[0].split("-")[2],
                y: instaObj.value
            })
        })
    }

    // Facebook data process in a sort array
    const FBProcess = (FbImpressions) => {
        let FBImpression = [];
        FBImpression = FbImpressions[0].concat(FbImpressions[1]);


        const FbnewObj = Object.values(FBImpression.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = parseInt(String(Number(a[end_time].value) + Number(value)));
            return a;
        }, {}));

        FbImpressrions.push({
            x: (new Date(Sinceselector[0] * 1000).getMonth() + 1).toString().padStart(2, "0") + "-" + new Date(Sinceselector[0] * 1000).getDate().toString().padStart(2, "0"),
            y: 0
        });


        FbnewObj.map((instaObj) => {
            FbImpressrions.push({
                x: (instaObj.end_time).split("T")[0].split("-")[1] + "-" + (instaObj.end_time).split("T")[0].split("-")[2],
                y: instaObj.value
            })
        })

    }

    // Pintrest data process in sort array
    const PinPrcess = (PinImpression) => {
        let PinImpressionObj = [];

        // make raw response date into formate data
        PinImpression.map((element) => {
            if (element.data_status === "READY") {
                PinImpressionObj.push({
                    end_time: element.date,
                    value: element.metrics.IMPRESSION
                })
            }
            else {
                PinImpressionObj.push({
                    end_time: element.date,
                    value: 0
                })
            }
        })


        const PinnewObj = Object.values(PinImpressionObj.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = parseInt(String(Number(a[end_time].value) + Number(value)));
            return a;
        }, {}));

        PinnewObj.map((instaObj) => {
            PintrestImpression.push({
                x: (instaObj.end_time).split("-")[1] + "-" + (instaObj.end_time).split("-")[2],
                y: instaObj.value
            })
        })
    }

    useEffect(() => {
        if (Filter.length !== 0) { 
            GetImpression()
        }
    }, [Sinceselector, untilSelector,Filter,analytics])
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                }
            },
        },
        elements: {
            point: {
                radius: 0.5
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }
    };


    const data = [{
        datasets: [
            {
                label: 'Instagram', // Name the series
                data: InstaImpression, // Specify the data values array
                fill: true,
                borderColor: '#CE3665', // Add custom color border (Line)
                backgroundColor: '#CE3665', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.3
            },
            {
                label: 'Facebook', // Name the series
                data: FbImpressrions, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.3
            },
            // {
            //     label: 'Linkedin', // Name the series
            //     data: LinkedinImpression, // Specify the data values array
            //     fill: true,
            //     borderColor: '#FFCD1C', // Add custom color border (Line)
            //     backgroundColor: '#FFCD1C', // Add custom color background (Points and Fill)
            //     borderWidth: 0.4, // Specify bar border width
            //     tension: 0.3

            // },
            // {
            //     label: 'Twitter', // Name the series
            //     data: TwitterImpression, // Specify the data values array
            //     fill: true,
            //     borderColor: '#08C4B2', // Add custom color border (Line)
            //     backgroundColor: '#08C4B2', // Add custom color background (Points and Fill)
            //     borderWidth: 1, // Specify bar border width
            //     tension: 0.3
            // },
            {
                label: 'Pintrest', // Name the series
                data: PintrestImpression, // Specify the data values array
                fill: true,
                borderColor: '#3896E3', // Add custom color border (Line)
                backgroundColor: '#3896E3', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.3
            }
        ],
    }];

    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Impressions
                    </label>

                    <label className='label_sub_head'>
                        Review how your content was seen across networks during the reporting period.
                    </label>
                </div>

                <div style={{ height: '40vh', paddingLeft: '30px', paddingRight: '30px', paddingTop: '40px' }}>
                    {
                        isLoader
                            ?
                            <div className='d-flex align-items-center justify-content-center'>
                                <Spin />
                            </div>
                            :
                            <>
                                <Line options={options} data={data[0]} />
                            </>
                    }
                </div>

                <div className='w-100 px-3'>
                    <table className='w-100 table chart_table'>
                        <tr>
                            <th>
                                Impression Metrics
                            </th>

                            <th>
                                Totals
                            </th>

                            <th>
                                % Change
                            </th>
                        </tr>

                        <tbody>
                            <tr>
                                <td>
                                    Total Impressions
                                </td>

                                <td>
                                    {
                                        InstaImpression.length !== 0
                                            ?
                                            data[0].datasets[0].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            ) +
                                            data[0].datasets[1].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            ) +
                                            data[0].datasets[2].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            )
                                            :
                                            null
                                    }
                                </td>

                                <td>
                                    2.1%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Total Net Audience Growth
                                </td>

                                <td>
                                    0
                                </td>

                                <td>
                                    130%
                                </td>
                            </tr>

                            {
                                InstaImpression.length !== 0
                                    ?
                                    <tr>
                                        <td>
                                            Instagram Impressions
                                        </td>
                                        <td>
                                            {
                                                InstaImpression.length !== 0
                                                    ?
                                                    data[0].datasets[0].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                            {
                                FbImpressrions.length !== 0
                                    ?
                                    <tr>
                                        <td>
                                            Facebook Impressions
                                        </td>
                                        <td>
                                            {
                                                FbImpressrions.length !== 0
                                                    ?
                                                    data[0].datasets[1].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                            {
                                PintrestImpression.length !== 0
                                    ?
                                    <tr>
                                        <td>
                                            Pintrest Impressions
                                        </td>
                                        <td>
                                            {
                                                PintrestImpression.length !== 0
                                                    ?
                                                    data[0].datasets[2].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
