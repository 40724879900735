import { useState, useRef } from "react";
import { DatePicker, message, Progress, Select, Spin, TimePicker } from "antd";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import { useEffect } from "react";

export default function PostUploader({
  messages,
  setMessage,
  setProgess,
  setPhotoPreview,
  PhotoPreview,
  setUploadMenu,
  PublishType,
  fileType,
  setFileType,
  messageRef,
  uploadfile,
  setEmoji,
  emoji,
  AddEmoji,
  uploadStocket,
  scheduleDates,
  setScheduleDates,
  setDatePickerToggle,
  datepickertoggle,
  GenerateCaption,
  captionLoad,
  SelectedPlatform,
  progess,
  setPublishType,
  outputResult,
  handleChangeInstaContent,
  instaContentData,
  BoardID,
  setBoardList,
  boardList,
  setopenCreateboard,
  PintBoardList,
  setBoardID,
  setTitle,
  setDesc
}) {
  const [isStory, setIsStory] = useState(false);

  // if isStory is true, set the PublishType to "stories"
  useEffect(() => {
    setPublishType(isStory ? "STORIES" : "Multiple");
  }
    , [isStory]);
  console.log('PhotoPreview', PhotoPreview);


  // setOpenSection,
  const [openSection, setOpenSection] = useState(null);
  console.log('openSection', openSection);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const platformMapping = {
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    TWITTER: "Twitter",
    LINKEDIN: "LinkedIn",
    PINTEREST: "Pinterest",
    GOOGLE_MY_BUSINESS: "google"
  }

  const selectedPlatformNames = SelectedPlatform?.map(p => platformMapping[p.platform]);
  console.log('selectedPlatformNames', selectedPlatformNames);

  return (
    <div className="">
      <div className="w-full   p-4 border rounded-lg bg-white">
        {/* Toggle Switch */}
        <div className="flex items-center justify-between mb-2">
          <label className="text-gray-700 font-medium">This is a story</label>
          <div
            className={`w-12 h-6 flex items-center rounded-full cursor-pointer transition ${isStory ? "bg-blue-600" : "bg-gray-300"
              }`}
            onClick={() => setIsStory(prev => !prev)}
          >
            <div
              className={`w-5 h-5 bg-white rounded-full shadow-md transform transition ${isStory ? "translate-x-6" : "translate-x-0"
                }`}
            ></div>
          </div>
        </div>

        {/* Text Area */}
        <textarea

          value={messages}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          ref={messageRef}
          className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 my-2"
          placeholder="Write something..."
        ></textarea>

        {captionLoad && <Spin />}

        {/* File Preview Section */}
        {PhotoPreview.length > 0 && (
          <div className="grid grid-cols-6 gap-2 mt-3">
            {PhotoPreview.map((elem, index) => {
              const fileTypeValid = Array.isArray(fileType) ? fileType[index] : fileType;
              console.log('fileTypeValid', fileTypeValid);
              return (
                <div key={index} className="relative w-20 h-28">
                  {fileTypeValid === "video" ? (
                    <video
                      src={elem}
                      className="w-full h-full object-cover rounded-md"
                    // controls

                    />
                  ) : fileTypeValid === "photo" ? (
                    <img
                      src={elem}
                      className="w-full h-full object-cover rounded-md"
                      alt="Preview"
                    />
                  ) : null}

                  {/* Edit Button */}
                  <button className="absolute top-1 right-1 bg-gray-800/70 p-1 rounded-full">
                    <i className="fa fa-pencil text-white"></i>
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {/* Add More Button */}
        <label className="w-20 h-28 mt-2 flex justify-center items-center border-2 border-dashed rounded-md cursor-pointer">
          <input
            type="file"
            accept="image/*, video/*"
            className="hidden"
            ref={uploadfile}
            multiple
            onChange={uploadStocket}
          />
          <i className="fa fa-plus text-gray-500 text-2xl"></i>
        </label>

        {/* Add Alt Text Link */}
        <div className="mt-2">
          <button className="text-blue-500 text-sm underline">Add Alt Text</button>
        </div>

        {/* Footer */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center gap-2">
            <img
              className="w-10 h-10 cursor-pointer"
              onClick={() => {
                setEmoji(!emoji);
              }}
              src={require("../../Assets/Icons/emoji.png")} alt="emoji icon" />
            <button
              className="assist-btn"
              onClick={() => {
                GenerateCaption();
              }}
            >
              <img src={require("../../Assets/Aicaption.png")} />
            </button>

          </div>
          <span className="text-gray-600 text-sm">
            {messages.length} / 486
          </span>
        </div>
        {/* add progess bar */}
        {
          <div>{progess > 0 ? <Progress percent={progess} /> : null}</div>
        }

        {emoji ? (
          <div className="emoji_picker">
            <EmojiPicker
              onEmojiClick={(e) => {
                AddEmoji(e);
              }}
            />
          </div>
        ) : null}



      </div>
      {/* AccordionItem */}
      <div className="w-full mt-2">
        {selectedPlatformNames?.includes("Instagram") && (
          <AccordionItem
            title="Instagram Options"
            isOpen={openSection === "instagram"}
            toggle={() => toggleSection("instagram")}
          >
            <InputField value={instaContentData.firstComment} name="firstComment" placeholder="Enter First Comment " label="Instagram First Comment"
              onChange={(e) => {
                handleChangeInstaContent(e);
              }}
            // defaultValue={firstComment}
            />

            <InputField
              placeholder="Invite collaborators"
              description="Invite up to 3 profiles. Learn more in the Help Center about Collaborators"
              label="Collaborators"
              onChange={(e) => {
                const value = e.target.value.split(',').map(item => item.trim());
                handleChangeInstaContent(e);
              }}
              value={instaContentData.collaborators}
              name="collaborators"
            // defaultValue={collaborators?.join(',')}
            />

          </AccordionItem>
        )}

        {selectedPlatformNames?.includes("Facebook") && (
          <AccordionItem
            title="Facebook Options"
            isOpen={openSection === "facebook"}
            toggle={() => toggleSection("facebook")}
          >
            {/* title and description  */}
            <InputField label="Title" placeholder="Title" />
            <InputField label="Description" placeholder="Description" />
          </AccordionItem>
        )}


        {selectedPlatformNames?.includes("Pinterest") && (
          <AccordionItem
            title="Pinterest Options"
            isOpen={openSection === "Pinterest"}
            toggle={() => toggleSection("Pinterest")}
          >
            <div className="">
              {[
                {
                  label: "Pin Title",
                  placeholder: "Pin Title",
                  value: "",
                  onChange: setTitle,
                },
             
                {
                  label: "Destination URL",
                  placeholder: "Destination URL",
                  value: PhotoPreview[0] || "",
                  onChange: setDesc,
                },
              ].map(({ label, placeholder, value, onClick, onChange }, index) => (
                <div key={index} className="">
                  <InputField
                    label={label}
                    placeholder={placeholder}
                 
                    onClick={onClick}
                    onChange={onChange}
                    name={label.toLowerCase().replace(" ", "_")}
                    readOnly={onClick}
                  />
                </div>
              ))}

           
            </div>
            <div className="">
              {[
            
                {
                  label: "Pin To",
                  placeholder: "Choose Board (Required)",
                  value: BoardID?.name || "",
                  onClick: () => setBoardList(!boardList),
                  onChange: (e) => console.log(e.target),
                },
            
              ].map(({ label, placeholder, value, onClick, onChange }, index) => (
                <div key={index} className="">
                  <InputField
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onClick={onClick}
                    onChange={onChange}
                    name={label.toLowerCase().replace(" ", "_")}
                    readOnly={!!onClick}
                  />
                </div>
              ))}

              {boardList && (
                <div className="relative">
                  <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg ">
                    <ul className="p-2">
                      <li
                        className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => setopenCreateboard(true)}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        <span>Create New Board</span>
                      </li>
                      {PintBoardList?.map((elem) => (
                        <li
                          key={elem.id}
                          className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            console.log(elem)
                            setBoardID(elem);
                            setBoardList(false);
                          }}
                        >
                          <img
                            src={elem.media.image_cover_url}
                            className="w-8 h-8 rounded-md"
                            alt="Board Cover"
                          />
                          <span>{elem.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </AccordionItem>
        )}

        {selectedPlatformNames?.includes("google") && (
          <AccordionItem
            title="Google My Business Options"
            isOpen={openSection === "google"}
            toggle={() => toggleSection("google")}
          >
            <SelectField label="Post Type" options={["What’s New? (Basic Post)"]} />
            <SelectField label="Button Type (Optional)" options={["None"]} />
          </AccordionItem>
        )}

        {/* When to Post (Always Visible) */}
        <AccordionItem
          title="When to post"
          // isOpen={openSection === "schedule"}
          isOpen={true}
          toggle={() => toggleSection("schedule")}
        >

          {/* <div className="flex gap-2 mt-2">
                        <InputField label="Date" placeholder="MM/DD/YYYY" />
                        <InputField label="Time" placeholder="Hours : Minutes AM/PM" />
                    </div> */}
          <ScheduleDraft scheduleDates={scheduleDates} setScheduleDates={setScheduleDates} />

        </AccordionItem>
      </div>

      <ErrorWarningMessages outputResult={outputResult} />
      {/* bottom footer */}
      {/* <div className="fixed bottom-0 left-0 w-full bg-white shadow-md p-4 flex justify-between items-center z-10">
                <button className="bg-gray-200 text-gray-700 py-2 px-4 rounded">Cancel</button>
                <button className="bg-blue-500 text-white py-2 px-4 rounded">Save</button>
            </div> */}

    </div>
  );
}

function ErrorWarningMessages({ outputResult }) {
  const errors = [];
  const warnings = [];

  // Extract errors from API response
  if (outputResult?.responses?.length > 0) {
    outputResult.responses.forEach((response) => {
      if (response.error) {
        errors.push(response.message);
      }
    });
  }

  return (
    <div className="p-4 border rounded-md bg-white shadow-md">
      {/* Title */}
      <h3 className="font-semibold mb-3">
        {errors.length} {errors.length === 1 ? "error" : "errors"} and{" "}
        {warnings.length} {warnings.length === 1 ? "warning" : "warnings"}
      </h3>

      {/* Errors Section */}
      {errors.length > 0 && (
        <ul className="text-sm text-gray-700">
          {errors.map((error, index) => (
            <li key={index} className="flex gap-2 items-start mb-2">
              <span className="text-red-500 font-bold">⚠️</span>
              {error}
            </li>
          ))}
        </ul>
      )}

      {/* Warnings Section */}
      {warnings.length > 0 && (
        <ul className="text-sm text-gray-700 mt-4">
          {warnings.map((warning, index) => (
            <li key={index} className="flex gap-2 items-start mb-2">
              <span className="text-yellow-600 font-bold">⚠️</span>
              {warning}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}



// Reusable Accordion Item
function AccordionItem({ title, children, isOpen, toggle }) {
  return (
    <div className="border-b  bg-white mb-2">
      <button
        className="w-full text-left p-3 font-medium flex justify-between items-center"
        onClick={toggle}
      >
        {title}
        <span>{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && <div className="p-3">{children}</div>}
    </div>
  );
}

// Reusable Input Field Component
function InputField({ label, placeholder = "", description = "", onChange, value, name, onClick, readOnly }) {
  return (
    <div className="mb-2">
      <label className="block text-gray-600 mb-1 mt-1 font-bold">{label}</label>
      {description && <p className="text-sm text-gray-500  font-semibold ">{description}</p>}
      <input
        type="text"
        className="border p-2 rounded-md w-full mt-2"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        onClick={onClick}
        readOnly={readOnly}
      />
    </div>
  );
}

// Reusable Select Field Component
function SelectField({ label, options }) {
  return (
    <div className="mb-2">
      <label className="block text-gray-600 mb-1">{label}</label>
      <select className="border p-2 rounded-md w-full">
        {options.map((option, idx) => (
          <option key={idx} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

// date and time picker
function ScheduleDraft({
  scheduleDates,
  setScheduleDates,


}) {

  const [timeZone, setTimeZone] = useState("Asia/Kolkata");
  const [showAdvanced, setShowAdvanced] = useState(true);

  const handleDateChange = (date, index) => {
    const updatedDates = [...scheduleDates];
    updatedDates[index].date = date;
    setScheduleDates(updatedDates);
  };

  const handleTimeChange = (time, index) => {
    const updatedDates = [...scheduleDates];
    updatedDates[index].time = time;
    setScheduleDates(updatedDates);
  };

  const handleAMPMChange = (value, index) => {
    const updatedDates = [...scheduleDates];
    updatedDates[index].ampm = value;
    setScheduleDates(updatedDates);
  };

  const addSchedule = () => {
    setScheduleDates([...scheduleDates, { date: null, time: null, ampm: "AM" }]);
  };

  const removeSchedule = (index) => {
    const updatedDates = scheduleDates.filter((_, i) => i !== index);
    setScheduleDates(updatedDates);
  };

  console.log('scheduleDates', scheduleDates);


  return (
    <div className="w-full ">
      {/* Title */}
      <h3 className="font-semibold text-gray-700 mb-2">
        Show draft on calendar (optional)
      </h3>

      {scheduleDates?.map((item, index) => (
        <div key={index} className="flex items-center gap-3 mb-2">
          {/* Date Picker */}
          <DatePicker
            // className="w-44"
            value={item.date}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(date) => handleDateChange(date, index)}
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
          />

          {/* <DatePicker
                        open={datepickertoggle}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={(e) => {
                            changeDateFormate(e.$d);
                        }}
                        onOk={() => {
                            setDatePickerToggle(false);
                        }}
                        style={{ visibility: "hidden" }}
                        showTime={true}
                    /> */}

          {/* Time Picker */}
          <TimePicker
            className="w-24"
            use12Hours
            format="h:mm"
            value={item.time}
            onChange={(time) => handleTimeChange(time, index)}
          />

          {/* AM/PM Selector */}
          <Select
            className="w-20"
            value={item.ampm}
            onChange={(value) => handleAMPMChange(value, index)}
            options={[
              { label: "AM", value: "AM" },
              { label: "PM", value: "PM" },
            ]}
          />

          {/* Delete Button */}
          {scheduleDates.length > 1 && (
            <button onClick={() => removeSchedule(index)} className="text-gray-500 hover:text-red-500">
              🗑
            </button>
          )}

          {/* <button
                        className="edit-btn"
                        onClick={() => {
                            setDatePickerToggle(true);
                        }}
                    >
                        Edit
                    </button> */}
        </div>
      ))}

      {/* Add More Button */}
      <button onClick={addSchedule} className="text-blue-500 text-sm mt-2">
        + Add more scheduled times
      </button>

      {/* Advanced Scheduling */}
      <div className="mt-4">
        <button
          className="text-blue-500 text-sm"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          {showAdvanced ? "Advanced Scheduling ⬆" : "Advanced Scheduling ⬇"}
        </button>

        {showAdvanced && (
          <div className="mt-3">
            <label className="block text-sm text-gray-700">Time Zone</label>
            <Select
              className="w-full mt-1"
              value={timeZone}
              onChange={(value) => setTimeZone(value)}
              options={[
                { label: "Chennai, Kolkata, Mumbai (UTC +05:30)", value: "Asia/Kolkata" },
                { label: "New York (UTC -05:00)", value: "America/New_York" },
                { label: "London (UTC +00:00)", value: "Europe/London" },
              ]}
            />
          </div>
        )}
      </div>

      {/* Footer Links */}
      <div className="mt-4 text-sm text-gray-600">
        Need to schedule a lot at once?{" "}
        <a href="#" className="text-blue-500 underline">
          Try Bulk Scheduling
        </a>
      </div>
    </div>
    // {/* <>
    //     {/* <div className="col-sm-5 date">
    //         Schedule Date:{" "}
    //         <b>
    //             {scheduleDates
    //                 ? moment(scheduleDates).format("MMM D, HH:mm")
    //                 : null}
    //         </b>
    //         <button
    //             className="edit-btn"
    //             onClick={() => {
    //                 setDatePickerToggle(true);
    //             }}
    //         >
    //             Edit
    //         </button>
    //         <DatePicker
    //             open={datepickertoggle}
    //             format="YYYY-MM-DD HH:mm:ss"
    //             onChange={(e) => {
    //                 changeDateFormate(e.$d);
    //             }}
    //             onOk={() => {
    //                 setDatePickerToggle(false);
    //             }}
    //             style={{ visibility: "hidden" }}
    //             showTime={true}
    //         />
    //     </div> */}
    // </> */}
  );
}