import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGroupAnalytics, processFBData } from '../services/activity';

export default function Activity() {
    const untilSelector = useSelector((state) => state.UntilDates);
    const sinceSelector = useSelector((state) => state.SinceDates);

    const [engagement, setEngagement] = useState(null);
    const [impression, setImpression] = useState(null);
    const [previousEng, setPreviousEng] = useState(null);
    const [prevImpression, setPrevImpression] = useState(null);



    const formatDate = (timestamp) => new Date(timestamp * 1000);

    const fetchAllAnalytics = async () => {
        try {
          // Format current dates
          const formattedSince = formatDate(sinceSelector[0]);
          const formattedUntil = formatDate(untilSelector[0]);
      
          // Calculate previous dates
          const previousSince = new Date(formattedSince);
          previousSince.setMonth(previousSince.getMonth() - 1);
          const previousUntil = new Date(formattedUntil);
          previousUntil.setMonth(previousUntil.getMonth() - 1);
      
          const groupName = localStorage.getItem('oolook_default_group');
      
          // Fetch current and previous analytics concurrently
          const [currentData, previousData] = await Promise.all([
            getGroupAnalytics(groupName, formattedSince, formattedUntil),
            getGroupAnalytics(groupName, previousSince, previousUntil)
          ]);
      
          // Process current analytics
          let currentEngagement = 0;
          const currentFBImp = [];
          const currentInstaImp = [];
      
          if (currentData.analytics?.FACEBOOK) {
            currentData.analytics.FACEBOOK.forEach(({ data }) => {
              data.forEach(({ name, values }) => {
                if (name === 'page_post_engagements') {
                  currentEngagement += values.reduce((acc, item) => acc + item.value, 0);
                }
                if (name === 'page_impressions') {
                  currentFBImp.push(values);
                }
              });
            });
          }
      
          if (currentData.analytics?.INSTAGRAM) {
            currentData.analytics.INSTAGRAM.forEach(({ data }) => {
              data.forEach(({ name, values }) => {
                if (name === 'impressions') {
                  currentInstaImp.push(values);
                }
              });
            });
          }
      
          const currentImpressions = processFBData(currentFBImp) + processFBData(currentInstaImp);
      
          // Process previous analytics
          let previousEngagement = 0;
          const previousFBImp = [];
          const previousInstaImp = [];
      
          if (previousData.analytics?.FACEBOOK) {
            previousData.analytics.FACEBOOK.forEach(({ data }) => {
              data.forEach(({ name, values }) => {
                if (name === 'page_post_engagements') {
                  previousEngagement += values.reduce((acc, item) => acc + item.value, 0);
                }
                if (name === 'page_impressions') {
                  previousFBImp.push(values);
                }
              });
            });
          }
      
          if (previousData.analytics?.INSTAGRAM) {
            previousData.analytics.INSTAGRAM.forEach(({ data }) => {
              data.forEach(({ name, values }) => {
                if (name === 'impressions') {
                  previousInstaImp.push(values);
                }
              });
            });
          }
      
          const previousImpressions = processFBData(previousFBImp) + processFBData(previousInstaImp);
      
          // Update state
          setEngagement(currentEngagement);
          setImpression(currentImpressions);
          setPreviousEng(previousEngagement);
          setPrevImpression(previousImpressions);
        } catch (error) {
          console.error('Error fetching analytics:', error);
        }
      };
      
      useEffect(() => {
        fetchAllAnalytics();
      }, [untilSelector, sinceSelector]);
      

    return (
        <div className="row activity">
            <h4>Your Latest Activity</h4>
            <div className="col-4 px-0 performance_section">
                <div className="titles">
                    <label>Performance Summary</label>
                </div>

                <div className="titles">
                    <div className="row title_box1">
                        <div className="col-6">
                            <label>Impressions</label>
                            <div className="d-flex justify-content-between">
                                <label>{impression}</label>
                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                <label style={{ color: "#21A500" }}>
                                    {impression !== null && prevImpression !== null
                                        ? prevImpression === 0
                                            ? impression === 0
                                                ? 0
                                                : "0"
                                            : (((impression - prevImpression) / prevImpression) * 100).toFixed(2)
                                        : null}
                                    %
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <label>Engagements</label>
                            <div className="d-flex justify-content-between">
                                <label>{engagement}</label>
                                <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                <label style={{ color: "#21A500" }}>
                                    {engagement !== null && previousEng !== null
                                        ? previousEng === 0
                                            ? engagement === 0
                                                ? 0
                                                : "0"
                                            : (((engagement - previousEng) / previousEng) * 100).toFixed(2)
                                        : null}
                                    %
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Additional Metrics */}
                <div className="titles">
                    <div className="row title_box1">
                        <div className="col-6">
                            <label>Post Link Clicks</label>
                            <div className="d-flex justify-content-between">
                                <label>0</label>
                                <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                <label>0%</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-8">
                <div className="summary">
                    <div className="d-flex summary_header">
                        <h4>Campaigns Summary</h4>
                        <div className="summary_inner_box">
                            <button className="btn summary_create">Create</button>
                            <label>Sort by</label>
                            <select>
                                <option>Highest Performance</option>
                                <option>Lowest Performance</option>
                            </select>
                        </div>
                    </div>
                    <div className="table_section">
                        <table className="summary_table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Campaign Name</th>
                                    <th>Type</th>
                                    <th>Budget</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>25/10/2023</td>
                                    <td>GGLGB700D7GGLGB700D7</td>
                                    <td>LG</td>
                                    <td>$500</td>
                                </tr>
                                {/* Add more rows dynamically */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
