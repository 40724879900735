import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Ans1: null,
    Ans2: null,
    Ans3: null,
    Ques: 1,
    steps: [],
    isLoader: false,
};

const OnboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setAns1: (state, action) => {
            state.Ans1 = action.payload;
        },
        setAns2: (state, action) => {
            state.Ans2 = action.payload;
        },
        setAns3: (state, action) => {
            state.Ans3 = action.payload;
        },
        setQues: (state, action) => {
            state.Ques = action.payload;
        },
        setSteps: (state, action) => {
            state.steps = action.payload;
        },
        setIsLoader: (state, action) => {
            state.isLoader = action.payload; // Add setIsLoader reducer
        },
    },
});

export const { setAns1, setAns2, setAns3, setQues, setSteps, setIsLoader } = OnboardingSlice.actions;
export default OnboardingSlice.reducer; // Correctly export the reducer
