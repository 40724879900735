import React, { useRef, useState, useEffect, useCallback } from 'react'

import axios from 'axios';
import { message } from 'antd';
import {
    LoginSocialInstagram,
    LoginSocialPinterest,
    LoginSocialTwitter
} from 'reactjs-social-login';

export default function ConnectProfile() {
    const [twtoken, setTWToken] = useState(false);
    const [googleToken, setGoogleToken] = useState(false);
    const [facebookToken, setFacebookToken] = useState(false);
    const [twitterToken, settwitterToken] = useState(false);
    const [instagramToken, setInstagramToken] = useState(false);
    const [LinkedinToken, setLinkedInToken] = useState(false);
    const [PintrestToken, setPintrestToken] = useState(false);
    const [popupWindow, setPopupWindow] = useState(null);
    const [userID, setUserID] = useState();

    const [isLoader, setLoader] = useState(false);


    const REDIRECT_URI = 'https://app.oolook.in/Dashboard/Profile'

    // const REDIRECT_URI = "http://localhost:3000/Dashboard/Profile"


    const getProfile = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/profiles/get-profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res)
                const response = res.data.profiles;

                response.map((elem) => {
                    if (elem.platform === 'FACEBOOK') {
                        setFacebookToken(true)
                    }

                    if (elem.platform === "LINKEDIN") {
                        setLinkedInToken(true)
                    }

                    if (elem.platform === "YOUTUBE") {
                        setGoogleToken(true)
                    }

                    if (elem.platform === "TWITTER") {
                        settwitterToken(true)
                    }
                    if (elem.platform === "INSTAGRAM") {
                        setInstagramToken(true)
                    }

                    if (elem.platform === "PINTEREST") {
                        setPintrestToken(true)
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Frontend connect

    // const getIGID = async (access_token, fb_id) => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/ig-id`, {
    //         id: fb_id,
    //         accessToken: access_token
    //     })
    //         .then((res) => {
    //             uploadInstagramToken(access_token, res.data.id)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const uploadInstagramToken = async (token, id) => {
    //     var email;
    //     setLoader(true)

    //     if (localStorage.getItem("Email")) {
    //         email = localStorage.getItem("Email");
    //     }
    //     else {
    //         email = sessionStorage.getItem("Email");
    //     }

    //     await axios.patch(`${process.env.REACT_APP_BACKEND_API}/token/add-insta/${email}`, {
    //         insta_access_token: token,
    //         insta_id: id
    //     })
    //         .then((res) => {
    //             setLoader(false)
    //             message.success("Instagram connected")
    //         })
    //         .catch((err) => {
    //             setLoader(false)
    //             console.log(err)
    //         })
    // }


    // const uploadTwitterToken = async (token) => {
    //     var email;
    //     setLoader(true)

    //     if (localStorage.getItem("Email")) {
    //         email = localStorage.getItem("Email");
    //     }
    //     else {
    //         email = sessionStorage.getItem("Email");
    //     }

    //     await axios.patch(`${process.env.REACT_APP_BACKEND_API}/token/add-tw/${email}`, {
    //         tw_access_token: token
    //     })
    //         .then((res) => {
    //             setLoader(false)
    //             message.success("Twitter connected")

    //         })
    //         .catch((err) => {
    //             setLoader(false)
    //             console.log(err)
    //         })
    // }

    // const AddPintrest = async (token) => {
    //     var email;
    //     setLoader(true);
    //     if (localStorage.getItem("Email")) {
    //         email = localStorage.getItem("Email");
    //     }
    //     else {
    //         email = sessionStorage.getItem("Email");
    //     }

    //     await axios.patch(`${process.env.REACT_APP_BACKEND_API}/token/add-pinterest/${email}`, {
    //         pinterest_access_token: token,
    //     })
    //         .then((res) => {
    //             setLoader(false)
    //             message.success("Pintrest connected")
    //         })
    //         .catch((err) => {
    //             setLoader(false)
    //             console.log(err)
    //         })
    // }

    // const LogOutIG = () => {
    //     var email;
    //     setLoader(true);
    //     if (localStorage.getItem("Email")) {
    //         email = localStorage.getItem("Email");
    //     }
    //     else {
    //         email = sessionStorage.getItem("Email");
    //     }
    //     axios.patch(`${process.env.REACT_APP_BACKEND_API}/token/add-insta/${email}`, {
    //         insta_access_token: '',
    //         insta_id: ''
    //     })
    //         .then((res) => {
    //             message.success("Instagram Disconneced");
    //             // getTokens()
    //             window.location.reload()

    //             setLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const LogOutTwitter = () => {
    //     var email;
    //     setLoader(true);
    //     if (localStorage.getItem("Email")) {
    //         email = localStorage.getItem("Email");
    //     }
    //     else {
    //         email = sessionStorage.getItem("Email");
    //     }
    //     axios.patch(`${process.env.REACT_APP_BACKEND_API}/token/add-tw/${email}`, {
    //         tw_access_token: ''
    //     })
    //         .then((res) => {
    //             message.success("Twitter Disconneced");
    //             // getTokens()
    //             window.location.reload()

    //             setLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const logOutPin = () => {
    //     var email;
    //     setLoader(true);
    //     if (localStorage.getItem("Email")) {
    //         email = localStorage.getItem("Email");
    //     }
    //     else {
    //         email = sessionStorage.getItem("Email");
    //     }
    //     axios.patch(`${process.env.REACT_APP_BACKEND_API}/token/add-pinterest/${email}`, {
    //         pinterest_access_token: ''
    //     })
    //         .then((res) => {
    //             message.success("Pintrest Disconneced")
    //             window.location.reload()
    //             setLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // Frontend connect end

    // facebook connect disconnect start

    const LoginFb = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/facebook/auth`)
            .then((res) => {
                // Open the Facebook login page in a new window
                const popup = window.open(res.data.authUrl, 'Facebook Login', 'width=1000,height=600');

                setInterval(function () {
                    console.log(popup.closed === true)
                    if (popup.closed === true) {
                        getProfile()
                    }
                }
                    , 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const LogOutFB = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }


        setLoader(true);

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/profiles/delete-profile/FACEBOOK`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                message.success("Facebook Disconnected");
                getProfile();
                window.location.reload();
            })
            .catch((err) => {
                setLoader(false);
                message.error("There is some error try again");
            })
    }

    // facebook connect disconnect end

    // linkedin connect disconnect start
    const Linkedin = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/linkedin/auth`)
            .then((res) => {
                // Open the Facebook login page in a new window
                const popup = window.open(res.data.authUrl, 'Linkedin Login', 'width=1000,height=600');

                setInterval(function () {
                    console.log(popup.closed === true)
                    if (popup.closed === true) {
                        getProfile()
                    }
                }
                    , 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const LogOutLinkedin = () => {
        setLoader(true);

        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/profiles/delete-profile/LINKEDIN`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                getProfile();
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // linkedin connect disconnect end

    // Youtube connect start
    const LoginYT = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/youtube/auth`)
            .then((res) => {
                const popup = window.open(res.data.authUrl, 'Youtube Login', 'width=1000,height=600');

                setInterval(function () {
                    console.log(popup.closed === true)
                    if (popup.closed === true) {
                        getProfile()
                    }
                }
                    , 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const LogOutYoutube = () => {
        setLoader(true);
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/profiles/delete-profile/YOUTUBE`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                getProfile();
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // Youtube connect end

    // Instagram connect start
    const LoginInsta = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/instagram/auth`)
            .then((res) => {
                const popup = window.open(res.data.authUrl, 'Instagram Login', 'width=1000,height=600');

                setInterval(function () {
                    console.log(popup.closed === true)
                    if (popup.closed === true) {
                        getProfile()
                    }
                }
                    , 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const LogOutInsta = () => {
        setLoader(true);
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/profiles/delete-profile/INSTAGRAM`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                getProfile();
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // Insta connect end


    // Twitter connect start
    const LoginTwitter = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/twitter/auth`)
            .then((res) => {
                const popup = window.open(res.data.authUrl, 'Twitter Login', 'width=1000,height=600');

                setInterval(function () {
                    console.log(popup.closed === true)
                    if (popup.closed === true) {
                        getProfile()
                    }
                }
                    , 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const LogOutTwitter = () => {
        setLoader(true);
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/profiles/delete-profile/TWITTER`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                getProfile();
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // Twitter connect end

    // Pintrest Connect Start
    const LoginPintrest = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/pinterest/auth`)
            .then((res) => {
                const popup = window.open(res.data.authUrl, 'Pinterest Login', 'width=1000,height=600');

                setInterval(function () {
                    console.log(popup.closed === true)
                    if (popup.closed === true) {
                        getProfile()
                    }
                }
                    , 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const LogOutPintrest = () => {
        setLoader(true);
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/profiles/delete-profile/PINTEREST`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                getProfile();
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // Pintrest Connect End

    useEffect(() => {
        getProfile()
    }, [isLoader])

    if (isLoader) {
        return (
            <div id='overlay'>
                <div style={{ textAlign: "center" }}>
                    <img src={require('../Assets/logo_symbol.png')} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='profile-connect'>
                <h2>Connect Profile</h2>
            </div>

            <hr />

            <div className='profile-section'>
                <h6>
                    Select a network to connect a profile.
                </h6>

                <p className='profile_head2'>
                    If you have several accounts to connect, don’t worry—we’ll bring you back to this screen after each profile is set up.
                </p>
            </div>

            <div className='social-links'>
                <div className='socials'>
                    <div className='social-logo'>
                        {/* <i class="fa-brands fa-facebook fa-2x" style={{color:"#1877F2"}}></i> */}
                        <img src={require('../Assets/Icons/Facebook.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Facebook</label>
                    </div>

                    <div style={{ display: "flex", justifyContent: 'center', padding: '10px' }}>
                        {
                            facebookToken
                                ?
                                <button className='connect-btn' onClick={() => { LogOutFB() }}>
                                    Disconnect
                                </button>
                                :
                                <button className='connect-btn' onClick={LoginFb}>
                                    Connect
                                </button>
                        }
                    </div>
                </div>

                <div className='socials instagram'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/Instagram.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Instagram</label>
                    </div>

                    <div style={{ justifyContent: 'center', padding: '10px' }}>
                        {
                            instagramToken
                                ?
                                <button className='connect-btn' onClick={() => { LogOutInsta() }}>
                                    Disconnect
                                </button>
                                :
                                <button className='connect-btn' onClick={LoginInsta}>
                                    Connect
                                </button>
                        }
                    </div>
                </div>

                <div className='socials twitter'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/Twitter.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Twitter</label>
                    </div>


                    <div style={{ justifyContent: 'center', padding: '10px' }}>
                        {
                            twitterToken
                                ?
                                <button className='connect-btn' onClick={() => { LogOutTwitter() }}>
                                    Disconnect
                                </button>
                                :
                                <button className='connect-btn' onClick={LoginTwitter}>
                                    Connect
                                </button>
                        }
                    </div>


                </div>
            </div>

            <div className='social-links '>
                <div className='socials linkedin'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/LinkedIn.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>LinkedIn</label>
                    </div>

                    <div style={{ padding: '10px', justifyContent: 'center' }}>

                        {
                            LinkedinToken
                                ?
                                <button className='connect-btn' onClick={() => { LogOutLinkedin() }}>
                                    Disconnect
                                </button>
                                :
                                <button className='connect-btn' onClick={() => { Linkedin() }}>
                                    Connect
                                </button>
                        }

                    </div>
                </div>

                <div className='socials googlebusiness'>
                    <div className='social-logo d-flex justify-content-center align-items-center'>
                        <img src={require('../Assets/Icons/GB.png')} style={{ width: "30px" }} />
                        <img src={require('../Assets/Icons/Youtube.png')} style={{ width: "40px" }} />

                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Google My Business / Youtube</label>
                    </div>

                    <div style={{ padding: '10px', justifyContent: 'center' }}>
                        {
                            googleToken
                                ?
                                <button className='connect-btn' onClick={() => { LogOutYoutube() }}>
                                    Disconnect
                                </button>
                                :
                                <button className='connect-btn' onClick={() => { LoginYT() }}>
                                    Connect
                                </button>
                        }
                    </div>
                </div>

                <div className='socials pintestbusiness'>
                    <div className='social-logo d-flex justify-content-center align-items-center'>
                        <img src={require('../Assets/Icons/Pintrest.png')} style={{ width: "30px" }} />

                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Pinterest</label>
                    </div>

                    <div style={{ padding: '10px', justifyContent: 'center' }}>
                        {
                            PintrestToken
                                ?
                                <button className='connect-btn' onClick={() => { LogOutPintrest() }}>
                                    Disconnect
                                </button>
                                :
                                <button className='connect-btn' onClick={() => { LoginPintrest() }}>
                                    Connect
                                </button>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
