import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Spin } from 'antd';
import { EngagmentGrowth } from '../../store/Slices/EngagementSlice';
import { AnalyticseGrowth } from '../../store/Slices/AnalyticsSlice';
import { getEngagementData } from '../../services/group';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);




export default function Engagementchart() {
    const [InstaImpression, setInstaImpression] = useState([]);
    const [isLoader, setIsloader] = useState(true);
    const [label, setLabel] = useState([]);
    const [FbImpressions, setFbImpressions] = useState([]);
  
    const [PintrestEng, setPintrestEng] = useState([]);

    const [AudienceGrowths, setAudienceGrowths] = useState(null);
    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });
    const {selectedGroup} = useSelector((state) => {
        return state.groups
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const Filter = useSelector((state) => {
        return state.Filter
    })

    const dispatch = useDispatch()
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                }
            },
        },
        elements: {
            point: {
                radius: 0.5
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }
    };
    const data = {
        datasets: [
            {
                label: 'Instagram', // Name the series
                data: InstaImpression, // Specify the data values array
                fill: true,
                borderColor: '#CE3665', // Add custom color border (Line)
                backgroundColor: '#CE3665', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.4
            },
            {
                label: 'Facebook', // Name the series
                data: FbImpressions, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.3
    
            },
            // {
            //     label: 'Linkedin', // Name the series
            //     data: LinkedinEng, // Specify the data values array
            //     fill: true,
            //     borderColor: '#FFCD1C', // Add custom color border (Line)
            //     backgroundColor: '#FFCD1C', // Add custom color background (Points and Fill)
            //     borderWidth: 0.4, // Specify bar border width
            //     tension: 0.3
            // },
            // {
            //     label: 'Twitter', // Name the series
            //     data: TwitterEng, // Specify the data values array
            //     fill: true,
            //     borderColor: '#08C4B2', // Add custom color border (Line)
            //     backgroundColor: '#08C4B2', // Add custom color background (Points and Fill)
            //     borderWidth: 1, // Specify bar border width
            //     tension: 0.3
            // },
            {
                label: 'Pintrest', // Name the series
                data: PintrestEng, // Specify the data values array
                fill: true,
                borderColor: '#3896E3', // Add custom color border (Line)
                backgroundColor: '#3896E3', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.3
            }
        ],
    };

    const FBEngeProcess = (newValues) => {
        const FBRAWData = newValues.flat(); // Flatten the array instead of looping
    
        // Aggregate values by end_time
        const FbnewObj = Object.values(
            FBRAWData.reduce((acc, { end_time, value }) => {
                acc[end_time] = acc[end_time] || { end_time, value: 0 };
                acc[end_time].value += Number(value); // Directly add values
                return acc;
            }, {})
        );
    
        // Initialize with the start date
        FbImpressions.push({
            x: new Date(Sinceselector[0] * 1000).toISOString().split("T")[0].slice(5), // "MM-DD"
            y: 0,
        });
    
        // Map data into required format
        FbImpressions.push(
            ...FbnewObj.map(({ end_time, value }) => ({
                x: end_time.split("T")[0].slice(5), // Extract "MM-DD"
                y: value,
            }))
        );
    };
    
    // Pinterest Data Process
    const PinEngagementProcess = (PinEngagement) => {
        // Transform and aggregate data
        const PinnewObj = Object.values(
            PinEngagement.reduce((acc, { date, metrics, data_status }) => {
                acc[date] = acc[date] || { end_time: date, value: 0 };
                acc[date].value += data_status === "READY" ? metrics.ENGAGEMENT : 0;
                return acc;
            }, {})
        );
    
        // Map data into required format
        PintrestEng.push(
            ...PinnewObj.map(({ end_time, value }) => ({
                x: end_time.slice(5), // Extract "MM-DD"
                y: value,
            }))
        );
    };

    const InstagramEngagementProcess = (instaEngagement) => {
        // Transform and aggregate data
        const PinnewObj = Object.values(
            instaEngagement.reduce((acc, { date, metrics, data_status }) => {
                acc[date] = acc[date] || { end_time: date, value: 0 };
                acc[date].value += data_status === "READY" ? metrics.ENGAGEMENT : 0;
                return acc;
            }, {})
        );
    
        // Map data into required format
        PintrestEng.push(
            ...PinnewObj.map(({ end_time, value }) => ({
                x: end_time.slice(5), // Extract "MM-DD"
                y: value,
            }))
        );
    };
    

    const GetEngagement = async () => {
        try {
          setIsloader(true);
      
          const analytics = await getEngagementData(Filter, Sinceselector, untilSelector,selectedGroup);
      
          let FBEngagement = [];
          let PinEngagement = [];
          let InstaImpression = [];
      
          if (analytics.FACEBOOK?.length) {
            analytics.FACEBOOK.forEach((elem) => {
              elem.data.forEach((FBelem) => {
                if (FBelem.name === "page_post_engagements") {
                  FBEngagement.push(FBelem.values);
                }
              });
            });
          }
      
          if (analytics.PINTEREST?.length) {
            analytics.PINTEREST.forEach((elem) => {
              elem.analytics.all.daily_metrics.forEach((data) => {
                PinEngagement.push(data);
              });
            });
          }
          if (analytics.INSTAGRAM?.length) {
            analytics.INSTAGRAM.forEach((elem) => {
              elem.data.values.forEach((data) => {
                InstaImpression.push(data);
              });
            });
          }
      
          if (FBEngagement.length) FBEngeProcess(FBEngagement);
          if (PinEngagement.length) PinEngagementProcess(PinEngagement);
          if (InstaImpression.length) InstagramEngagementProcess(InstaImpression);
      
          const EngagementGrow = data.datasets[1].data.reduce(
            (acc, curr) => acc + curr.y,
            0
          );
          
          dispatch(EngagmentGrowth(EngagementGrow));
          dispatch(AnalyticseGrowth(analytics));
          
        } catch (error) {
          console.error("Engagement data fetch failed:", error);
        } finally {
          setIsloader(false);
        }
      };

   

    useEffect(() => {
        if (Filter.length !== 0) {
            GetEngagement()
        }
    }, [Sinceselector, untilSelector, Filter,selectedGroup])

    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Engagement
                    </label>

                    <label className='label_sub_head'>
                        See how people are engaging with your posts during the reporting period.
                    </label>
                </div>

                <div style={{ height: '40vh' }}>
                    {
                        isLoader
                            ?
                            <div className='d-flex align-items-center justify-content-center'>
                                <Spin />
                            </div>
                            :
                            <Line options={options} data={data} />
                    }
                </div>

                <div className='w-100 px-3'>
                    <table className='w-100 table chart_table'>
                        <tr>
                            <th>
                                Engagement Metrics
                            </th>

                            <th>
                                Totals
                            </th>

                            <th>
                                % Change
                            </th>
                        </tr>

                        <tbody>
                            <tr>
                                <td>
                                    Total Engagement
                                </td>

                                <td>
                                    {

                                        isLoader
                                            ?
                                            // data.datasets[0].data.reduce((accumulator, currentValue) =>
                                            //     accumulator + currentValue.y, 0
                                            // ) +
                                            // data.datasets[1].data.reduce((accumulator, currentValue) =>
                                            //     accumulator + currentValue.y, 0
                                            // ) +
                                            // data.datasets[2].data.reduce((accumulator, currentValue) =>
                                            //     accumulator + currentValue.y, 0
                                            // )

                                            data.datasets[1].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            )
                                            :
                                            0
                                    }
                                </td>

                                <td>
                                    2.1%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Total Net Audience Growth
                                </td>

                                <td>

                                </td>

                                <td>
                                    130%
                                </td>
                            </tr>

                            {
                                InstaImpression.length !== 0
                                    ?
                                    <tr>
                                        <td>
                                            Instagram Engagement
                                        </td>
                                        <td>
                                            {
                                                InstaImpression.length !== 0
                                                    ?
                                                    data.datasets[0].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                            {
                                FbImpressions.length !== 0
                                    ?
                                    <tr>
                                        <td>
                                            Facebook Engagement
                                        </td>
                                        <td>
                                            {
                                                FbImpressions.length !== 0
                                                    ?
                                                    data.datasets[1].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                            {
                                PintrestEng.length !== null
                                    ?
                                    <tr>
                                        <td>
                                            Pintrest Engagement
                                        </td>
                                        <td>
                                            {
                                                PintrestEng.length !== 0
                                                    ?
                                                    data.datasets[2].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
