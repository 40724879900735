import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Modal, Spin, Checkbox } from "antd";
import dayjs from "dayjs";
import { ChangeSinceDate } from "../store/Slices/DateSlice";
import { ChangeUntilDate } from "../store/Slices/UntilDateSlice";
import { getPost } from "../services/publish";
import PostCard from "./PostCard";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/DD/MM";
const FilterData = [
  ["Source", ["Instagram", "Facebook", "Pinterest"]],
  ["Type", ["Image", "Video"]],
];

const MonthsString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export default function PostReport() {
  const dispatch = useDispatch();
  const { selectedGroup } = useSelector((state) => state.groups);
  const Sinceselector = useSelector((state) => state.SinceDates);
  const untilSelector = useSelector((state) => state.UntilDates);

  const [showFilter, setShowFilter] = useState(false);
  const [posts, setPosts] = useState([]);
  const [platforms, setPlatforms] = useState(["INSTAGRAM", "FACEBOOK", "PINTEREST"]);
  const [mediaType, setMediaType] = useState("image");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewType, setPreviewType] = useState("image");
  const [activeTab, setActiveTab] = useState("Post Report");

  const d = new Date();
  var months = (d.getMonth() + 1).toString().padStart(2, "0");
  var day = d.getDate().toString().padStart(2, "0");
  const fetchPosts = useCallback(async () => {
    setLoader(true);
    const since = new Date(Sinceselector[0] * 1000);
    const until = new Date(untilSelector[0] * 1000);
    const res = await getPost({
      groupName: selectedGroup,
      startDate: `${since.getFullYear()}-${String(since.getMonth() + 1).padStart(2, "0")}-${String(since.getDate()).padStart(2, "0")}`,
      endDate: `${until.getFullYear()}-${String(until.getMonth() + 1).padStart(2, "0")}-${String(until.getDate()).padStart(2, "0")}`,
      mediaType,
      page: currentPage,
      pageSize: "12",
      platforms,
    });

    if (!res.error) {
      setPosts(res.data.data.posts);
      setTotalPages(res.data.data.totalPages);
    }
    setLoader(false);
  }, [Sinceselector, untilSelector, currentPage, mediaType, platforms, selectedGroup]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handlePlatformFilter = (platformName, checked) => {
    const platformKey = platformName.toUpperCase();
    setPlatforms((prev) =>
      checked.target.checked ? [...prev, platformKey] : prev.filter((p) => p !== platformKey)
    );
  };

  const handleTypeFilter = (typeName, checked) => {
    const selectedTypes = checked.target.checked ? [typeName.toLowerCase()] : [];
    setMediaType(selectedTypes.length === 1 ? selectedTypes[0] : "");
  };

  if (loader) {
    return (
      <div className="loader_section">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="post_report_section">
      <div className="approval_header performace_header">
        <div className="performance_inner">
          <h4>Post Report</h4>
          <span className="date_txt p-1 ">
            Activity from {`${MonthsString[new Date(Sinceselector[0] * 1000).getMonth()]} ${new Date(Sinceselector[0] * 1000).getDate()}, ${new Date(Sinceselector[0] * 1000).getFullYear()}`} -
            {`${MonthsString[new Date(untilSelector[0] * 1000).getMonth()]} ${new Date(untilSelector[0] * 1000).getDate()}, ${new Date(untilSelector[0] * 1000).getFullYear()}`}
          </span>
        </div>

        <div className="d-flex align-items-center">
          <span className="Inbox_remaining performace_date">
            <RangePicker
              format={dateFormat}
              style={{ backgroundColor: "transparent", border: "none" }}
              defaultValue={[
                dayjs(
                  d.getFullYear() + "/" + "01" + "/" + months,
                  dateFormat
                ),
                dayjs(d.getFullYear() + "/" + day + "/" + months, dateFormat),
              ]}
              onChange={(e) => {
                dispatch(ChangeSinceDate(Date.parse(e[0].$d) / 1000));
                dispatch(ChangeUntilDate(Date.parse(e[1].$d) / 1000));
              }}
            />
          </span>
          <div>
            <span className="Inbox_remaining">
              <i class="fa fa-ellipsis-h"></i>
            </span>
          </div>

          <div>
            <span className="Inbox_remaining">
              <i class="fa fa-upload" style={{ marginRight: "5px" }}></i>
              Export
            </span>
          </div>

          <div>
            <button
              className="Inbox_remaining Filter_btn"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <img src={require("../Assets/Filter.png")} />
              Filters
            </button>
          </div>
        </div>
      </div>

      {showFilter && (
        <div className="filter_section">
          {FilterData.map(([title, options], idx) => (
            <div key={idx} className="filter_block">
              <h5>{title}</h5>
              {options.map((option) => (
                <Checkbox
                  key={option}
                  checked={platforms.includes(option.toUpperCase())}
                  onChange={(e) => handlePlatformFilter(option, e)}
                >
                  {option}
                </Checkbox>
              ))}
            </div>
          ))}
        </div>
      )}

      <div className="d-flex w-100 mt-2">
        {['Post Report', 'Competitor Post Report'].map((tab) => (
          <button
            key={tab}
            className={`w-50 post_performance_tabs ${activeTab === tab ? 'post_performance_tabs_active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <Modal
        open={showPreview}
        footer={false}
        onCancel={() => setShowPreview(false)}
        closable={false}
        className="preview_img_modal"
      >
        <div style={{ textAlign: "center" }}>
          {previewType === "image" ? (
            <img className="preview_img" src={previewUrl} alt="Preview" />
          ) : (
            <video className="preview_img" src={previewUrl} controls />
          )}
        </div>
      </Modal>

      <PostCard
        Posted={posts}
        setShowpreview={setShowPreview}
        setPreviewType={setPreviewType}
        setPreviewUrl={setPreviewUrl}
      />
    </div>
  );
}



const CompetitorPostReport = (Posted) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ flexWrap: "wrap" }}
    >
      {Posted && Posted.length > 0 ? (
        Posted.map((elem) => {
          return (
            <div className="socials uploaded_posts instagram mt-5 mx-2 box_size">
              <div className="d-flex align-items-center post_username">
                <div>
                  <img
                    src={require("../Assets/Rectangle.png")}
                    className="user_profile_image"
                  />
                </div>

                <div className="d-flex flex-column align-items-center mx-2">
                  <div className="d-flex align-items-center w-100">
                    <i class="fa-brands fa-facebook"></i>

                    <span className="username">Starbucks</span>
                  </div>

                  <div>
                    <span className="post_date">
                      2024-04-06T11:07:01+0000
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <span className="post_caption">Captions</span>

                <div>
                  <img
                    src={require("../Assets/dummy_post.png")}
                    className="post_imgs"
                  />
                </div>
                <div>
                  <table className="post_details table">
                    <tbody>
                      <tr>
                        <td>Public Engagements</td>

                        <td>188,213</td>
                      </tr>

                      <tr>
                        <td>Likes</td>

                        <td>187,299</td>
                      </tr>

                      <tr>
                        <td>Comments</td>

                        <td>914</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center mt-5">
          <p className="text-gray-500">No competitor posts available</p>
        </div>
      )}
    </div>
  );
};