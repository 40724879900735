import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { message, Progress } from "antd";
import EmojiPicker from "emoji-picker-react";
import "react-quill/dist/quill.snow.css";
import { DatePicker } from "antd";
import FacebookModal from "./Social Desings/FacebookModal";
import InstagarmModal from "./Social Desings/InstagarmModal";
import { Spin, Modal } from "antd";
import { useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import { LoadingOutlined } from "@ant-design/icons";
import Pinerest from "./Social Desings/Pinerest";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  generateCaptionApi,
  createBoardApi,
  getBoardApi,
  postApi,
} from "../services/publish";
import ShowProfiles from "./ShowProfiles";
import moment from "moment";
import { selectGroupAndFetchDetails } from "../store/Slices/GroupSlice";
import UserName from "../Comman Components/UserName";
import PinName from "../Comman Components/Pinterest Componets/PinName";
import PostUploader from "./PostUploader";

export default function PublishPage() {
  const navigate = useNavigate();
  const { profile, selectedGroup } = useSelector((state) => state.groups);

  const [messages, setMessage] = useState("");
  const dispatch = useDispatch();

  const [emoji, setEmoji] = useState();

  const uploadfile = useRef(null);
  const messageRef = useRef(null);
  const [datepickertoggle, setDatePickerToggle] = useState(false);
  const [PhotoPreview, setPhotoPreview] = useState([]);
  const [PublishType, setPublishType] = useState("Multiple");
  const [uploadMenu, setUploadMenu] = useState(false);
  const [uploadedImg, setUploadImg] = useState([]);
  const [postBtnEnable, setPostButtonEnable] = useState(true);
  const [progess, setProgess] = useState(0);
  const [isLoader, setLoader] = useState(false);
  const [fileType, setFileType] = useState("text");
  const [APIwait, setAPIWait] = useState(false);
  const [singlePage, setSinglepage] = useState([]);
  const [boardList, setBoardList] = useState(false);
  const [newBoardName, setNewBoardName] = useState(null);
  const [newBoardDes, setNewBoardDesc] = useState(null);
  const [BoardID, setBoardID] = useState();
  const [DefaultBorad, setDefaultBoard] = useState();
  const [outputResult, setOutputResult] = useState();
  // insta first comment
  const [instaContentData, setInstaContentData] = useState({
    firstComment: "",
    collaborators: [],
  });
  const [timeZone, setTimeZone] = useState("Asia/Kolkata");
  const handleChangeInstaContent = (e) => {
    setInstaContentData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [showList, setShowList] = useState(false);
  const [SelectedPlatform, setSelectedPlatform] = useState(null);

  const [scheduleDates, setScheduleDates] = useState([
    { date: "", time: "", ampm: "AM" },
  ]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const location = useLocation();

  // Youtube option
  const [title, setTitle] = useState("");

  const [onboarding, setOnboarding] = useState(null);
  const [captionLoad, setCaptionLoad] = useState(false);
  const [PintBoardList, setPintBoardList] = useState();
  const [openCreateboard, setopenCreateboard] = useState(false);

  // Facebook Option
  const [description, setDesc] = useState("");

  const MonthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Platform Posting

  // Add emoji in message
  const AddEmoji = (emojiObject) => {
    const cursor = messageRef.current.selectionStart;
    const text =
      messages.slice(0, cursor) + emojiObject.emoji + messages.slice(cursor);

    setMessage(text);
    setEmoji(false);
  };

  // Change Date formate
  const changeDateFormate = (date) => {
    const newscheduledDate = new Date(date);

    // setScheduleDates(newscheduledDate);
  };

  // Upload image to AWS
  const uploadStocket = async (e) => {
    // Return early if no file was selected
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    if (PublishType === "REELS") {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension !== "mp4") {
        message.error("Please Upload the Video in mp4 format");
        return;
      }
    }

    if (file.type.split("/")[0] === "image") {
      setFileType("photo");
    } else {
      setFileType("video");
    }

    let filename = file.name
      .replace(/\s+/g, "")
      .replace(".", `${Date.now() + "."}`);

    try {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: process.env.REACT_APP_REGION,
      });

      const params = {
        Bucket: "oolook-posting",
        Key: filename,
        Body: file,
        ContentType: file.type,
      };

      const options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
      };

      const upload = s3.upload(params, options);

      upload.on("httpUploadProgress", (progressData) => {
        const percentageProgress = Math.round(
          (progressData.loaded / progressData.total) * 100
        );
        setProgess(percentageProgress);
      });

      // Use a single upload promise instead of nested callbacks
      const result = await upload.promise();

      // Update PhotoPreview with the new URL
      setPhotoPreview([...PhotoPreview, result.Location]);
      setUploadMenu(false);
      message.success(`File Uploaded Successfully`);
    } catch (error) {
      console.error("Upload failed:", error);
      message.error(`Failed to upload file: ${error.message}`);
    } finally {
      // Reset the file input
      if (uploadfile.current) {
        uploadfile.current.value = "";
      }
    }
  };

  const GetOnboardingData = () => {
    let token;

    if (localStorage.getItem("user_Token")) {
      token = localStorage.getItem("user_Token");
    } else {
      token = sessionStorage.getItem("user_Token");
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/onboarding/get-onboarding`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res)
        setOnboarding(res.data.OnBoarding);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Generate Captions
  const GenerateCaption = async () => {
    try {
      setCaptionLoad(true);
      let res = await generateCaptionApi(PhotoPreview[0]);

      if (!res.error) {
        setMessage(res.data);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCaptionLoad(false);
    }
  };

  // **Post Content (Handles Scheduled & Instant Posting)**
  const Post = async (type) => {
    try {
      setAPIWait(true);

      // Basic validation
      if (!SelectedPlatform || SelectedPlatform.length === 0) {
        message.error("Please select at least one platform");
        return;
      }

      // Remove duplicate platform selections
      const uniqueSelections = SelectedPlatform.reduce((acc, current) => {
        const key = `${current.platform}_${current.platform === "FACEBOOK"
          ? current.platform_pageId
          : current.platform_id
          }`;
        if (
          !acc.find(
            (item) =>
              `${item.platform}_${item.platform === "FACEBOOK"
                ? item.platform_pageId
                : item.platform_id
              }` === key
          )
        ) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Continue with other validations
      if (!PhotoPreview || PhotoPreview.length === 0) {
        message.error("Please select media to post");
        return;
      }

      if (!messages && PublishType !== "STORIES") {
        message.error("Please add a message for your post");
        return;
      }

      // Validate media type for Instagram reels
      if (
        PublishType === "REELS" &&
        uniqueSelections.some((p) => p.platform === "INSTAGRAM")
      ) {
        const videoUrl = PhotoPreview[0];
        if (!videoUrl.toLowerCase().endsWith(".mp4")) {
          message.error("Instagram reels must be in MP4 format");
          return;
        }
      }

      // console.log('scheduleDates', scheduleDates);

      // Additional validation for scheduled posts
      if (Array.isArray(scheduleDates) && scheduleDates.length > 0) {
        console.log("scheduleDates", scheduleDates);
        // throw error if date and time is not provided
        if (
          scheduleDates.some(({ date, time, ampm }) => !date || !time || !ampm)
        ) {
          message.error("Date and time must be provided.");
          return;
        }

        const now = moment(); // Get the current time
        const formatDateTime = (dateString) => {
          const scheduledTime = new Date(dateString);

          return `${scheduledTime.getFullYear()}-${(
            "0" +
            (scheduledTime.getMonth() + 1)
          ).slice(-2)}-${("0" + scheduledTime.getDate()).slice(-2)}T${(
            "0" + scheduledTime.getHours()
          ).slice(-2)}:${("0" + scheduledTime.getMinutes()).slice(-2)}:00`;
        };

        const formattedScheduleDates = scheduleDates.map((item) => ({
          date: formatDateTime(item.date),
          time: formatDateTime(item.time),
          ampm: item.ampm,
        }));
        console.log("formattedScheduleDates", formattedScheduleDates);

        // throw error if date and time are not future use formattedScheduleDates
        // if (
        //   formattedScheduleDates.some(({ date, time, ampm }) => {
        //     const scheduleDateTime = moment.tz(
        //       `${date} ${time} ${ampm}`,
        //       "YYYY-MM-DD hh:mm A",
        //        timeZone
        //     ).utc();
        //     return (
        //       !scheduleDateTime.isValid() || !scheduleDateTime.isAfter(now)
        //     );
        //   })
        // ) {
        //   message.error("Scheduled time must be in the future");
        //   return;
        // }
      }

      let res = await postApi({
        PhotoPreview,
        scheduleDates: scheduleDates || [new Date()],
        SelectedPlatform: uniqueSelections, // Use deduplicated selections
        PublishType,
        title,
        messages,
        description,
        BoardID: BoardID.id||DefaultBorad,
        fileType,
        instaContentData,
      });
      setOutputResult(res);

      if (!res.error) {
        message.success(
          scheduleDates ? "Post Scheduled Successfully" : "Posted Successfully"
        );

        if (type === "schedule") {
          navigate("/Dashboard/Publishing");
        } else if (type === "scheduleNew") {
          window.location.reload();
        }
        console.log("Post result:", res);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      console.error("Post error:", error);
      message.error(
        error.message || "Failed to post content. Please try again."
      );
    } finally {
      setAPIWait(false);
    }
  };

  // **Delete Images**
  const DeleteImage = async (
    platform,
    images,
    PhotoPreview,
    setPhotoPreview,
    setUploadMenu,
    PublishType
  ) => {
    if (platform === "Facebook") {
      setPhotoPreview([]);
    } else if (platform === "Instagram") {
      if (PublishType === "Multiple") {
        setPhotoPreview(PhotoPreview.filter((img) => img !== images));
        setUploadMenu(true);
      } else {
        setPhotoPreview([]);
      }
    }
  };

  // **Get Pinterest Boards**
  const GetBoard = async (boardID) => {
    try {
      let res = await getBoardApi(boardID);
      console.log("get board run check");

      if (!res.error) {
        setPintBoardList(res.data);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // **Create Pinterest Board**
  const CreateBoard = async () => {
    if (!newBoardName) {
      message.error("Enter Board Name");
      return;
    }

    try {
      let res = await createBoardApi({
        DefaultBoard: DefaultBorad,
        newBoardName,
        newBoardDes,
      });

      if (!res.error) {
        message.success("New Board Created");
        setopenCreateboard(false);
        GetBoard(DefaultBorad);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get Selected Group Details
  const GetGroups = () => {
    // dispatch(fetchGroups());

    dispatch(selectGroupAndFetchDetails(selectedGroup));
    // console.log(profile,'profilprofileprofileprofile',selectedGroup)
    var selePlatform;

    selePlatform = profile.slice(0);

    const indexToRemove = selePlatform.findIndex(
      (item) => item.platform === "PINTEREST"
    );

    // If a Pinterest object is found, remove it
    if (indexToRemove !== -1) {
      selePlatform.splice(indexToRemove, 1);
    }
    setSelectedPlatform(selePlatform);

    selePlatform.map((elem) => {
      if (elem.platform === "PINTEREST") {
        console.log(elem)
        setDefaultBoard(elem.platform_id);
        GetBoard(elem.platform_id);
      }
    });
    // setSelectedGroup()
  };

  // check value for checkbox
  const CheckPlatform = (value) => {
    console.log("CheckPlatform run check");

    if (value.platform === "FACEBOOK") {
      return SelectedPlatform.some(
        (item) => item.platform_pageId === value.platform_pageId
      );
    }

    if (value.platform === "INSTAGRAM" || value.platform === "PINTEREST") {
      return SelectedPlatform.some(
        (item) => item.platform_id === value.platform_id
      );
    }

    return false;
  };

  // Push Data to Selected Array
  const ChangePlatform = (value, checked) => {
    console.log(value, checked, "valuevaluevaluevalue");
    if (checked) {
      setSelectedPlatform((prev) => [...prev, value]);
      if (value.platform === "PINTEREST") setDefaultBoard(value.platform_id);
    } else {
      setSelectedPlatform((prev) =>
        prev.filter((item) =>
          value.platform === "FACEBOOK"
            ? item.platform_pageId !== value.platform_pageId
            : item.platform_id !== value.platform_id
        )
      );
    }
  };

  useEffect(() => {
    if (location.state?.date === new Date().getDate()) {
      if (location.state?.month + 1 === new Date().getMonth() + 1) {
        changeDateFormate(new Date());
      }
    } else {
      changeDateFormate(
        new Date(
          new Date().getFullYear() +
          "-" +
          (location.state?.month + 1) +
          "-" +
          location.state?.date
        )
      );
    }
    GetOnboardingData();
    // GetBoard();
    GetGroups();
  }, []);

  // Use useEffect to fetch GetBoard only when a PINTEREST profile is available
  useEffect(() => {
    const pinterestProfiles = profile?.filter(
      (p) => p.platform === "PINTEREST"
    );
    if (pinterestProfiles?.length > 0) {
      pinterestProfiles.forEach((p) => GetBoard(p.platform_id));
    }
  }, [profile]);

  if (isLoader === true) {
    return (
      <div id="overlay">
        <img
          src={require("../Assets/logo_overlay.png")}
          className="overlay_logo"
        />
      </div>
    );
  }

  return (
    <div>
      {/* Header */}
      <div className="h-16 pl-4 py-2 flex items-center justify-start  ">
        <h1 className="text-xl font-bold text-start  ">New Post</h1>
      </div>
      <hr />

      <div className="flex justify-center items-center w-full  gap-10 mb-[60px] ">
        <div className="col-sm-8 twit-box1 mb-3 ">
          <div className="d-flex flex-column mt-3">
            <button
              className="d-flex w-100 list_btn justify-content-between align-items-center"
              onClick={() => {
                setShowList(!showList);
              }}
            >
              {/* Show Sprofielected Platform list */}
              <div className="d-flex">
                {SelectedPlatform &&
                  [...new Set(SelectedPlatform.slice(0, 3))].map(
                    (elem, index) => (
                      <span key={index} className="d-flex platform_name">
                        {renderPlatformIcon(elem.platform)}
                        {renderProfileName(elem)}
                        <span className="pl-2 pr-1">
                          <i
                            class="fa fa-times"
                            onClick={() => {
                              ChangePlatform(elem, false);
                            }}
                          ></i>
                        </span>
                      </span>
                    )
                  )}
              </div>
              {/* Show Selcted platform length */}
              <div className="d-flex align-items-center">
                <span className="mx-2 more_txt">
                  {SelectedPlatform !== null ? (
                    <>
                      {SelectedPlatform.length <= 3
                        ? null
                        : SelectedPlatform.length - 3 + " " + "more..."}
                    </>
                  ) : null}
                </span>

                {showList ? (
                  <span className="pr-2">
                    <i class="fa fa-angle-up"></i>
                  </span>
                ) : (
                  <span className="pr-2">
                    <i class="fa fa-angle-down"></i>{" "}
                  </span>
                )}
              </div>
            </button>

            {/* Show List of Platforms in groups */}
            {showList && profile && (
              <div className="List_dropdown">
                <ul>
                  <ShowProfiles
                    avalibaleProfile={profile}
                    CheckPlatform={CheckPlatform}
                    ChangePlatform={ChangePlatform}
                  />
                </ul>
              </div>
            )}
          </div>

          <div className="d-flex">
            <div className="w-100">
              <div className="box1-small">
                <PostUploader
                  messages={messages}
                  setMessage={setMessage}
                  messageRef={messageRef}
                  timeZone={timeZone}
                  setTimeZone={setTimeZone}
                  PhotoPreview={PhotoPreview}
                  setPhotoPreview={setPhotoPreview}
                  fileType={fileType}
                  setFileType={setFileType}
                  uploadStocket={uploadStocket}
                  uploadfile={uploadfile}
                  setUploadMenu={setUploadMenu}
                  uploadMenu={uploadMenu}
                  GenerateCaption={GenerateCaption}
                  captionLoad={captionLoad}
                  setCaptionLoad={setCaptionLoad}
                  DeleteImage={DeleteImage}
                  uploadedImg={uploadedImg}
                  setUploadImg={setUploadImg}
                  setProgess={setProgess}
                  progess={progess}
                  setLoader={setLoader}
                  setAPIWait={setAPIWait}
                  AddEmoji={AddEmoji}
                  emoji={emoji}
                  setEmoji={setEmoji}
                  changeDateFormate={changeDateFormate}
                  scheduleDates={scheduleDates}
                  setScheduleDates={setScheduleDates}
                  datepickertoggle={datepickertoggle}
                  setDatePickerToggle={setDatePickerToggle}
                  SelectedPlatform={SelectedPlatform}
                  setPublishType={setPublishType}
                  PublishType={PublishType}
                  outputResult={outputResult}
                  handleChangeInstaContent={handleChangeInstaContent}
                  instaContentData={instaContentData}
                  // Pinterest-specific props
                  boardList={boardList}
                  setBoardList={setBoardList}
                  PintBoardList={PintBoardList}
                  setopenCreateboard={setopenCreateboard}
                  setBoardID={setBoardID}
                  BoardID={BoardID}
                  setTitle={setTitle}
                  setDesc={setDesc}
                />


                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {/* {captionLoad && <Spin />}

                  <textarea
                    disabled={PublishType === "STORIES" ? true : false}
                    value={messages}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    ref={messageRef}
                    placeholder="Start writing or"
                    style={{
                      color: "#59626a",
                      fontSize: "12",
                      marginTop: "10",
                      // border: "none",
                      width: "100%",
                      minHeight: "120px",
                      maxHeight: "120px",
                      resize: "none",
                      marginBottom: "20px",
                      border: "1px solid #d3d3d3",
                    }}
                  ></textarea> */}

                  {/* <div>
                                        <button className='assist-btn' onClick={() => { GenerateCaption() }}>Use the AI Assistant</button>
                                    </div> */}
                </div>

                {/* {PhotoPreview.length !== 0 ? (
                  <div className="row">
                    {PhotoPreview.map((elem) => {
                      return (
                        <div className="col-sm-3 image_preview_Section">
                          <button
                            className="cross_photo"
                            onClick={() => {
                              DeleteImage(elem);
                            }}
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </button>
                          <div>
                            {fileType === "video" ? (
                              <video
                                src={elem}
                                className="preview_imgs"
                              ></video>
                            ) : (
                              <img src={elem} className="preview_imgs" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null} */}

                <div
                  className="d-flex align-items-center"
                  style={{ flexBasis: 0 }}
                >
                  {uploadedImg.length === 0
                    ? null
                    : uploadedImg.map((elem, index) => {
                      return (
                        <div style={{ width: "100px" }}>
                          <img src={elem[index]} className="uploaded_imgs" />
                        </div>
                      );
                    })}
                  {/* <FileUploader multiple={true} children={dragNdrop()} handleChange={handelUploaded} name="file" types={fileTypes} /> */}
                </div>
              </div>
            </div>
          </div>

          {SelectedPlatform?.some((o) => o.platform === "FACEBOOK") &&
            PublishType === "REELS" && (
              <div className="mt-2 option_section">
                <div className="platform_field_names">Facebook Option</div>
                <table className="w-100 mt-2 table options_table">
                  <tbody>
                    {[
                      { label: "Title", type: "text", onChange: setTitle },
                      {
                        label: "Descriptions",
                        type: "textarea",
                        onChange: setDesc,
                      },
                    ].map(({ label, type, onChange }) => (
                      <tr key={label}>
                        <td>
                          <label>{label}</label>
                        </td>
                        <td>
                          <div className="w-100">
                            {type === "text" ? (
                              <input
                                type="text"
                                className="w-100"
                                onChange={(e) => onChange(e.target.value)}
                              />
                            ) : (
                              <textarea
                                className="w-100"
                                onChange={(e) => onChange(e.target.value)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

          {/* {SelectedPlatform?.some((o) => o.platform === "PINTEREST") &&
            PublishType === "Multiple" && (
              <div className="mt-2 option_section">
                <div className="platform_field_names">Pinterest Option</div>

                {[
                  {
                    label: "Pin Title",
                    placeholder: "Pin Title",
                    value: "",
                    onClick: null,
                  },
                  {
                    label: "Pin To",
                    placeholder: "Choose Board (Required)",
                    value: BoardID?.name || "",
                    onClick: () => setBoardList(!boardList),
                  },
                  {
                    label: "Destination URL",
                    placeholder: "Destination URL",
                    value: PhotoPreview[0] || "",
                  },
                ].map(({ label, placeholder, value, onClick }, index) => (
                  <div key={index} style={{ marginTop: index > 0 ? 15 : 0 }}>
                    <label className="pint-label">{label}</label>
                    <div>
                      <input
                        className="pint-input"
                        placeholder={placeholder}
                        value={value}
                        onClick={onClick}
                        readOnly={!!onClick}
                      />
                    </div>
                  </div>
                ))}

                {boardList && (
                  <div style={{ position: "relative", width: "100%" }}>
                    <div className="pint_board_list_menu">
                      <ul className="pint_board_list">
                        <li onClick={() => setopenCreateboard(true)}>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          <span className="mx-3">Create New Board</span>
                        </li>
                        {PintBoardList?.map((elem) => (
                          <li
                            key={elem.id}
                            onClick={() => {
                              alert('ihhihihiih')
                              setBoardID(elem);
                              setBoardList(false);
                            }}
                          >
                            <img
                              src={elem.media.image_cover_url}
                              className="board_list_img"
                              alt="Board Cover"
                            />
                            <span className="mx-2">{elem.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )} */}

          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 256,
              width: "100%",
              zIndex: 100,
              backgroundColor: "#fff",
              padding: "10px",
              borderTop: "1px solid #d3d3d3",
              boxShadow: "0px 0px 10px 0px #d3d3d3",
            }}
          >
            {/* old schedule btn */}
            {/* <div className="flex justify-start items-center mr-10 ">
              <div className="col-sm-7 flex  justify-content-end">
                <div className=" ">
                  <button className="draft-btn-enable">Schedule Draft</button>
                </div>

                <div className=" d-flex justify-content-end">
                  {PublishType === "STORIES" ? (
                    <button
                      className={
                        postBtnEnable === true ? "draft-btn" : "draft-btn-enable"
                      }
                      onClick={() => {
                        Post();
                      }}
                    >
                      {APIwait === false ? (
                        <>
                          {new Date(scheduleDates) > new Date()
                            ? "Schedule Post"
                            : "Post"}

                        </>
                      ) : (
                        <Spin indicator={antIcon} />
                      )}
                    </button>
                  ) : (
                    <button
                      className={
                        postBtnEnable === true && messages === ""
                          ? "draft-btn-enable"
                          : "draft-btn-enable"
                      }
                      disabled={
                        (postBtnEnable === true && messages === "") ||
                          PublishType === "STORIES"
                          ? true
                          : false
                      }
                      onClick={() => {
                        Post();
                      }}
                    >
                      {APIwait === false ? (
                        <>
                          {new Date(scheduleDates) > new Date()
                            ? "Schedule Post"
                            : "Post"}
                        </>
                      ) : (
                        <Spin indicator={antIcon} />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div> */}
            <ScheduleButton
              Post={Post}
              messages={messages}
              APIwait={APIwait}
              scheduleDates={scheduleDates}
            />
          </div>
        </div>

        <div className="col-sm-4 twit-box2">
          <div className="box2-head d-flex flex-column justify-content-between">
            <div className="head-1 mb-2">Network Preview</div>
            {/* deciprion with left side info icon */}
            <div className="d-flex align-items-center">
              <div className="info_icon">
                <i class="fa fa-info-circle"></i>
              </div>
              <div className="head-2 pl-2">
                Preview approximates how your content will display when
                published. Tests and updates by social networks may affect the
                final appearance.
              </div>
            </div>
            <div className="mt-3">
              {/* <div className='mx-3'>
                                {
                                    Fileupload && PublishType === "IMAGE"
                                        ?
                                        <img src={PhotoPreview} style={{ width: "100%", borderRadius: '10px' }} />
                                        :
                                        PublishType === "REELS"
                                            ?
                                            <video src={sessionStorage.getItem("uploadImage")}>
                                            </video>
                                            :
                                            null
                                }
                            </div> */}

              {SelectedPlatform !== null ? (
                <div className="d-flex align-items-center flex-column">
                  {SelectedPlatform.find((o) => o.platform === "FACEBOOK") ? (
                    <FacebookModal
                      Previewimages={PhotoPreview}
                      PostType={fileType}
                      Previewcaptions={messages}
                    />
                  ) : null}

                  {SelectedPlatform.find((o) => o.platform === "INSTAGRAM") ? (
                    <InstagarmModal
                      Previewimages={PhotoPreview}
                      PostType={fileType}
                      Previewcaptions={messages}
                    />
                  ) : null}

                  {SelectedPlatform.find((o) => o.platform === "PINTEREST") ? (
                    <Pinerest
                      Previewimages={PhotoPreview}
                      PostType={fileType}
                      Previewcaptions={messages}
                    />
                  ) : null}
                  {/* {
                                            Paltform === "Youtube"
                                                ?
                                                <YoutubeModal Previewimages={PhotoPreview} PostType={fileType} Previewcaptions={messages} title={title} />
                                                :
                                                null
                                        } */}
                </div>
              ) : null}
            </div>
          </div>

          <div>
            {/* <img src={require('../Assets/Twitter-box2.png')} className='Twit-box2Img' /> */}
          </div>
        </div>
      </div>

      {/* Create Board Modal */}
      <Modal
        open={openCreateboard}
        footer={null}
        onCancel={() => setopenCreateboard(false)}
        title="Creat New Board"
      >
        <div>
          <div>
            <table className="table">
              <tr>
                <td>
                  <label>Name of Board</label>
                </td>

                <td>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNewBoardName(e.target.value);
                    }}
                    className="pint-input"
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label>Descriptions (Optional)</label>
                </td>

                <td>
                  <textarea
                    className="pint-input"
                    onChange={(e) => {
                      setNewBoardDesc(e.target.value);
                    }}
                  ></textarea>
                </td>
              </tr>
            </table>

            <div>
              <button
                className="create_board_btn"
                onClick={() => {
                  CreateBoard();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const renderPlatformIcon = (platform) => {
  const iconStyles = {
    FACEBOOK: { className: "fa-brands fa-facebook", color: "#1877F2" },
    INSTAGRAM: { className: "fa-brands fa-instagram", color: "#FD1D1D" },
    PINTEREST: { className: "fa-brands fa-pinterest", color: "#E60023" },
  };
  return iconStyles[platform] ? (
    <i
      className={iconStyles[platform].className}
      style={{ color: iconStyles[platform].color }}
    ></i>
  ) : null;
};

const renderProfileName = (elem) => {
  const truncateText = (text, maxLength = 10) =>
    text?.length > maxLength ? text?.slice(0, maxLength) + "..." : text;

  switch (elem.platform) {
    case "FACEBOOK":
      return <span>{truncateText(elem.profile)}</span>;
    case "INSTAGRAM":
      return <span>{truncateText(<UserName Id={elem.platform_id} />)}</span>;
    case "PINTEREST":
      return <span>{truncateText(<PinName Id={elem.platform_id} />)}</span>;
    default:
      return null;
  }
};

const ScheduleButton = ({ Post, messages, APIwait }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSchedule = (type) => {
    setIsOpen(false); // Close the dropdown
    Post(type); // Call the API with selected schedule type
  };

  console.log("messages", messages);

  return (
    <div className="relative inline-block">
      {/* Schedule Button */}
      <button
        className={`bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center transition ${!messages ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
          }`}
        onClick={() => setIsOpen(!isOpen)}
        disabled={!messages}
      >
        {APIwait ? <Spin size="small" className="mr-2" /> : null}
        {APIwait ? "Posting..." : "Schedule Post"}
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute left-0 mt-2 bottom-[40px] w-64 bg-white shadow-lg rounded-lg border z-50">
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleSchedule("schedule")}
          >
            Schedule
            <span className="block text-gray-500 text-sm">
              Close Compose after sending
            </span>
          </button>
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleSchedule("scheduleDuplicate")}
          >
            Schedule + Duplicate
            <span className="block text-gray-500 text-sm">
              Re-open to edit for other networks
            </span>
          </button>
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleSchedule("scheduleNew")}
          >
            Schedule + New
            <span className="block text-gray-500 text-sm">
              Re-open blank Compose window
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
