import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticatePinterest } from "../../services/group";
import { createGroup } from "../../store/Slices/GroupSlice";
import { useNavigate } from "react-router-dom";

export default function Pinterest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedGroup } = useSelector((state) => state.groups);
  const addToGroup = async (pageID) => {
    dispatch(await createGroup({
      groupName: selectedGroup,
      connectedAccounts: [
        { platform: "PINTEREST", platform_id: pageID, profile: "" },
      ],
    }))

    window.close();
  };

  useEffect(() => {
    const getQueryParam = (name) => new URLSearchParams(window.location.search).get(name);

    const handlePinterestAuth = async () => {
      const code = getQueryParam("code");
      if (code) {
        const userId = await authenticatePinterest(code);
        if (userId) {
          addToGroup(userId);
        }
      }
    };

    handlePinterestAuth();
   
  }, []);

  return <div>Redirecting.......</div>;
}
