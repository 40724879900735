
import axiosServices from "./axios"

export const facebookCallback = async (code) => {
    try {
      const response = await axiosServices.get("/group/get", {
        params: {
          code,
        },
      });
      return response;
    } catch (error) {
      console.error("Error in facebookCallback:", error);
      throw error;
    }
  };
  
 
  export const getEngagementData = async (filter, sinceSelector, untilSelector,groupName) => {
    try {
    
  
      // Extracting profiles
      const selectedProfiles = filter[0].map((elem) => elem.platform_id);
  
      // Convert timestamps to date strings
      const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      };
  
      const since = formatDate(sinceSelector[0]);
      const until = formatDate(untilSelector[0]);
  
      const response = await axiosServices.post(
        `/group/group-analytics`,
        {
          groupName: groupName,
          selectedProfiles,
          since,
          until,
        },
      
      );
  
      return response.data.analytics;
    } catch (error) {
      console.error("Error fetching engagement data:", error);
      throw error;
    }
  };

export const fetchInstagramProfile = async (insta_id) => {
  try {
    const response = await axiosServices.post('/instagram/profile', { insta_id });
    return response.data;
  } catch (error) {
    console.error('Error fetching Instagram profile:', error);
    throw error;
  }
};
export const fetchInstagramEngagment = async (data, type) => {
  try {
    const response = await axiosServices.post(`/${type}/get-profile-engagement`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Instagram profile:', error);
    throw error;
  }
};

// group/get-draft
export const fetchDrafts = async (groupName) => {
  try {
    const response = await axiosServices.get('/group/get-draft/'+groupName);
    return response.data;
  } catch (error) {
    console.error('Error fetching Instagram profile:', error);
    throw error;
  }
};


export const authenticatePinterest = async (code) => {
  try {
   
    const response = await axiosServices.get(`/pinterest/auth/callback`, {
      params: { code, state: "state" },
    
    });
    return response.data.user_id;
  } catch (error) {
    console.error("Pinterest authentication failed:", error);
    return null;
  }
};
