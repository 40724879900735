import axiosServices from "./axios";

/**
 * Adds a competitor (general endpoint).
 * @param {Object} competitorData - Competitor details.
 * @returns {Promise<Object>} - Response from the backend API.
 */
export const addCompetitor = async (competitorData) => {
  try {
    const response = await axiosServices.post("/competitor/add", competitorData);
    return response;
  } catch (error) {
    console.error("❌ Error in addCompetitor:", error);
    throw error;
  }
};

/**
 * Adds a competitor by Instagram username (Instant Add).
 * @param {string} username - Instagram username of the competitor.
 * @returns {Promise<Object>} - Response from the backend API.
 */
export const addInstagramCompetitor = async (username) => {
  try {
    const response = await axiosServices.post("/competitor/instant-add-insta", {
      username,
    });
    return response;
  } catch (error) {
    console.error("❌ Error in addInstagramCompetitor:", error);
    throw error;
  }
};

/**
 * Retrieves all competitor profiles.
 * @returns {Promise<Object>} - Response containing competitor profiles.
 */
export const getCompetitorProfiles = async (data) => {
  try {
    const response = await axiosServices.post("/competitor/get-range-profile",data);
    return response.data;
  } catch (error) {
    console.error("❌ Error in getCompetitorProfiles:", error);
    throw error;
  }
};

/**
 * Retrieves competitor profiles by date range.
 * @param {Object} dateRange - Date range object { startDate, endDate }.
 * @returns {Promise<Object>} - Response containing filtered competitor profiles.
 */
export const getCompetitorsByDateRange = async (dateRange) => {
  try {
    const response = await axiosServices.post(
      "/competitor/get-range-profile",
      dateRange
    );
    return response;
  } catch (error) {
    console.error("❌ Error in getCompetitorsByDateRange:", error);
    throw error;
  }
};
