import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import CountryDropdown from "../Comman Components/CountryDropdown";
import { country } from "../Comman Components/Country";
import { TimeZone } from "../Comman Components/TimeZone";
import TimezoneDropdown from "../Comman Components/TimezoneDropdown";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify"; // Import Toast notifications
import { createGroup } from "../services/user.profile";
import { setAns1, setAns2, setAns3 } from '../store/Slices/OnboardingSlice';
import { message } from "antd";

export default function Organization({ dataOrganization, setMobile, Mobile, setOrganizationData, setCurrentStep, answers }) {

  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );


  function handelOrganization(e) {
    const newdata = { ...dataOrganization };
    newdata[e.target.name] = e.target.value;
    setOrganizationData(newdata);
  }

  // Validation function
  const validateForm = () => {
    if (
      !dataOrganization.Brandname ||
      !dataOrganization.Brandaddress ||
      !dataOrganization.Brandsize ||
      !dataOrganization.Brandwebsite ||
      !Mobile ||
      !selector.Country[0] ||
      !selector.Timezone[0]
    ) {
      toast.error("All fields are required!"); // Error toast for missing fields
      return false;
    }

    if (isNaN(dataOrganization.Brandsize) || dataOrganization.Brandsize <= 0) {
      toast.error("Brand Size must be a positive number!"); // Error toast for invalid brand size
      return false;
    }

    if (!/^\+?\d{10,15}$/.test(Mobile)) {
      toast.error("Please enter a valid phone number!"); // Error toast for invalid phone number
      return false;
    }

    return true; // Form is valid
  };
  const submitHandler = async() => {
    if (!validateForm()) return;

    // Store data in Redux before creating group
    if (answers) {
      dispatch(setAns1(answers.Ans1));
      dispatch(setAns2(answers.Ans2));
      dispatch(setAns3(answers.Ans3));
    } else {
      console.error('Answers object is undefined');
      message.error('Missing required answers');
      return;
    }

   await CreateFirstGroup();
    setCurrentStep(6);
  }


  const CreateFirstGroup = async () => {
    try {
      const response = await createGroup({
        groupName: dataOrganization.Brandname,
        connectedAccounts: []
      });
   
    } catch (error) {
      console.error("Error during onboarding:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center  ">
      <div className="w-full   rounded-lg ">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">Your Brand</h2>
          <p className="text-gray-600">
            Choose how your business presents itself.
          </p>
        </div>

        <form onSubmit={submitHandler}>
          {" "}
          {/* Added onSubmit to the form */}
          <div className="space-y-4">
            <div>
              <label htmlFor="brandname" className="block text-gray-700">
                Brand Name
              </label>
              <input
                id="brandname"
                type="text"
                name="Brandname"
                value={dataOrganization.Brandname}
                onChange={handelOrganization}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="brandsize" className="block text-gray-700">
                Brand Size
              </label>
              <input
                id="brandsize"
                type="number"
                name="Brandsize"
                value={dataOrganization.Brandsize}
                onChange={handelOrganization}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="mobile" className="block text-gray-700">
                Phone Number
              </label>
              <PhoneInput
                id="mobile"
                defaultCountry="IN"
                value={Mobile}
                onChange={setMobile}
                limitMaxLength={true}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label htmlFor="brandaddress" className="block text-gray-700">
                Address
              </label>
              <input
                id="brandaddress"
                type="text"
                name="Brandaddress"
                value={dataOrganization.Brandaddress}
                onChange={handelOrganization}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="brandwebsite" className="block text-gray-700">
                Website
              </label>
              <input
                id="brandwebsite"
                type="text"
                name="Brandwebsite"
                value={dataOrganization.Brandwebsite}
                onChange={handelOrganization}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="country" className="block text-gray-700">
                Country
              </label>
              <CountryDropdown
                id="country"
                countryList={country}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label htmlFor="timezone" className="block text-gray-700">
                Time Zone
              </label>
              <TimezoneDropdown
                id="timezone"
                Timezonelist={TimeZone}
                className="w-full py-2 px-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 "
              />
            </div>

            <div className="flex justify-between items-center pt-12">
              <button
                type="button"
                onClick={() => setCurrentStep(3)}
                className="px-6 py-2 bg-gray-300 text-gray-700 rounded-lg"
              >
                Back
              </button>
              <button
                type="button"
                onClick={submitHandler}
                className={`px-6 py-2 rounded-lg ${isLoader ? "bg-gray-300" : "bg-indigo-600"
                  }`}
                disabled={isLoader} // Disable button during loading
              >
                {isLoader ? (
                  <>
                    <Spin indicator={antIcon} />
                    <span className="ml-2">Please wait...</span>{" "}
                    {/* Optional text */}
                  </>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
