// src/components/Pagination.js
import React from 'react';

const Pagination = ({ CurrentPage, setCurrentPage, TotalPages }) => {
  const pages = [];
  const maxVisiblePages = 3; // Number of page numbers to show

  // Add Previous button
  pages.push(
    <span
      key="prev"
      className={`mx-1 px-3 py-2 cursor-pointer border rounded-md ${
        CurrentPage === 1
          ? "bg-gray-100 text-gray-400"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
      onClick={() => CurrentPage > 1 && setCurrentPage(CurrentPage - 1)}
    >
      Prev
    </span>
  );

  // Calculate range of pages to show
  let startPage = Math.max(1, CurrentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(TotalPages, startPage + maxVisiblePages - 1);

  // Adjust start if we're near the end
  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  // Add first page and ellipsis if needed
  if (startPage > 1) {
    pages.push(
      <span
        key={1}
        className="mx-1 px-3 py-2 cursor-pointer border rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
        onClick={() => setCurrentPage(1)}
      >
        1
      </span>
    );
    if (startPage > 2) {
      pages.push(
        <span key="ellipsis1" className="mx-1">
          ...
        </span>
      );
    }
  }

  // Add page numbers
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <span
        key={i}
        className={`mx-1 px-3 py-2 cursor-pointer border rounded-md ${
          CurrentPage === i
            ? "bg-blue-600 text-white font-bold"
            : "bg-gray-200 text-gray-700 hover:bg-gray-300"
        }`}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </span>
    );
  }

  // Add last page and ellipsis if needed
  if (endPage < TotalPages) {
    if (endPage < TotalPages - 1) {
      pages.push(
        <span key="ellipsis2" className="mx-1">
          ...
        </span>
      );
    }
    pages.push(
      <span
        key={TotalPages}
        className="mx-1 px-3 py-2 cursor-pointer border rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
        onClick={() => setCurrentPage(TotalPages)}
      >
        {TotalPages}
      </span>
    );
  }

  // Add Next button
  pages.push(
    <span
      key="next"
      className={`mx-1 px-3 py-2 cursor-pointer border rounded-md ${
        CurrentPage === TotalPages
          ? "bg-gray-100 text-gray-400"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
      onClick={() =>
        CurrentPage < TotalPages && setCurrentPage(CurrentPage + 1)
      }
    >
      Next
    </span>
  );

  return <div className="flex space-x-2">{pages}</div>;
};

export default Pagination;
