import React from 'react'

export default function DashScreen() {
    return (
        <div className='Dash_section'>
            <div className='Dash_Notification'>
                <h1>Notifications.</h1>
                <label>Here are the tools you need to know about.</label>
            </div>

            <div className='d-flex boxsection'>
                <div className='dash_box1'>
                    <img src={require('../Assets/automation.png')} className='dash_notifi_box' />

                    <div className='box1_title'>
                        <label>Automation</label>
                        <p>Automate your scheduling,
                            copywriting, designing.</p>
                    </div>
                </div>

                <div className='dash_box1'>
                    <img src={require('../Assets/smart_inbox.png')} className='dash_notifi_box' />

                    <div className='box1_title'>
                        <label>Smart Inbox</label>
                        <p>Track incoming message
                            volume and see how quickly
                            you’re taking action.</p>
                    </div>
                </div>

                <div className='dash_box1'>
                    <img src={require('../Assets/performance.png')} className='dash_notifi_box' />

                    <div className='box1_title'>
                        <label>Reports</label>
                        <p>See how all your profiles are
                            performing in one place.</p>
                    </div>
                </div>

                <div className='dash_box1'>
                    <img src={require('../Assets/scheduling.png')} className='dash_notifi_box' />

                    <div className='box1_title'>
                        <label>Scheduling</label>
                        <p>Schedule post to your needs on all
                            platforms at once.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
