import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Countrychange } from '../store/Slices/CountrySlice';

export default function CountryDropdown({ countryList }) {
    const [listShow, setListShow] = useState(false);
    const [ListData, setListData] = useState(countryList);
    const [search, setSearch] = useState('');
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearch(query);

        // Filter country list as user types in the search box
        const filtered = countryList.filter((country) =>
            country.toLowerCase().includes(query)
        );
        setListData(filtered);
    };

    const handleSearchKeyDown = (key) => {
        if (key.key === "Enter") {
            setListShow(true);
        }
    };

    const handleDropdownToggle = (e) => {
        e.preventDefault(); // Prevent form submission or page reload
        setListShow(!listShow);
    };

    return (
        <div className="relative w-full">
            <button
                onClick={handleDropdownToggle}  // Use custom handler here
                className="w-full py-3 px-4 flex justify-between items-center bg-white border rounded-lg shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
            >
                <div>{selector.Country[0] || 'Select Country'}</div>
                <div className="text-gray-600">
                    <i className="fa fa-angle-down fa-xs"></i>
                </div>
            </button>

            {listShow && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
                    <input
                        type="text"
                        value={search}
                        onKeyDown={handleSearchKeyDown}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        className="w-full py-2 px-4 border-b-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <ul className="max-h-40 overflow-y-auto">
                        {ListData.length > 0 ? (
                            ListData.map((elem, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => {
                                            setSearch(''); // Clear search on selection
                                            setListShow(false);
                                            setListData(countryList); // Reset country list to full list
                                            dispatch(Countrychange(elem));
                                        }}
                                        className="w-full py-2 px-4 text-left hover:bg-indigo-600 hover:text-white focus:outline-none transition duration-150 ease-in-out"
                                    >
                                        {elem}
                                    </button>
                                </li>
                            ))
                        ) : (
                            <li>
                                <span className="w-full py-2 px-4 text-gray-500">No countries found</span>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}
