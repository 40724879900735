import React, { useEffect, useState } from 'react';
import { Modal, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
    getPagesDetails,
    getProfile,
    saveProfiles,
    savePageDetails,
    facebookCallback,
} from '../../services/user.profile';

export default function Facebook() {
    const navigate = useNavigate();
    const [ShowModal, setShowModal] = useState(false);
    const [PagesList, setPagesList] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [SelectedPage] = useState([]);
    const [pageDetails] = useState([]);
    const [facebookACId, setFacebookACId] = useState();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const handleGetPagesDetails = async (accessToken) => {
        try {
            const res = await getPagesDetails(accessToken);
            setPagesList(res.data.data);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching pages details:', error);
            message.error('Failed to fetch pages details.');
        }
    };

    const handleGetProfile = async () => {
        try {
            const res = await getProfile();
            const profiles = res.data.profiles;
            if (profiles.length > 0) {
                setFacebookACId(profiles[profiles.length - 1].user_id);
            } else {
                message.warning('No profiles found.');
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
            message.error('Failed to fetch profile.');
        }
    };

    const handleFacebookCallback = async () => {
        const getQueryParam = (name) => {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        };

        const code = getQueryParam('code');

        if (code) {
            try {
                const res = await facebookCallback(code);
                if(res?.data?.access_token || res?.data?.accessToken){

                    const accessToken = res.data.access_token|| res?.data?.accessToken;
                    await handleGetPagesDetails(accessToken);
                    await handleGetProfile();
                }
            } catch (error) {
                if (error.response?.data?.accessToken) {
                    const fallbackAccessToken = error?.response?.data?.accessToken;
                    await handleGetPagesDetails(fallbackAccessToken);
                    await handleGetProfile();
                } else {
                    console.error('Error during Facebook callback:', error);
                    message.error('Failed to authenticate with Facebook.');
                }
            }
        }else{
            navigate('/dashboard');
        }
    };

    const handleSaveProfiles = async () => {
        setIsLoader(true);
        try {
            const groupName = localStorage.getItem('selected_group');
            if (!groupName) {
                message.error('Select a group first.');
                setIsLoader(false);
                return;
            }

            await saveProfiles(groupName, SelectedPage);
            await handleSavePageDetails();
        } catch (error) {
            console.error('Error saving profiles:', error);
            message.error('Failed to save profiles.');
            setIsLoader(false);
        }
    };

    const handleSavePageDetails = async () => {
        try {
            await savePageDetails(facebookACId, pageDetails);
            navigate('/Dashboard/GroupsProfile');
            setIsLoader(false);
        } catch (error) {
            console.error('Error saving page details:', error);
            message.error('Failed to save page details.');
            setIsLoader(false);
        }
    };

    const handleCancelModal = () => {
        navigate('/Dashboard/GroupsProfile');
    };

    const handlePageSelection = (value, check) => {
        if (check.target.checked) {
            pageDetails.push({
                pageId: value.id,
                accessToken: value.access_token,
            });
        } else {
            const index = pageDetails.findIndex((item) => item.pageId === value.id);
            if (index > -1) {
                pageDetails.splice(index, 1);
            }
        }
    };

    const handleSelectPages = (value, check) => {
        if (check.target.checked) {
            SelectedPage.push({
                platform: 'FACEBOOK',
                platform_pageId: value.id,
                platform_id: facebookACId,
                profile: value.name,
            });
        } else {
            const index = SelectedPage.findIndex((item) => item.platform_pageId === value.id);
            if (index > -1) {
                SelectedPage.splice(index, 1);
            }
        }
    };

    useEffect(() => {
        handleFacebookCallback();
    }, []);

    return (
        <Modal title="Connect a Profile" open={ShowModal} footer={null} onCancel={handleCancelModal}>
            <div>
                <div className="select_page_head">
                    <h4>Select pages to add to your account.</h4>
                </div>

                <hr />

                <div className="pages_list_section">
                    {PagesList
                        ? PagesList.map((page) => (
                              <div className="d-flex align-items-center justify-content-between my-4" key={page.id}>
                                  <div className="d-flex align-items-center justify-content-between">
                                      <img
                                          src={page.picture.data.url || require('../../Assets/Insta_logo.png')}
                                          style={{ width: '20%' }}
                                          className="profile_img"
                                          alt={page.name}
                                      />

                                      <div>
                                          <i className="fa-brands fa-facebook fa-md mx-2" style={{ color: '#3b5998' }}></i>
                                      </div>

                                      <span className="mx-1">{page.name}</span>
                                  </div>

                                  <div>
                                      <input
                                          type="checkbox"
                                          onChange={(e) => {
                                              handleSelectPages(page, e);
                                              handlePageSelection(page, e);
                                          }}
                                      />
                                  </div>
                              </div>
                          ))
                        : null}
                </div>

                <div>
                    <button className="Next_page" onClick={handleSaveProfiles}>
                        {isLoader ? <Spin indicator={antIcon} /> : 'Next'}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
