import React, { useEffect, useState } from 'react';
import { fetchInstagramProfile } from '../services/group';

export default function UserName({ Id }) {
  const [name, setName] = useState('');

  useEffect(() => {
    const getInstagramDetails = async () => {
      try {
        const data = await fetchInstagramProfile(Id);
        console.log(data);
        setName(data.username);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };
    getInstagramDetails();
  }, [Id]);

  return (
    <>
      <span>{name} </span>
    </>
  );
}