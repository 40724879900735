import React from "react";
import { useEffect, useState } from "react";
import { Modal, message } from "antd";
import axios from "axios";

import {
  Linkedin,
  LoginFb,
  LoginYT,
  LoginPintrest,
  InstagramLogin,
} from "./Comman Methods/Connectprofile";
import Cookies from "js-cookie";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Images from "../Comman Components/Images";
import PinImage from "../Comman Components/Pinterest Componets/PinImage";
import { fetchUserProfiles } from "../services/user.profile";
import {
  createGroup,
  deleteGroupDetails,
  fetchGroupDetails,
  fetchGroups,
  selectGroupAndFetchDetails,
  setProfiles,

} from "../store/Slices/GroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function GroupsProfile() {
  const dispatch = useDispatch();
  const { groups, selectedGroup, profile } = useSelector(
    (state) => state.groups
  );




  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupData, setGroupData] = useState(false);
  const [singleProfile, setSingleProfile] = useState([]);
  const [platform, setPlatform] = useState();
  const [isModalNew, setIsModalNew] = useState(false);
  const [GroupLinks, setGroupLink] = useState([]);
  const [groupName, setGroupName] = useState();
  const [Groups, setGroups] = useState(null);
  // const [profile, setProfile] = useState();

  // const [SelectedGroup, setSelectedGroup] = useState();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#0B968F" }} spin />
  );

  const GetProfile = async () => {
    const profiles = await fetchUserProfiles();
    if (profiles.length !== 0) {
      // Remove duplicates by filtering for unique user_id values.
      const uniqueProfiles = profiles.filter((profile, index, self) =>
        index === self.findIndex(p => p.user_id === profile.user_id)
      );
      dispatch(setProfiles(uniqueProfiles));
    }
  };



  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalNew = () => {
    setIsModalNew(true);
  };

  const DefaultAccount = async (accountData) => {
    console.log(accountData);
    if (accountData.platform === "FACEBOOK") {
      Cookies.set(
        "Oolook_default",
        JSON.stringify(accountData.platform_pageId)
      );
    } else if (accountData.platform === "INSTAGRAM") {
      Cookies.set(
        "Oolook_default_Insta",
        JSON.stringify(accountData.platform_id)
      );
    }
  };

  const CheckDefault = (checkData) => {
    if (platform === "FACEBOOK") {
      if (Cookies.get("Oolook_default") !== undefined) {
        return JSON.parse(Cookies.get("Oolook_default")) === checkData.id;
      }
    } else if (platform === "INSTAGRAM") {
      if (Cookies.get("Oolook_default_Insta") !== undefined) {
        return JSON.parse(Cookies.get("Oolook_default_Insta")) === checkData.id;
      }
    }
  };

  const CreateGroup = async () => {

    // using react toast 
    if (!groupName) {
      toast.error('Please provide a group name');
      return;
    }

    try {
      await dispatch(createGroup({ groupName, connectedAccounts: [] }));
      toast.success('Group created successfully!');
      dispatch(fetchGroups()); // Refresh the groups list after creation
      // close the modal
      setIsModalNew(false);

    } catch (err) {
      console.error("Error creating group:", err);
      toast.error('Failed to create group. Please try again.');
    }
  };

  const GetGroups = () => {
    dispatch(fetchGroups());

    dispatch(selectGroupAndFetchDetails(groups[0].name));
    dispatch(fetchGroupDetails(groups[0].name));

    // setSelectedGroup()
  };

  const ConnectPlatformView = () => {
    return (
      <>
        <div className="grp-modal-head mt-3">
          Select a network to connect a profile
        </div>
        <span>
          If you have several accounts to connect, don't worry we'll bring you
          back to this screen after each profile is set up.
        </span>

        <div className="d-flex flex-column w-50 mt-5">
          <label className="Group_label">In Group</label>

          <select
            className="Group_dropdown"
            value={selectedGroup}
            onChange={(e) => {
              localStorage.setItem("selected_group", e.target.value);
              dispatch(selectGroupAndFetchDetails(e.target.value));
            }}
          >
            {groups?.map((elem) => {
              return <option value={elem.name}>{elem.name}</option>;
            })}
          </select>
        </div>

        <div className="modalgrp-div">
          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/Facebook.png")}
                className="modal-img"
              />
              <div className="modal-text">Facebook</div>
            </div>

            <div className="modal-connect">
              <button
                className="connect-btn"
                onClick={() => {
                  LoginFb();
                }}
              >
                Connect
              </button>
            </div>
          </div>

          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/Instagram.png")}
                className="modal-img"
              />
              <div className="modal-text">Instagram</div>
            </div>

            <div className="modal-connect">
              <button
                className="connect-btn"
                onClick={() => {
                  InstagramLogin();
                }}
              >
                Connect
              </button>
            </div>
          </div>

          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/Pintrest.png")}
                className="modal-img"
              />
              <div className="modal-text">Pinterest</div>
            </div>

            <div className="modal-connect">
              <button
                className="connect-btn"
                onClick={() => {
                  LoginPintrest();
                }}
              >
                Connect
              </button>
            </div>
          </div>

          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/GB.png")}
                className="modal-img"
              />
              <div className="modal-text">Google My Business</div>
            </div>

            <div className="modal-connect">
              <button className="connect-btn">Connect</button>
            </div>
          </div>
        </div>

        <div className="modalgrp-div2 d-flex justify-content-center">
          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/Twitter.png")}
                className="modal-img"
              />
              <div className="modal-text">X</div>
            </div>

            <div className="modal-connect">
              <button className="connect-btn">Connect</button>
            </div>
          </div>

          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/LinkedIn.png")}
                className="modal-img"
              />
              <div className="modal-text">Linkdin</div>
            </div>

            <div className="modal-connect">
              <button className="connect-btn" onClick={() => Linkedin()}>
                Connect
              </button>
            </div>
          </div>

          <div className="modal-card d-flex flex-column justify-content-around">
            <div className="card-img-div">
              <img
                src={require("../Assets/Icons/Youtube.png")}
                className="modal-img"
              />
              <div className="modal-text">YouTube</div>
            </div>

            <div className="modal-connect">
              <button
                className="connect-btn"
                onClick={() => {
                  LoginYT();
                }}
              >
                Connect
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const DeleteSingleProfile = (pagedetails) => {
    const confirmDelete = async () => {
      try {
        await dispatch(deleteGroupDetails(pagedetails.platform_id));
        await dispatch(fetchGroupDetails(selectedGroup));
        toast.success('Profile deleted successfully');
      } catch (error) {
        console.error('Error deleting profile:', error);
        toast.error('Failed to delete profile. Please try again.');
      }
    };

    Modal.confirm({
      title: 'Delete Profile',
      content: `Are you sure you want to delete this ${pagedetails.platform} profile?`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        confirmDelete();
      },
    });
  };

  useEffect(() => {
    GetProfile();
    GetGroups();
  }, [0]);

  return (
    <>
      {/* Connect Profile Modal */}
      <>
        <Modal
          title="Connect a Profile"
          open={isModalOpen}
          footer={null}
          onCancel={() => {
            setIsModalOpen(false);
            GetProfile();
          }}
        >
          <ConnectPlatformView />
        </Modal>
      </>

      {/* second modal  */}

      <>
        <Modal
          open={isModalNew}
          footer={null}
          onCancel={() => {
            setIsModalNew(false);
          }}
        >
          <div className="invite-head">Create New Group</div>
          <hr />

          <div>
            Groups help you organize profiles and team members for your company.
            You can create a group with a new or existing profile.
          </div>

          <div>
            <input
              className="grp-input"
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
              placeholder="Name of Group"
            />
          </div>

          <div className="d-flex w-100">
            <span className="w-100 tab_btn active_tab">From Existing</span>
          </div>

          <div className="exist-div1">
            <div className="exist-div2">
              {profile !== null
                ? profile?.map((elem) => {
                  return (
                    <div className="d-flex">
                      <div>
                        <img
                          className="exist-pic"
                          src={require("../Assets/test_brand.png")}
                        />
                      </div>

                      <button
                        className="mx-2 btn"
                        onClick={() => {
                          GroupLinks.push([
                            {
                              platform: elem.platform,
                              platform_id: elem.user_id,
                            },
                          ]);
                        }}
                      >
                        <div className="exist-text">@username</div>
                        <div>{elem.platform} Profile</div>
                      </button>
                    </div>
                  );
                })
                : "Loading...."}
            </div>
          </div>

          <div className="from-div">
            <button
              className="from-btn"
              onClick={() => {
                CreateGroup();
              }}
            >
              Proceed
            </button>
          </div>
        </Modal>
      </>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="grp-head ">Business Profile & Social Profiles</div>
          </div>

          <div className="d-flex align-items-center">
            <div className="flex align-items-center grphead-btn gap-2 mx-2">
              <div>
                <img
                  src={require("../Assets/Icons/bookmark.png")}
                  className="grp-vector"
                />
              </div>

              <div>
                <button className="grp-btn" onClick={showModalNew}>
                  Create a Business Profile
                </button>
              </div>
            </div>
            <div className="flex align-items-center grphead-btn gap-2 mx-2">
              <div>
                <img
                  src={require("../Assets/Icons/plus.png")}
                  className="grp-vector"
                />
              </div>
              <div>
                <button className="grp-btn" onClick={showModal}>
                  connect a profile
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="grp-box1">
          <div className="grp-box1-text1">Organisation Name</div>
          <div className="grp-box1-text2">
            Add, remove and manage your Business profile and social profiles
          </div>
          <hr />
          <div className="grp-box1-text1">Business Profile</div>
          <div className="d-flex mt-4 mb-2 cursor-pointer ">
            {groups.length > 0 || groups != null ? (
              groups.map((elem) => {
                return (
                  <div
                    className={
                      localStorage.getItem("oolook_default_group") === elem.name
                        ? "Group_folder_active"
                        : "Group_folder"
                    }
                    onClick={() => {
                      dispatch(fetchGroupDetails(elem.name));
                      dispatch(selectGroupAndFetchDetails(elem.name));
                    }}
                  >
                    {elem.name}
                  </div>
                );
              })
            ) : (
              <Spin indicator={antIcon} />
            )}
          </div>
          <hr />

          {/* <div className='member_count'>2 Team members in <span style={{ color: '#4D94CF' }}>Group Name</span></div>

                    <div>
                        <img src={ProfileImg} className='grp-social' />
                    </div> */}

          <div className="grp-box1-text1">
            {profile ? profile.length : null} Profile Connected
          </div>

          <div className="profile-text">
            <ul className="profile_list">
              {profile
                ? profile.map((elem) => {
                  return (
                    <>
                      <li>
                        <button className="d-flex align-items-center justify-content-between w-100 profile_list_btn  ">
                          <div className="d-flex align-items-center">
                            {elem.platform === "FACEBOOK" ? (
                              <img
                                src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`}
                                className="profile_img"
                              />
                            ) : elem.platform === "INSTAGRAM" ? (
                              <Images Id={elem.platform_id} />
                            ) : (
                              <PinImage Id={elem.platform_id} />
                            )}
                            {elem.platform === "FACEBOOK" ? (
                              <i
                                className="fa-brands fa-facebook fa-md mx-2"
                                style={{ color: "#3b5998" }}
                              ></i>
                            ) : elem.platform === "LINKEDIN" ? (
                              <i
                                className="fa-brands fa-linkedin fa-md mx-2"
                                style={{ color: "#0A66C2" }}
                              ></i>
                            ) : elem.platform === "YOUTUBE" ? (
                              <i
                                className="fa-brands fa-youtube fa-md mx-2"
                                style={{ color: "#FF0000" }}
                              ></i>
                            ) : elem.platform === "INSTAGRAM" ? (
                              <i
                                className="fa-brands fa-instagram fa-md mx-2"
                                style={{ color: "#E4405F" }}
                              ></i>
                            ) : elem.platform === "TWITTER" ? (
                              <i
                                className="fa-brands fa-twitter fa-md mx-2"
                                style={{ color: "#1DA1F2" }}
                              ></i>
                            ) : (
                              <i
                                className="fa-brands fa-pinterest fa-md mx-2"
                                style={{ color: "#E60023" }}
                              ></i>
                            )}
                            <span className="profile_name">
                              1 Page Connected
                            </span>
                          </div>

                          <div>
                            <button className="mx-1 profile_action_btn">
                              <i class="fa fa-refresh"></i>
                            </button>

                            <button
                              className="mx-1 profile_action_btn"
                              onClick={() => {
                                DeleteSingleProfile(elem);
                              }}
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </button>
                      </li>
                    </>
                  );
                })
                : "All Available Profiles Connected"}
            </ul>
          </div>
          <div className="profile-text">
            <button
              className="profile-btn"
              onClick={() => {
                showModal();
              }}
            >
              Connect a Profile
            </button>
          </div>
        </div>
      </div>

      {/* Particular profile modal */}
      <Modal
        open={groupData}
        footer={null}
        onCancel={() => {
          setGroupData(false);
        }}
      >
        <ul className="profile_list">
          {singleProfile.length !== 0
            ? singleProfile.map((elem, index) => {
              return (
                <li key={index}>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      className="mx-2"
                      name="default"
                      onChange={() => {
                        DefaultAccount(elem);
                        CheckDefault(elem.id);
                      }}
                    />
                    <img
                      src={
                        elem.picture.data.url !== undefined
                          ? elem.picture.data.url
                          : require("../Assets/Insta_logo.png")
                      }
                      className="profile_img"
                    />

                    <span className="mx-2">{elem.name}</span>

                    {CheckDefault(elem) ? (
                      <i
                        className="fa fa-check-circle"
                        style={{ color: "#7FFF00" }}
                      />
                    ) : null}
                  </div>
                </li>
              );
            })
            : null}
        </ul>

        <button
          className="save_btn"
          onClick={() => {
            setGroupData(false);
          }}
        >
          Save
        </button>
      </Modal>
    </>
  );
}

// Get Page Details
//  const DataStore = async (data) => {
//     let token;

//     if (localStorage.getItem("user_Token")) {
//         token = localStorage.getItem("user_Token");
//     }
//     else {
//         token = sessionStorage.getItem("user_Token");
//     }

//     if (data.platform === "FACEBOOK") {
//         singleProfile.length = 0;
//         await Promise.all(
//             (data.page_details)?.map(async (elem, index) => {
//                 axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/page`, {
//                     "pageId": elem.pageId
//                 }, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                     }
//                 })
//                     .then((res) => {
//                         singleProfile.push(res.data);
//                         setTimeout(() => {
//                             setGroupData(true);
//                         }, 500)
//                     })
//                     .catch((err) => {
//                         console.log(err)
//                     })
//             })
//         )
//     }
//     else if (data.platform === "INSTAGRAM") {
//         singleProfile.length = 0;
//         await Promise.all(
//             (data.page_details)?.map(async (elem, index) => {
//                 axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/profile`, {
//                     insta_id: elem.accountId,
//                 }, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 })
//                     .then((res) => {
//                         // console.log(res.data.profile_picture_url)
//                         singleProfile.push({
//                             access_token: elem.accessToken,
//                             id: elem.accountId,
//                             name: res.data.name,
//                             picture: {
//                                 data: {
//                                     height: '50',
//                                     url: res.data.profile_picture_url,
//                                     width: '50'
//                                 }
//                             }
//                         })

//                         setTimeout(() => {
//                             setGroupData(true);
//                         }, 500)
//                     })
//                     .catch((err) => {
//                         console.log(err)
//                     })
//             })
//         )

//     }
// }
