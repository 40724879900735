import React, { useState } from 'react';
import { Spin, message } from 'antd';
import Organization from './Organization';
import AssetsData from './AssetsData';
import BuySubscription from './BuySubscription';
import { useSelector } from 'react-redux';
  
export default function Onboarding() {
  const [answers, setAnswers] = useState({
    Ans1: '',
    Ans2: '',
    Ans3: '',
  });
  const [Mobile, setMobile] = useState();
  const [dataOrganization, setOrganizationData] = useState({
    Brandname: "",
    Brandaddress: "",
    Brandsize: "",
    Brandwebsite: "",
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // Function to update answers
  const updateAnswer = (key, value) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
  };

    const ProfileSelector = useSelector((state) => state.Profiles);
    const isActivePlan = ProfileSelector[0]?.is_active_plan;
  
  // Step 1: Buy Subscription UI
// Step 1: Buy Subscription UI
const SubscriptionStep = () => (
  <div className="text-center">
    <h2 className="text-2xl font-semibold mb-4">Subscribe to Continue</h2>
    <p className="mb-6 text-gray-600">Get access to premium features by subscribing</p>
    <BuySubscription 
    setCurrentStep={setCurrentStep}
    />
    <div className="mt-6">
      <button
        className={`px-6 py-2 rounded-lg ${
          isActivePlan ? "bg-blue-500 text-white hover:bg-blue-600" : "bg-gray-400 text-gray-200 cursor-not-allowed"
        }`}
        disabled={!isActivePlan}
        onClick={() => setCurrentStep(2)}
      >
        Next
      </button>
    </div>
  </div>
);


  // Step 2: Select Role
  const Question1 = () => (
    <>
      <div className="text-2xl font-semibold mb-6">What best describes you?</div>
      <div className="grid grid-cols-2 gap-6">
        {['Content Creator or Influencer', 'Marketing Agency', 'SMB Owner', 'Social Media Manager'].map((option) => (
          <button
            key={option}
            className={`p-6 border rounded-lg ${answers.Ans1 === option ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'} hover:bg-blue-200`}
            onClick={() => {
              updateAnswer('Ans1', option);
              setCurrentStep(3);
            }}
          >
            <img src={require(`../Assets/${option.replace(/ /g, '_')}.png`)} className="mx-auto mb-4" alt={option} />
            {option}
          </button>
        ))}
      </div>
    </>
  );

  // Step 3: Select Industry
  const SocialMedia = () => (
    <div className="text-center">
      <div className="text-2xl font-semibold mb-6">Select your Industry</div>
      <div className="grid grid-cols-3 gap-6">
        {['Health & Fitness', 'Beauty & Skincare', 'Food & Beverages'].map((option) => (
          <button
            key={option}
            className={`p-6 border rounded-lg ${answers.Ans2 === option ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'} hover:bg-blue-200`}
            onClick={() => updateAnswer('Ans2', option)}
          >
            <img src={require(`../Assets/${option.replace(/ /g, '_')}.png`)} className="mx-auto mb-4" alt={option} />
            {option}
          </button>
        ))}
      </div>
      <div className="mt-6">
        <label className="block mb-2">Others:</label>
        <input
          type="text"
          className="p-4 border rounded-lg w-full"
          value={answers.Ans2}
          onChange={(e) => updateAnswer('Ans2', e.target.value)}
          placeholder="Type Here"
        />
      </div>
      <div className="flex justify-between mt-6">
        <button
          className="bg-gray-300 text-gray-800 px-6 py-2 rounded-lg hover:bg-gray-400"
          onClick={() => setCurrentStep(2)}
        >
          Back
        </button>
        {answers.Ans2 && (
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
            onClick={() => setCurrentStep(4)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );

  // Step 4: Select Goals
  const Question3 = () => (
    <>
      <div className="text-2xl font-semibold mb-6">Select Your Primary Goals</div>
      <div className="grid grid-cols-2 gap-6">
        {['Brand Awareness', 'Lead Generation', 'Customer Feedback', 'Promote Content'].map((option) => (
          <button
            key={option}
            className={`p-6 border rounded-lg ${answers.Ans3 === option ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'} hover:bg-blue-200`}
            onClick={() => updateAnswer('Ans3', option)}
          >
            <img src={require(`../Assets/${option.replace(/ /g, '_')}.png`)} className="mx-auto mb-4" alt={option} />
            {option}
          </button>
        ))}
      </div>
      <div className="flex justify-between mt-6">
        <button
          className="bg-gray-300 text-gray-800 px-6 py-2 rounded-lg hover:bg-gray-400"
          onClick={() => setCurrentStep(3)}
        >
          Back
        </button>
        {answers.Ans3 && (
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
            onClick={() => setCurrentStep(5)}
          >
            Submit
          </button>
        )}
      </div>
    </>
  );

  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-8 w-[700px] relative">
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
            <Spin size="large" />
          </div>
        )}
        <div className="w-full bg-gray-200 h-2 rounded-lg mb-6">
          <div className="bg-blue-500 h-2 rounded-lg" style={{ width: `${currentStep * 20}%` }}></div>
        </div>

        {currentStep === 1 && SubscriptionStep()}
        {currentStep === 2 && Question1()}
        {currentStep === 3 && SocialMedia()}
        {currentStep === 4 && Question3()}
        {currentStep === 5 && (
          <Organization
            setOrganizationData={setOrganizationData}
            answers={answers}
            Mobile={Mobile}
            dataOrganization={dataOrganization}
            setMobile={setMobile}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 6 && (
          <AssetsData
            dataOrganization={dataOrganization}
            Mobile={Mobile}
            setCurrentStep={setCurrentStep}
          />
        )}
      </div>
    </div>
  );
}
