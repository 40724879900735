import React from 'react'
import { Link } from 'react-router-dom'

export default function Notification() {
    return (
        <div>
            <div className='profile-connect'>
                <h2>Space Notification</h2>

                <div>
                    <button className='btn'>
                        <i class="fa fa-gear fa-xl"></i>
                    </button>

                    <button className='btn'>
                        <img className='settings' src={require('../Assets/Icons/setting.png')} />
                    </button>

                    <button className='btn'>
                        <i class="fa fa-close fa-xl"></i>
                    </button>
                </div>

            </div>

            <hr />

            <div className='msg-action'>
                <Link>Mark all as read</Link>
                |
                <Link>Clear All</Link>
            </div>

            <div>
                <div className='notification-section'>
                    <i class="fa fa-exclamation-circle"></i>


                    <div className='message-box'>
                        <div className='message-section'>
                            <img style={{ width: '40px' }} src={require('../Assets/Icons/notification_img.png')} />
                            Failed post on Facebook for passive.earn
                        </div>

                        <p className='details-message'>
                            Want to live life on your own terms? Build passive income...
                        </p>
                    </div>

                    <label className='notification-time'>5 days ago</label>

                    <i class="fa fa-ellipsis-h fa-lg"></i>
                </div>

                <div className='notification-section'>
                    <i class="fa fa-exclamation-circle"></i>


                    <div className='message-box'>
                        <div className='message-section'>
                            <img style={{ width: '40px' }} src={require('../Assets/Icons/notification_img.png')} />
                            Failed post on Facebook for passive.earn
                        </div>

                        <p className='details-message'>
                            Want to live life on your own terms? Build passive income...
                        </p>
                    </div>

                    <label className='notification-time'>5 days ago</label>

                    <i class="fa fa-ellipsis-h fa-lg"></i>
                </div>

                <div className='notification-section'>
                    <i class="fa fa-exclamation-circle"></i>


                    <div className='message-box'>
                        <div className='message-section'>
                            <img style={{ width: '40px' }} src={require('../Assets/Icons/notification_img.png')} />
                            Failed post on Facebook for passive.earn
                        </div>

                        <p className='details-message'>
                            Want to live life on your own terms? Build passive income...
                        </p>
                    </div>

                    <label className='notification-time'>5 days ago</label>

                    <i class="fa fa-ellipsis-h fa-lg"></i>
                </div>

                <div className='notification-section'>
                    <i class="fa fa-exclamation-circle"></i>


                    <div className='message-box'>
                        <div className='message-section'>
                            <img style={{ width: '40px' }} src={require('../Assets/Icons/notification_img.png')} />
                            Failed post on Facebook for passive.earn
                        </div>

                        <p className='details-message'>
                            Want to live life on your own terms? Build passive income...
                        </p>
                    </div>

                    <label className='notification-time'>5 days ago</label>

                    <i class="fa fa-ellipsis-h fa-lg"></i>
                </div>
            </div>
        </div>
    )
}
