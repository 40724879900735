import Images from "../Comman Components/Images";
import PinImage from "../Comman Components/Pinterest Componets/PinImage";
import PinName from "../Comman Components/Pinterest Componets/PinName";
import UserName from "../Comman Components/UserName";

function ShowProfiles({ avalibaleProfile, CheckPlatform, ChangePlatform }) {
    const supportedPlatforms = ["FACEBOOK", "INSTAGRAM", "PINTEREST"];

    return (
        avalibaleProfile
            .filter(profile => supportedPlatforms.includes(profile.platform))
            .map((elem, index) => (
                <li key={index}>
                    <div className="d-flex align-items-center justify-content-between pages_list_view">
                        <div className="d-flex align-items-center w-50">
                            {renderProfileImage(elem)}
                            {renderPlatformIcon(elem.platform)}
                            {renderProfileName(elem)}
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={CheckPlatform(elem)}
                                onChange={(e) => ChangePlatform(elem, e.target.checked)}
                            />
                        </div>
                    </div>
                </li>
            ))
    );
}

const renderProfileImage = (elem) => {
    switch (elem.platform) {
        case "FACEBOOK":
            return <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className="profile_img_post" />;
        case "INSTAGRAM":
            return <Images Id={elem.platform_id} />;
        case "PINTEREST":
            return <PinImage Id={elem.platform_id} />;
        default:
            return null;
    }
};

const renderPlatformIcon = (platform) => {
    const icons = {
        FACEBOOK: { class: "fa-brands fa-facebook", color: "#1877F2" },
        INSTAGRAM: { class: "fa-brands fa-instagram", color: "#E1306C" },
        PINTEREST: { class: "fa-brands fa-pinterest", color: "#E60023" },
    };

    return icons[platform] ? <i className={`${icons[platform].class} mx-2`} style={{ color: icons[platform].color }}></i> : null;
};

const renderProfileName = (elem) => {
    switch (elem.platform) {
        case "FACEBOOK":
            return <>{elem.profile}</>;
        case "INSTAGRAM":
            return <UserName Id={elem.platform_id} />;
        case "PINTEREST":
            return <PinName Id={elem.platform_id} />;
        default:
            return null;
    }
};

export default ShowProfiles;
