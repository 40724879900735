import { createSlice } from "@reduxjs/toolkit";

const AnalyticsSlice = createSlice({
  name: "AnalyticsSlice",
  initialState: {
    analytics: {},
  },

  reducers: {
    AnalyticseGrowth(state, action) {
      state.length = 0;
      state.analytics = action.payload;
      // state=action.payload
      // return
    },
  },
});

export { AnalyticsSlice };

export const { AnalyticseGrowth } = AnalyticsSlice.actions;
