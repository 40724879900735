import React from "react";

import { numify } from "numify";

export default function Comp_profile({ competitorData }) {
  console.log(competitorData);

  return (
    <>
      <div className="chart_section">
        <div className="chart_heading d-flex flex-column px-3 py-3">
          <label className="label_head">Profiles</label>

          <label className="label_sub_head">
            Review aggregate activity for pages and the posts published during
            the selected time period.
          </label>
        </div>

        <div style={{ overflowX: "auto" }}>
          <table className="comp-profile-tbl table">
            <thead>
              <tr>
                <th style={{ width: "70%" }}>Profile</th>
                <th>Audience</th>
                <th>Net Audience Growth</th>
                <th>Published Posts</th>
                <th>Public Engagements</th>
                <th>Public Engagements per Post</th>
                <th>% Audience Growth</th>
                <th>Published Carousels</th>
                <th>Published Videos</th>
                <th>Published Photos</th>
                <th>Reactions</th>
                <th>Comments</th>
                <th>Top Hashtags by Lifetime Public Engagements</th>
              </tr>
            </thead>

            <tbody>
              {competitorData?.map((elem, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mx-2">{index + 1}</span>
                        {elem.platform === "Instagram" ? (
                          <i
                            class="fa-brands fa-instagram"
                            style={{ color: "#E1306C" }}
                          ></i>
                        ) : (
                          <i
                            class="fa-brands fa-pinterest"
                            style={{ color: "#E60023" }}
                          ></i>
                        )}
                        <span className="mx-2" style={{ fontWeight: "bold" }}>
                          {elem.username}
                        </span>
                      </div>
                    </td>

                    {elem.details.length === 0 ? (
                      <>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </>
                    ) : (
                        <>
                        {/* Audience */}
                        <td>
                          {numify(
                            elem.details.length === 1
                              ? elem.details[0].data.business_discovery.followers_count
                              : elem.details.at(-1).data.business_discovery.followers_count
                          )}
                        </td>
                      
                        {/* Audience Growth */}
                        <td>
                          {elem.details.length === 1
                            ? "-"
                            : numify(
                                elem.details.at(-1).data.business_discovery.followers_count -
                                  elem.details[0].data.business_discovery.followers_count
                              )}
                        </td>
                      
                        {/* Published Posts */}
                        <td></td>
                      
                        {/* Public Engagements */}
                        <td>
                          {numify(
                            elem.details.reduce((acc, detail) => {
                              return (
                                acc +
                                (detail.data.business_discovery.media.data || []).reduce(
                                  (mediaAcc, mediaItem) =>
                                    mediaAcc + (mediaItem.like_count || 0) + (mediaItem.comments_count || 0),
                                  0
                                )
                              );
                            }, 0)
                          )}
                        </td>
                      
                        {/* Public Engagements per Post */}
                        <td>
                          {numify(
                            elem.details.reduce((acc, detail) => {
                              return (
                                acc +
                                (detail.data.business_discovery.media.data || []).reduce(
                                  (mediaAcc, mediaItem) =>
                                    mediaAcc + (mediaItem.like_count || 0) + (mediaItem.comments_count || 0),
                                  0
                                )
                              );
                            }, 0) / 25
                          )}
                        </td>
                      
                        {/* % Audience Growth */}
                        <td>
                          {(
                            ((elem.details.at(-1).data.business_discovery.followers_count -
                              elem.details[0].data.business_discovery.followers_count) /
                              elem.details[0].data.business_discovery.followers_count) *
                            100
                          ).toFixed(2) + "%"}
                        </td>
                      
                        {/* Published Carousels */}
                        <td>
                          {
                            (elem.details.at(-1).data.business_discovery.media.data || []).filter(
                              (item) => item.media_type === "CAROUSEL_ALBUM"
                            ).length
                          }
                        </td>
                      
                        {/* Published Videos */}
                        <td>
                          {
                            (elem.details.at(-1).data.business_discovery.media.data || []).filter(
                              (item) => item.media_type === "VIDEO"
                            ).length
                          }
                        </td>
                      
                        {/* Published Photos */}
                        <td>
                          {
                            (elem.details.at(-1).data.business_discovery.media.data || []).filter(
                              (item) => item.media_type === "IMAGE"
                            ).length
                          }
                        </td>
                      
                        {/* Reactions */}
                        <td>
                          {numify(
                            elem.details.reduce((acc, detail) => {
                              return (
                                acc +
                                (detail.data.business_discovery.media.data || []).reduce(
                                  (mediaAcc, mediaItem) => mediaAcc + (mediaItem.like_count || 0),
                                  0
                                )
                              );
                            }, 0)
                          )}
                        </td>
                      
                        {/* Comments */}
                        <td>
                          {numify(
                            elem.details.reduce((acc, detail) => {
                              return (
                                acc +
                                (detail.data.business_discovery.media.data || []).reduce(
                                  (mediaAcc, mediaItem) => mediaAcc + (mediaItem.comments_count || 0),
                                  0
                                )
                              );
                            }, 0)
                          )}
                        </td>
                      </>
                      
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
