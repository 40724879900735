const { REACT_APP_BACKEND_API } = require("../config");
const { default: axiosServices } = require("./axios");

// Generate Caption API
export const generateCaptionApi = async (imageUrl) => {
  try {
    const response = await axiosServices.post("https://oolook-api-gtfxd0cxc9bgf0fu.centralindia-01.azurewebsites.net/scrape", {
      image_url: imageUrl
    });
    return { error: false, data: response.data.captions };
  } catch (error) {
    return { error: true, message: error?.response?.data?.message || error?.message };
  }
};

// Post API (Handles both Scheduled & Instant Posting)
export const postApi = async ({ PhotoPreview, scheduleDates, SelectedPlatform, PublishType, title, fileType, messages, description, BoardID, instaContentData }) => {
  try {
    // Validate required inputs
    if (!PhotoPreview?.length || !SelectedPlatform?.length) {
      throw new Error("Missing required fields");
    }

    // Remove duplicate platform entries by using a Map with a unique identifier
    const uniquePlatforms = new Map();

    SelectedPlatform.forEach(platform => {
      const uniqueKey = `${platform.platform}_${platform.platform === "FACEBOOK" ? platform.platform_pageId : platform.platform_id}`;
      if (!uniquePlatforms.has(uniqueKey)) {
        uniquePlatforms.set(uniqueKey, platform);
      }
    });

    // Convert Map back to array
    const uniqueSelectedPlatform = Array.from(uniquePlatforms.values());

    // Determine media type based on PublishType
    const media_type = PublishType.toLowerCase() === "multiple"
      ? (fileType === "video" ? "video" : "image")
      : (PublishType.toLowerCase() === "stories" ? "story" : "image");

    // Function to convert date-time to ISO 8601 format
    const formatToISO = (dateString, timeString) => {
      const date = new Date(dateString);
      const time = new Date(timeString);

      // Set time on the date object
      date.setHours(time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds(), time.getUTCMilliseconds());

      // Return the date in ISO format (UTC)
      return date.toISOString();
    };

    const formattedScheduleDates = scheduleDates.map(item => ({
      date: formatToISO(item.date, item.time) // Convert to UTC ISO format
    }));

  
    // Determine endpoint
    const scheduleDateTime = new Date(formattedScheduleDates?.[0]?.date);
    const currentDateTime = new Date();

    let endpoint =
      scheduleDateTime > currentDateTime
        ? "/group/schedule-posting"
        : "/group/group-posting";

   if(uniqueSelectedPlatform.some(p => p.platform === "PINTEREST") && !BoardID){
return { error: true, message: "Please select a board" };
   }
    // Create the base payload with unique platforms
    const payload = {
      groupName: localStorage.getItem("oolook_default_group"),
      media_type,
      media_url: PhotoPreview[0],
      message: messages,
      title,
      first_comment: instaContentData?.firstComment,
      collaborators: instaContentData?.collaborators,
      description,
      board_id: BoardID,
      scheduledTime: formattedScheduleDates.map(d => d.date), // Array of ISO 8601 strings
      selectedProfiles: uniqueSelectedPlatform.map(platform =>
        platform.platform === "FACEBOOK" ? platform.platform_pageId : platform.platform_id
      ),
      platforms: [...new Set(uniqueSelectedPlatform.map(platform => platform.platform))]
    };

    // Handle carousel posts
    if (PublishType.toLowerCase() === "multiple" && PhotoPreview.length > 1) {
      payload.media_urls = PhotoPreview;
      payload.is_carousel = true;
    }

    // Add Instagram-specific fields for reels
    if (uniqueSelectedPlatform.some(p => p.platform === "INSTAGRAM") && PublishType.toLowerCase() === "reels") {
      payload.instagram_media_type = "REELS";
      payload.instagram_sharing_type = "REEL";
    }

    console.log('Posting payload:', payload); // For debugging

    const response = await axiosServices.post(endpoint, payload);
    return { error: false, data: response.data };

  } catch (error) {
    console.error("Post API Error:", error);
    return {
      error: true,
      message: error?.response?.data?.message || error?.message || "Failed to post content"
    };
  }
};


// Delete Image API (Handles Facebook & Instagram Cases)

// Get Pinterest Board List
export const getBoardApi = async (boardID) => {
  try {
    const response = await axiosServices.post(`/pinterest/get-board`, {
      user_id: boardID
    });
    return { error: false, data: response.data.items };
  } catch (error) {
    return { error: true, message: error?.response?.data?.message || error?.message };
  }
};
export const getPost = async (data) => {
  try {
    let response = await axiosServices.post(`/posts/get-filtered`, data);
    return { error: false, data: response };
  } catch (error) {
    return { error: true, message: error?.response?.data?.message || error?.message };
  }

};

// Create Pinterest Board
export const createBoardApi = async ({ DefaultBoard, newBoardName, newBoardDes }) => {
  if (!newBoardName) return { error: true, message: "Enter Board Name" };

  try {
    const response = await axiosServices.post(`/pinterest/create-board`, {
      user_id: DefaultBoard,
      name: newBoardName,
      description: newBoardDes,
      privacy: "PUBLIC"
    });

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, message: error?.response?.data?.message || error?.message };
  }
};

export const getScheduledData = async (name) => {
  try {
    let response = await axiosServices
      .get(
        `${process.env.REACT_APP_BACKEND_API
        }/schedule/get-all/${name}`,

      )
    return { error: false, data: response.data };

  } catch (error) {
    return { error: true, message: error?.response?.data?.message || error?.message };
  }
};


export const importFileService = async (file, groupName, selectedProfiles, platforms) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("groupName", groupName);

    selectedProfiles.forEach((profile) => {
      formData.append("selectedProfiles", profile.platform_id);
    });

    platforms.forEach((platform) => {
      formData.append("platforms", platform);
    });


    const response = await axiosServices.post(`/schedule/upload-schedules`, formData);

    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error importing file"
    };
  }
};
export const generateTaskService = async () => {
  try {

    const response = await axiosServices.post(`/task/generate`, {});

    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false, message: error.response?.data?.message || "Error generating task" };
  }
};
export const getPostDataService = async (platformType, selectedPlatform, groupName) => {
  try {

    if (!platformType || !selectedPlatform) {
      throw new Error("Platform type and selected profiles are required");
    }

    let platformSelected = [];

    selectedPlatform.forEach((elem) => {
      if (elem.platform === "FACEBOOK") {
        platformSelected.push(elem.platform_pageId);
      } else {
        platformSelected.push(elem.platform_id);
      }
    });

    const response = await axiosServices.post(
      `/posts/get-all`,
      {
        platform: platformType,
        groupName,
        user_id: platformSelected,
      }
    );

    return { success: true, data: response.data.Posts };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error fetching posts"
    };
  }
};
export const getGeneratedTaskService = async () => {
  try {

    const response = await axiosServices.get(`/task/get`);

    const tasks = response.data.tasks || [];
    return { success: true, data: tasks.length ? tasks[0].details : [] };
  } catch (error) {
    return { success: false, message: error.response?.data?.message || "Error fetching generated tasks" };
  }
};


