import React, { useEffect, useState } from "react";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";

import { Checkbox, message, Modal } from "antd";
import GenereatedData from "./GenereatedData";
import Images from "../Comman Components/Images";
import UserName from "../Comman Components/UserName";
import PinName from "../Comman Components/Pinterest Componets/PinName";
import PinImage from "../Comman Components/Pinterest Componets/PinImage";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupDetails } from "../store/Slices/GroupSlice";
import {
  generateTaskService,
  getGeneratedTaskService,
  getPostDataService,
  getScheduledData,
  importFileService,
} from "../services/publish";
import ImportPostsModal from "./ImportPostsModal";
const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Calender() {
  const [dataObject, setDataObject] = useState(moment().utc());
  const [month, setMonth] = useState("");
  const [ViewType, setViewType] = useState("Month");
  const [GeneratedData, setGeneratedData] = useState(null);
  const [Profilecheck, setProfileCheck] = useState(false);
  const [getPostData, setGetPostData] = useState(null);
  const [TypeFilter, setTypeFilter] = useState(false);
  const [PlatformFilter, setPlatformFilter] = useState(false);
  const [Type, setType] = useState(["Image"]);
  const [MediaType, setMediaType] = useState("image");
  const [PlatformType, setPlatformType] = useState([
    "FACEBOOK",
    "INSTAGRAM",
    "PINTEREST",
  ]);
  const [AccountFilter, setAccountFilter] = useState(false);
  const [SelectedPlatform, setSelectedPlatform] = useState(null);
  const [AvaliableAccount, setAvaliableAccount] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [ImportOpen, setImportOpen] = useState(false);
  const [AllGroups, setAllGroups] = useState(null);
  const [showGroups, setShowGroups] = useState(false);
  //const [selectedGroup, setSelctedGroup] = useState('');
  const [ImportExcel, setImportExcel] = useState(null);
  const [ScheduledData, setScheduledData] = useState(null);
  const [AuthorFilter, setAuthorFilter] = useState(false);
  const [AuthorType, setAuthorType] = useState(["Oolook"]);
  const [currentWeek, setCurrentWeek] = useState(moment().utc().week());
  const [selectedDate, setSelectedDate] = useState(moment().utc());

  const navigatie = useNavigate();
  const { groups, selectedGroup, profile } = useSelector(
    (state) => state.groups
  );
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const groupName = localStorage.getItem("oolook_default_group");

  console.log('ScheduledData', ScheduledData);


  const GetProfile = () => {
    if (profile.length === 0) {
      setProfileCheck(true);
    } else {
      setProfileCheck(false);
    }
  };

  const GetPostData = async () => {
    const response = await getPostDataService(PlatformType, SelectedPlatform, selectedGroup);

    if (response.success) {
      setGetPostData(response.data);
    } else {
      console.error("Fetch Error:", response.message);
    }
  };

  const firstDayOfMonth = () => {
    if (!dataObject) return 0; // Ensure dataObject is defined
    return moment(dataObject).startOf("month").day(); // Returns 0-6
  };


  function WeekRender() {
    const weekdayshort = moment.weekdaysShort();
    let weekdayshortname = weekdayshort.map((day) => {
      return (
        <th key={day} className="week-day">
          {day}
        </th>
      );
    });

    return weekdayshortname;
  }
  function RenderDate() {
    let dates = moment(dataObject).daysInMonth();
    const today = moment(); // Get today's date

    let date = [];

    let firstDay = moment(dataObject).startOf("month").day();
    let lastDatePrevMonth = moment(dataObject).subtract(1, "month").endOf("month").date();

    let blanks = [];
    for (let i = 0; i < firstDay; i++) {
      blanks.push(
        <td key={`empty-${i}`} className="calendar-day previous_dates empty">
          <div>{lastDatePrevMonth - (firstDay - 1) + i}</div>
        </td>
      );
    }

    for (let d = 1; d <= dates; d++) {
      const currentDate = moment(dataObject).date(d);
      const isPastDate = currentDate.isBefore(today, "day");
      const postForTheDay = getPostData.filter((post) => {
        const postDate = moment(post.date).utc(); // Ensure UTC consistency
        console.log(postDate.month(), post.date, 'getPostDatagetPostDatagetPostDatagetPostDatagetPostDatagetPostData')
        // console.log( postDate.date() == d , postDate.month() , dataObject.month())
        return (
          postDate.date() === d && postDate.month() === dataObject.month()
        );
      });

      // console.log(postForTheDay,'getPostDatagetPostDatagetPostData')

      let scheduledDates;
      let importedPosts = {};

      if (ScheduledData !== null) {
        scheduledDates = ScheduledData.filter(
          (post) =>
            moment(post.scheduledTime.split("T")[0]).date() === d &&
            moment(post.scheduledTime.split("T")[0]).month() === dataObject.month()
        );
        //   console.log(ScheduledData,'scheduledDatesscheduledDates')
        scheduledDates.forEach((item) => {
          item.platforms.forEach((platform) => {
            platform.toUpperCase();

            if (importedPosts[platform]) {
              importedPosts[platform]++;
            } else {
              importedPosts[platform] = 1;
            }
          });
        });
      }

      const groupedPosts = postForTheDay.reduce((acc, post) => {
        if (!acc[post.platform]) {
          acc[post.platform] = [];
        }
        acc[post.platform].push(post);
        return acc;
      }, {});

      let cellClass = isPastDate ? "previous_date empty p-2" : "weeks-day";
      let showPosts = postForTheDay.length > 0 || Object.keys(importedPosts).length > 0;
      ///console.log(showPosts,importedPosts,'showPostsshowPostsshowPosts')
      if (GeneratedData === null) {
        date.push(
          <th key={d} className={cellClass}>
            <div style={{ textAlign: "left", height: "10%", marginBottom: "10px" }}>
              {d}

              {showPosts && (
                <div className="mt-2 mx-2 d-flex flex-column">

                  {Object.entries(importedPosts)
                    .filter(([platform]) => PlatformType.includes(platform)) // Check if platform exists in PlatformType
                    .map(([platform, count]) => (
                      <span
                        className="posts_icons"
                        key={platform}
                        onClick={() =>
                          navigatie("/Dashboard/Approval", {
                            state: {
                              genData: {
                                platform,
                                GroupData: groupedPosts[platform],
                              },
                            },
                          })
                        }
                      >
                        {platform === "FACEBOOK" && <i className="fa-brands fa-facebook"></i>}
                        {platform === "INSTAGRAM" && <i className="fa-brands fa-instagram"></i>}
                        {platform === "PINTEREST" && <i className="fa-brands fa-pinterest"></i>}
                        {count}
                      </span>
                    ))}

                  {/* Display Grouped Posts */}
                  {Object.keys(groupedPosts)
                    .filter((platform) => PlatformType.includes(platform)) // Check if platform exists in PlatformType
                    .map((platform) => (
                      <span
                        className="posts_icons"
                        key={platform}
                        onClick={() =>
                          navigatie("/Dashboard/Approval", {
                            state: {
                              genData: {
                                platform,
                                GroupData: groupedPosts[platform],
                              },
                            },
                          })
                        }
                      >
                        {platform === "FACEBOOK" && <i className="fa-brands fa-facebook"></i>}
                        {platform === "INSTAGRAM" && <i className="fa-brands fa-instagram"></i>}
                        {platform === "PINTEREST" && <i className="fa-brands fa-pinterest"></i>}
                        {groupedPosts[platform].length}
                      </span>
                    ))}
                </div>
              )}

            </div>

            {/* Only show buttons for today or future dates */}
            <div style={{ height: "90%" }}>
              {!isPastDate ? (
                <div className="action_task">
                  <button
                    id={d}
                    className="tasks add_task"
                    onClick={(e) =>
                      navigatie("/Dashboard/PublishPost", {
                        state: {
                          date: parseInt(e.target.id),
                          month: moment(dataObject).month(),
                        },
                      })
                    }
                  >
                    +
                  </button>
                  <button className="tasks add_task">
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              ) : (
                <div className="empty-placeholder"></div> // Ensures consistent spacing
              )}
            </div>
          </th>
        );
      }
      else {
        date.push(
          <GenereatedData
            date={d}
            PostedData={groupedPosts}
            importedPosts={importedPosts}
            dataObject={dataObject}
            month={moment(dataObject).month() + 1}
            genratedData={GeneratedData}
          />
        );
      }
    }

    var totalSlots = [...blanks, ...date];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr key={i}>{d}</tr>;
    });

    return daysinmonth;
  }

  function WeekView() {
    const weekStart = moment(dataObject).week(currentWeek).startOf('week');
    const days = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = moment(weekStart).add(i, 'days');
      const isPastDate = currentDate.isBefore(moment(), 'day');
      const isCurrentDate = currentDate.isSame(moment(), 'day');

      // Get posts for the current day
      const postForTheDay = getPostData?.filter(
        (post) =>
          moment(post.date).date() === currentDate.date() &&
          moment(post.date).month() === currentDate.month()
      ) || [];

      // Group posts by platform
      const groupedPosts = postForTheDay.reduce((acc, post) => {
        if (!acc[post.platform]) {
          acc[post.platform] = [];
        }
        acc[post.platform].push(post);
        return acc;
      }, {});

      // Get scheduled posts for the day
      let importedPosts = {};
      if (ScheduledData) {
        const scheduledDates = ScheduledData.filter(
          (post) =>
            moment(post.scheduledTime.split("T")[0]).date() === currentDate.date() &&
            moment(post.scheduledTime.split("T")[0]).month() === currentDate.month()
        );

        scheduledDates.forEach((item) => {
          item.platforms.forEach((platform) => {
            platform = platform.toUpperCase();
            if (importedPosts[platform]) {
              importedPosts[platform]++;
            } else {
              importedPosts[platform] = 1;
            }
          });
        });
      }

      const showPosts = Object.keys(groupedPosts).length > 0 || Object.keys(importedPosts).length > 0;

      days.push(
        <td
          key={i}
          className={`weeks-day ${isCurrentDate ? 'current' : ''} ${isPastDate ? 'past-date' : ''}`}
        >
          <div className="d-flex flex-column h-100">
            <div className="date-header mb-3">
              <div className="date-number">{currentDate.format('D')}</div>
              <div className="date-day">{currentDate.format('ddd')}</div>
            </div>

            {/* Social Media Icons Section */}
            {showPosts && (
              <div className="social-icons-container mb-3">
                {/* Show Imported Posts */}
                {Object.entries(importedPosts)
                  .filter(([platform]) => PlatformType.includes(platform))
                  .map(([platform, count]) => (
                    <span
                      className="posts_icons"
                      key={platform}
                      onClick={() =>
                        navigatie("/Dashboard/Approval", {
                          state: {
                            genData: {
                              platform,
                              GroupData: groupedPosts[platform],
                            },
                          },
                        })
                      }
                    >
                      {platform === "FACEBOOK" && <i className="fa-brands fa-facebook"></i>}
                      {platform === "INSTAGRAM" && <i className="fa-brands fa-instagram"></i>}
                      {platform === "PINTEREST" && <i className="fa-brands fa-pinterest"></i>}
                      {count}
                    </span>
                  ))}

                {/* Show Regular Posts */}
                {Object.entries(groupedPosts)
                  .filter(([platform]) => PlatformType.includes(platform))
                  .map(([platform, posts]) => (
                    <span
                      className="posts_icons"
                      key={platform}
                      onClick={() =>
                        navigatie("/Dashboard/Approval", {
                          state: {
                            genData: {
                              platform,
                              GroupData: posts,
                            },
                          },
                        })
                      }
                    >
                      {platform === "FACEBOOK" && <i className="fa-brands fa-facebook"></i>}
                      {platform === "INSTAGRAM" && <i className="fa-brands fa-instagram"></i>}
                      {platform === "PINTEREST" && <i className="fa-brands fa-pinterest"></i>}
                      {posts.length}
                    </span>
                  ))}
              </div>
            )}

            {/* Action Buttons */}
            <div className="week-action-buttons mt-auto">
              {isPastDate ? (
                <div className="past-date-placeholder">
                  <div className="schedule-post-btn disabled">
                    Past Date
                  </div>
                  <div className="draft-btn disabled">
                    <i className="fa fa-edit me-2"></i>
                    Past Draft
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className="schedule-post-btn"
                    onClick={() =>
                      navigatie("/Dashboard/PublishPost", {
                        state: {
                          date: currentDate.date(),
                          month: currentDate.month(),
                        },
                      })
                    }
                  >
                    Schedule Post
                  </button>
                  <button className="draft-btn">
                    <i className="fa fa-edit me-2"></i>
                    Start Draft
                  </button>
                </>
              )}
            </div>
          </div>
        </td>
      );
    }

    return (
      <table className="week-view-table">
        <tbody>
          <tr>{days}</tr>
        </tbody>
      </table>
    );
  }

  const switchToWeekView = () => {
    setCurrentWeek(moment(dataObject).week());
    setViewType("Week");
  };

  const switchToMonthView = () => {
    setViewType("Month");
  };

  const NextMonth = () => {
    let newDateObject = moment(dataObject).clone().add(1, "month"); // Clone to avoid mutation
    console.log(newDateObject.month(), 'newDateObject')

    setMonth(`${Months[newDateObject.month()]}, ${newDateObject.year()}`);
    setDataObject(newDateObject);
    setSelectedDate(newDateObject);
  };

  const PreviousMonth = () => {
    let newDateObject = moment(dataObject).clone().subtract(1, "month"); // Clone to prevent mutation
    console.log(newDateObject.month(), 'newDateObject')
    setMonth(`${Months[newDateObject.month()]}, ${newDateObject.year()}`);
    setDataObject(newDateObject);
    setSelectedDate(newDateObject);
  };


  const currentMonth = () => {
    const today = moment();
    setMonth(Months[today.month()] + ", " + today.year());
    setDataObject(today);
    setSelectedDate(today);
    setCurrentWeek(today.week());
  };

  const NextWeek = () => {
    const newDate = moment(dataObject).add(1, 'week');
    setCurrentWeek(newDate.week());
    setDataObject(newDate);
    setSelectedDate(newDate);
    setMonth(Months[newDate.month()] + ", " + newDate.year());
  };

  const PreviousWeek = () => {
    const newDate = moment(dataObject).subtract(1, 'week');
    setCurrentWeek(newDate.week());
    setDataObject(newDate);
    setSelectedDate(newDate);
    setMonth(Months[newDate.month()] + ", " + newDate.year());
  };

  const FilterData = [
    ["Post Type", ["Image", "Video", "Text", "Notes"]],
    ["Profiles", AvaliableAccount],
    ["Platform Type", ["FACEBOOK", "INSTAGRAM", "Twitter"]],
    ["Author", AuthorType]
  ];

  const Generate = async () => {
    const response = await generateTaskService();

    if (response.success) {
      setGeneratedData(response.data);
    } else {
      console.error("Generate Error:", response.message);
    }
  };
  const GetGenerateData = async () => {
    const response = await getGeneratedTaskService();

    if (response.success) {
      setGeneratedData(response.data);
    } else {
      console.error("Fetch Error:", response.message);
    }
  };

  // Post Type Filter
  const ChangeType = (FilterType, checked) => {
    if (checked.target.checked === true) {
      if (FilterType === "Image") {
        Type.push("Image");
        if (Type.length === 2) {
          setMediaType("");
          GetPostData();
        } else {
          setMediaType("image");
          GetPostData();
        }

        setTypeFilter(false);
      } else {
        Type.push("Video");
        if (Type.length === 2) {
          setMediaType("");
          GetPostData();
        } else {
          setMediaType("video");
          GetPostData();
        }

        setTypeFilter(false);
      }
    } else {
      if (FilterType === "Image") {
        Type.splice(Type.indexOf("Image"), 1);
        setTypeFilter(false);
        GetPostData();
      } else {
        Type.splice(Type.indexOf("Video"), 1);
        setTypeFilter(false);
        GetPostData();
      }
    }
  };

  // Platform Filter
  const ChangePlatform = (filterType, event) => {
    const isChecked = event.target.checked;
    const index = PlatformType.indexOf(filterType);

    if (!isChecked && index !== -1) {
      PlatformType.splice(index, 1);
    } else if (isChecked && index === -1) {
      PlatformType.push(filterType);
    }

    setPlatformFilter(false);
    GetPostData();
  };


  // Get particular Groups
  const GetGroups = () => {
    setAvaliableAccount(profile);

    var selePlatform;

    selePlatform = profile?.slice(0);

    const indexToRemove = selePlatform.findIndex(
      (item) => item.platform === "PINTEREST"
    );

    // If a Pinterest object is found, remove it
    if (indexToRemove !== -1) {
      selePlatform.splice(indexToRemove, 1);
    }

    setSelectedPlatform(selePlatform);
  };

  // Get all groups

  // Account Filter
  const CheckDefaultSelect = (Account) => {
    if (Account.platform === "FACEBOOK") {
      for (var i = 0; i < SelectedPlatform.length; i++) {
        if (SelectedPlatform[i].platform_pageId === Account.platform_pageId) {
          return true;
        }
      }
    } else {
      for (var i = 0; i < SelectedPlatform.length; i++) {
        if (SelectedPlatform[i].platform_id === Account.platform_id) {
          return true;
        }
      }
    }
  };

  const ChangePaltform = (value, checked) => {
    if (checked) {
      SelectedPlatform.push(value);
      GetPostData();
    } else {
      if (value.platform === "FACEBOOK") {
        SelectedPlatform.map((item) => {
          if (item.platform_pageId === value.platform_pageId) {
            SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
            GetPostData();
            // console.log(item.platform_id);
          }
        });
      }
      if (value.platform === "INSTAGRAM") {
        SelectedPlatform.map((item) => {
          if (item.platform_id === value.platform_id) {
            SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
            GetPostData();
          }
        });
      }
      if (value.platform === "PINTEREST") {
        SelectedPlatform.map((item) => {
          if (item.platform_id === value.platform_id) {
            SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
            GetPostData();
          }
        });
      }
    }
  };

  // download sample file
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/Samplefile.xlsx"; // Replace with the actual file URL or path
    link.download = "Samplefiles.xlsx"; // The name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Import File
  const ImportFile = async () => {
    if (!ImportExcel?.length) {
      message.error("Please select a file to import");
      return;
    }

    const response = await importFileService(
      ImportExcel[0],
      selectedGroup,
      SelectedPlatform,
      PlatformType
    );

    if (response.success) {
      message.success("File Imported");
      setImportOpen(false);
    } else {
      console.error("Import Error:", response.message);
      message.error(response.message);
    }
  };

  // Get All ScheduledData
  const GetScheduledData = async () => {
    try {
      const res = await getScheduledData(groupName);

      if (res.error) {
        console.error("Error fetching scheduled data:", res.message);
        setScheduledData([]); // Reset to empty array on error
      } else {
        setScheduledData(res.data.scheduled_posts || []); // Ensure fallback to empty array
      }
    } catch (err) {
      console.error("Unexpected error in GetScheduledData:", err.message);
      setScheduledData([]); // Reset data on unexpected failure
    }
  };

  // Author Filter
  const ChangeAuthor = (FilterType, checked) => {
    if (checked.target.checked) {
      setAuthorType([...AuthorType, FilterType]);
    } else {
      setAuthorType(AuthorType.filter(author => author !== FilterType));
    }
    setAuthorFilter(false);
  };

  useEffect(() => {
    setMonth(Months[moment(dataObject).month()] + ", " + moment().year());
    // GetGenerateData();
    GetGroups();
  }, [groupName]);
  useEffect(() => {

    GetProfile();
    GetScheduledData();
  }, [profile, groupName]);

  useEffect(() => {
    GetPostData();
  }, [SelectedPlatform]);

  if (profile.length === 0) {
    return (
      <div>
        <div></div>
        <div className="d-flex justify-content-center align-items-center h-screen ">
          <h3 className="text-[#d40c0c] font-semibold">Connect atleast one profile</h3>
        </div>
      </div>
    );
  }

  return (
    <div className="calender_section">
      <div className="d-flex align-items-center justify-content-between">
        <div className="approval_header d-flex">
          <button
            className="p-2 rounded hover:bg-gray-100 text-gray-600"
            onClick={() => {
              ViewType === "Month" ? PreviousMonth() : PreviousWeek();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>

          <h4
            className="px-3 py-2 mx-2 rounded hover:bg-blue-400 bg-blue-600 text-white cursor-pointer font-medium"
            onClick={() => {
              currentMonth();
            }}
          >
            Today
          </h4>

          <button
            className="p-2 rounded hover:bg-gray-100 text-gray-600"
            style={{ marginRight: "10px" }}
            onClick={() => {
              ViewType === "Month" ? NextMonth() : NextWeek();
            }}
          >
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>

          <h5 className="text-lg font-medium text-gray-900">{month}</h5>
        </div>

        <div className="d-flex">
          <div className="d-flex justify-content-between menu_section mx-2">
            <button
              className={ViewType === "Week" ? "menu_btn_1 menu_active" : "menu_btn_1"}
              onClick={switchToWeekView}
            >
              Week
            </button>

            <button
              className={ViewType === "Month" ? "menu_btn_2 menu_active" : "menu_btn_2"}
              onClick={switchToMonthView}
            >
              Month
            </button>
          </div>

          <div className="flex-column d-flex mt-1">
            <div className="d-flex justify-content-between dot_menu menu_section mx-2">
              <button
                className="menu_btn_2"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              >
                <i className="fa fa-ellipsis-h"></i>
              </button>
            </div>

            <>
              {showMenu ? (
                <div className="list_menu">
                  <ul>
                    <li>
                      <button
                        onClick={() => {
                          setImportOpen(true);
                          setShowMenu(false);
                        }}
                      >
                        Import File
                      </button>
                    </li>
                  </ul>
                </div>
              ) : null}
            </>
          </div>

          <>
            <Modal
              open={ImportOpen}
              onCancel={() => setImportOpen(false)}
              footer={null}
            >
              <ImportPostsModal />
            </Modal>
          </>

          <div className="d-flex justify-content-between align-items-center px-2 menu_section mx-2 menu_active">
            <img
              src={require("../Assets/share.png")}
              style={{ width: "20px", height: "20px" }}
            />
            <button className="menu_btn_2">Share</button>
          </div>

          <div className="d-flex justify-content-between align-items-center px-2 menu_section mx-2 menu_active">
            <button
              className="menu_btn_2 "
              style={{ color: "#fff" }}
              onClick={() => {
                Generate();
              }}
            >
              Generate
            </button>

            <img
              src={require("../Assets/generate.png")}
              style={{ width: "20px", height: "20px" }}
            />
          </div>
        </div>
      </div>

      {/* Filter List */}
      <div className="d-flex align-items-center justify-content-between filter_section">
        {/* Posting Type Filter */}
        <div
          style={{ width: "33.3%", marginLeft: "10px", marginRight: "10px" }}
        >
          <button
            className="d-flex align-items-center justify-content-between select_filter"
            onClick={() => {
              setTypeFilter(!TypeFilter);
            }}
          >
            <div className="d-flex flex-column mx-2">
              <div className="filter_head">{FilterData[0][0]}</div>

              {/* Options */}
              <div className="filter_selcted_option">
                {Type.length !== 1 ? "Image, Video" : Type}{" "}
              </div>
            </div>

            <div>
              <i className={TypeFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
            </div>
          </button>

          {/* options list */}
          {TypeFilter ? (
            <div className="option_list">
              <ul>
                {FilterData[0][1].map((elem) => {
                  return (
                    <li>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          key={elem}
                          value={elem}
                          checked={Type.includes(elem)}
                          onClick={(e) => {
                            ChangeType(elem, e);
                          }}
                        >
                          {elem}
                        </Checkbox>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>

        {/* Platform Filter */}
        <div
          style={{ width: "33.3%", marginLeft: "10px", marginRight: "10px" }}
        >
          <button
            className="d-flex align-items-center justify-content-between select_filter"
            onClick={() => {
              setPlatformFilter(!PlatformFilter);
              console.log(PlatformFilter)
            }}
          >
            <div className="d-flex flex-column mx-2">
              <div className="filter_head">{FilterData[2][0]}</div>

              {/* Options */}
              <div className="filter_selcted_option">
                {PlatformType.length !== 1
                  ? PlatformType.length - 1 + "+" + " Platforms"
                  : PlatformType}{" "}
              </div>
            </div>

            <div>
              <i className={TypeFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
            </div>
          </button>

          {PlatformFilter ? (
            <div className="option_list">
              <ul>
                {FilterData[2][1].map((elem) => {
                  return (
                    <li>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          key={elem}
                          value={elem}
                          checked={PlatformType.includes(elem)}
                          onClick={(e) => {
                            ChangePlatform(elem, e);
                          }}
                        >
                          {elem}
                        </Checkbox>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>

        {/* Account Filter */}
        <div
          style={{ width: "33.3%", marginLeft: "10px", marginRight: "10px" }}
        >
          <button
            className="d-flex align-items-center justify-content-between select_filter"
            onClick={() => {
              setAccountFilter(!AccountFilter);
            }}
          >
            <div className="d-flex flex-column mx-2">
              <div className="filter_head">{FilterData[1][0]}</div>

              {/* Options */}
              {SelectedPlatform && SelectedPlatform.length > 0 ? (
                <div className="filter_selected_option">
                  {SelectedPlatform?.length > 1
                    ? `${SelectedPlatform?.length - 1} + Profiles`
                    : SelectedPlatform?.[0]?.profile}{" "}
                  {/* Display first profile name */}
                </div>
              ) : null}
            </div>

            <div>
              <i
                className={AccountFilter ? "fa fa-angle-up" : "fa fa-angle-down"}
              ></i>
            </div>
          </button>

          {AccountFilter ? (
            <div className="option_list">
              <ul>
                {AvaliableAccount.map((elem, index) => {
                  return (
                    <li>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center w-100">
                          {elem.platform === "FACEBOOK" ? (
                            <img
                              src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`}
                              className="filter_profile_img"
                            />
                          ) : elem.platform === "INSTAGRAM" ? (
                            <>{<Images Id={elem.platform_id} />}</>
                          ) : (
                            <PinImage Id={elem.platform_id} />
                          )}
                          {elem.platform === "FACEBOOK" ? (
                            <i
                              className="fa-brands fa-facebook mx-2"
                              style={{ color: "#1877F2" }}
                            ></i>
                          ) : elem.platform === "INSTAGRAM" ? (
                            <i
                              className="fa-brands fa-instagram mx-2"
                              style={{ color: "#E1306C" }}
                            ></i>
                          ) : elem.platform === "PINTEREST" ? (
                            <i
                              className="fa-brands fa-pinterest mx-2"
                              style={{ color: "#E60023" }}
                            ></i>
                          ) : null}
                          {elem.platform === "FACEBOOK" ? (
                            <>{elem.profile}</>
                          ) : elem.platform === "INSTAGRAM" ? (
                            <UserName Id={elem.platform_id} />
                          ) : (
                            <PinName Id={elem.platform_id} />
                          )}
                        </div>

                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={CheckDefaultSelect(elem)}
                            onChange={(e) => {
                              ChangePaltform(elem, e.target.checked);
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>

        {/* Author Filter */}
        <div style={{ width: "33.3%", marginLeft: "10px", marginRight: "10px" }}>
          <button
            className="d-flex align-items-center justify-content-between select_filter"
            onClick={() => {
              setAuthorFilter(!AuthorFilter);
            }}
          >
            <div className="d-flex flex-column mx-2">
              <div className="filter_head">{FilterData[3][0]}</div>

              {/* Options */}
              <div className="filter_selcted_option">
                {AuthorType.length !== 1
                  ? AuthorType.length - 1 + "+" + " Authors"
                  : AuthorType}{" "}
              </div>
            </div>

            <div>
              <i className={AuthorFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
            </div>
          </button>

          {AuthorFilter ? (
            <div className="option_list">
              <ul>
                {["Oolook", "Team User 1", "Team User 2"].map((elem) => {
                  return (
                    <li key={elem}>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          value={elem}
                          checked={AuthorType.includes(elem)}
                          onClick={(e) => {
                            ChangeAuthor(elem, e);
                          }}
                        >
                          {elem}
                        </Checkbox>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>

      <div className="days_section">
        {ViewType === "Month" ? (
          <table>
            <thead>{WeekRender()}</thead>
            <tbody>{getPostData !== null ? RenderDate() : null}</tbody>
          </table>
        ) : (
          WeekView()
        )}
      </div>
    </div>
  );
}
