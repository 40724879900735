import axios from 'axios';


// Create an axios instance
const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API, // Replace this with your actual backend API URL
});
// Interceptor for HTTP requests
// Used to pass the bearer token to the Muse endpoints
axiosServices.interceptors.request.use(
  function (config) {
    // Get the access token from cookies
    const token = localStorage.getItem('user_Token') || sessionStorage.getItem('user_Token');

    if (config.headers && config.url) {
      // Set the bearer token in the request header
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Perform logout action
      localStorage.removeItem('user_Token');
      sessionStorage.removeItem('user_Token');
      window.location.href = '/'; // Redirect to login page
    }
    if (error.response && error.response.status === 403) {
     
      window.location.href = '/onBoarding'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

// Interceptor for HTTP responses

export default axiosServices;
