import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function PinImage({ Id }) {
    const [Image, setImage] = useState();

    function GetPinDetails() {
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/pinterest/get-profile`, {
            user_id: Id
        }, {
            headers: {
                'Authorization': `Berear ${token}`
            }
        })
            .then((res) => {
                console.log('PinImage', res.data);

                setImage(res.data.userAccount.profile_image)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GetPinDetails()
    }, [])
    return (
        <img src={Image} className='profile_img' />
    )
}
