import { createSlice } from "@reduxjs/toolkit";

const ImpressionSlice=createSlice({
    name:"ImpressionSlice",
    initialState:[],
    reducers:{
        ImpressionGrowth(state,action){  
            state.length=0          
            state.push(action.payload)
            // state=action.payload
            // return
        },
    }
})

export {ImpressionSlice}

export const {ImpressionGrowth}=ImpressionSlice.actions