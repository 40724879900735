import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import DummyPreview from './DummyPreview';
import Cookies from 'js-cookie'
import { message } from 'antd';
import axios from 'axios';


export default function FacebookModal({ Previewimages, Previewcaptions, PostType }) {
    const [open, setOpen] = useState(true);
    const [PreviewImg, setPreviewImg] = useState(null);
    const [ProfileData, setProfileDate] = useState(null);

    const GetUserProfile = () => {

        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/page`, {
            pageId: JSON.parse(Cookies.get('Oolook_default'))
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setProfileDate(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        setPreviewImg(Previewimages[0])
        // GetUserProfile();
    }, [Previewimages, PostType, Previewcaptions])

    if (Previewimages.length === 0 && Previewcaptions === '') {
        return (
            <DummyPreview />
        )
    }
    else
        return (
            <>
                {
                    PostType === "STORIES"
                        ?
                        <div className='w-100 d-flex flex-column align-items-center social_preview_box my-3'>
                            <button onClick={() => { setOpen(!open) }} className='my-3 toggle_btn d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <i class="fa-brands fa-facebook fa-lg mx-2" style={{ color: '#1877F2' }}></i>
                                    <label className='social_name'>
                                        Facebook
                                    </label>
                                </div>

                                <div>
                                    <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                                </div>
                            </button>

                            {
                                open
                                    ?

                                    <div className='story_section'>
                                        <div className='d-flex p-2'>
                                            <div>
                                                <img src={ProfileData !== null ? ProfileData.picture.data.url : require('../../Assets/grp_profile.png')} />
                                            </div>

                                            <div className='d-flex flex-column mx-2'>
                                                <label className='userid_preview'>
                                                    {
                                                        ProfileData !== null
                                                            ?
                                                            ProfileData.name
                                                            :
                                                            "User Name"
                                                    }
                                                </label>

                                                <label className='date_preview'>
                                                    5 Mar
                                                </label>
                                            </div>
                                        </div>

                                        {
                                            PostType === "photo"
                                                ?
                                                <div className='d-flex row justify-content-center w-100 multiple_img'>
                                                    {
                                                        <div className='col-sm-6'>
                                                            <img src={Previewimages[0]} className="previewImgs" />
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                PostType === "video"
                                                    ?
                                                    < div className='d-flex justify-content-center'>
                                                        <video src={Previewimages[0]} controls className="previewImgs">
                                                        </video>
                                                    </div>
                                                    :
                                                    null
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        :
                        <div className='w-100 d-flex flex-column align-items-center social_preview_box my-3'>
                            <button onClick={() => { setOpen(!open) }} className='my-3 toggle_btn d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <i class="fa-brands fa-facebook fa-lg mx-2" style={{ color: '#1877F2' }}></i>
                                    <label className='social_name'>
                                        Facebook
                                    </label>
                                </div>

                                <div>
                                    <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                                </div>
                            </button>

                            {
                                open
                                    ?
                                    <div className='facebook_preview'>
                                        <div className='d-flex p-2'>
                                            <div>
                                                <img src={ProfileData !== null ? ProfileData.picture.data.url : require('../../Assets/grp_profile.png')} className='profile_picture' />
                                            </div>

                                            <div className='d-flex flex-column mx-2'>
                                                <label className='userid_preview'>
                                                    {
                                                        ProfileData !== null
                                                            ?
                                                            ProfileData.name
                                                            :
                                                            "User Name"
                                                    }
                                                </label>

                                                <label className='date_preview'>
                                                    5 Mar
                                                </label>
                                            </div>
                                        </div>



                                        <div className='mx-2 preview_captions'>
                                            {
                                                Previewcaptions === ''
                                                    ?
                                                    <Skeleton style={{ margin: '10px' }} width={70} />
                                                    :
                                                    Previewcaptions
                                            }
                                        </div>


                                        {
                                            PostType === "photo"
                                                ?
                                                <div className='d-flex row justify-content-center w-100 multiple_img'>
                                                    <div className='col-sm-6'>
                                                        <img src={Previewimages[0]} className="previewImgs" />
                                                    </div>

                                                </div>
                                                :
                                                PostType === "video"
                                                    ?
                                                    <div className='d-flex justify-content-center'>
                                                        <video src={Previewimages[0]} controls className="previewImgs">
                                                        </video>
                                                    </div>
                                                    :
                                                    null
                                        }


                                        <div className='d-flex justify-content-between likes_options'>
                                            <div>
                                                <i className="fa fa-thumbs-up mx-1"></i>
                                                Like
                                            </div>

                                            <div className='d-flex align-items-center'>
                                                <img src={require('../../Assets/fb_comment.png')} className='fb_comments' />
                                                Comment
                                            </div>

                                            <div>
                                                <i className="fa fa-share mx-1"></i>
                                                Share
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }

            </>
        )
}
