import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

export default function CompOverview({ competitorData }) {
  const [instaAvg, setInstaAvg] = useState(0);
  const [fbAvg, setFbAvg] = useState(0);
  const [twitterAvg, setTwitterAvg] = useState(0);
  const [instaPosts, setInstaPosts] = useState(0);
  const [fbPosts, setFbPosts] = useState(0);
  const [twitterPosts, setTwitterPosts] = useState(0);
 
  const [instaEngagement, setInstaEngagement] = useState(0);
  const [fbEngagement, setFbEngagement] = useState(0);
  const [instaEngagementPerPost, setInstaEngagementPerPost] = useState(0);
  const [fbEngagementPerPost, setFbEngagementPerPost] = useState(0);

  useEffect(() => {
    if (Array.isArray(competitorData) && competitorData.length > 0) {
      // Function to calculate averages
      const newData = competitorData.map((item) => ({
        platform: item?.platform,
        username: item?.username,
        followers_count: item?.details[0]?.data?.business_discovery?.followers_count || 0,
        published_posts: item?.details[0]?.data?.business_discovery?.media_count || 0,
      }));

      const calcAverage = (data, key) =>
        data.length > 0 ? data.reduce((sum, comp) => sum + (comp[key] || 0), 0) / data.length : 0;

      // Filter Data by Platform
      const instaData = newData.filter((comp) => comp.platform === "Instagram");
      const fbData = newData.filter((comp) => comp.platform === "Facebook");
      const twitterData = newData.filter((comp) => comp.platform === "Twitter");
      
      // Compute Averages
      console.log(calcAverage(instaData, "followers_count"))
      setInstaAvg(calcAverage(instaData, "followers_count"));
      setFbAvg(calcAverage(fbData, "followers_count"));
      setInstaEngagement(calcAverage(instaData, "engagements"));
      setFbEngagement(calcAverage(fbData, "engagements"));
      setInstaEngagementPerPost(calcAverage(instaData, "engagements_per_post"));
      setFbEngagementPerPost(calcAverage(fbData, "engagements_per_post"));
      // Set Values
      setInstaAvg(calcAverage(instaData, "followers_count"));
      setFbAvg(calcAverage(fbData, "followers_count"));
      setTwitterAvg(calcAverage(twitterData, "followers_count"));

      setInstaPosts(calcAverage(instaData, "published_posts"));
      setFbPosts(calcAverage(fbData, "published_posts"));
      setTwitterPosts(calcAverage(twitterData, "published_posts"));
    }
  }, [competitorData]);

  // Chart Options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { grid: { display: false } },
    },
    elements: { point: { radius: 0 } },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: { usePointStyle: true, pointStyle: "rectRounded", padding: 30 },
      },
    },
  };

  // Chart Data
  const data = {
    datasets: [
      {
        label: "Facebook Growth",
        data: fbAvg, 
        fill: true,
        borderColor: "#08C4B2",
        backgroundColor: "#08C4B2",
        borderWidth: 1,
        tension: 0.2,
      },
      {
        label: "Instagram Growth",
        data: instaAvg, 
        fill: true,
        borderColor: "#6F5ED3",
        backgroundColor: "#6F5ED3",
        borderWidth: 1,
        tension: 0.2,
      },
    ],
  };

  return (
    <div>
    <div className="com-div">
      {/* Audience Average */}
      <div className="comp-box2">
        <div style={{ fontSize: 14, fontWeight: "bold", textDecoration: "underline", marginTop: 10 }}>
          Audience Average
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 20 }}>
          <div className="comp-text1">Competitor Instagram Avg</div>
          <div className="comp-text22">{instaAvg.toLocaleString()}</div>
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 10 }}>
          <div className="comp-text1">Competitor Facebook Avg</div>
          <div className="comp-text22">{fbAvg.toLocaleString()}</div>
        </div>
      </div>

      {/* Public Engagements Average */}
      <div className="comp-box2">
        <div style={{ fontSize: 14, fontWeight: "bold", textDecoration: "underline", marginTop: 10 }}>
          Public Engagements Average
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 20 }}>
          <div className="comp-text1">Competitor Instagram Avg</div>
          <div className="comp-text22">{instaEngagement.toLocaleString()}</div>
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 10 }}>
          <div className="comp-text1">Competitor Facebook Avg</div>
          <div className="comp-text22">{fbEngagement.toLocaleString()}</div>
        </div>
      </div>

      {/* Public Engagements per Post */}
      <div className="comp-box2">
        <div style={{ fontSize: 14, fontWeight: "bold", textDecoration: "underline", marginTop: 10 }}>
          Public Engagements per Post
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 20 }}>
          <div className="comp-text1">Competitor Instagram Avg</div>
          <div className="comp-text22">{instaEngagementPerPost.toLocaleString()}</div>
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 10 }}>
          <div className="comp-text1">Competitor Facebook Avg</div>
          <div className="comp-text22">{fbEngagementPerPost.toLocaleString()}</div>
        </div>
      </div>
    </div>
    <div className="chart_section">
      <div className="chart_heading d-flex flex-column px-3 py-3">
        <label className="label_head">Publishing Behavior</label>
        <label className="label_sub_head">
          View the different types of posts you and your competitors published during the selected time period.
        </label>
      </div>

      <div style={{ marginLeft: 20, fontSize: 12, marginTop: 20 }}>Published Posts Average</div>

      <div style={{ height: "40vh", paddingLeft: "30px", paddingRight: "30px", paddingTop: "40px" }}>
        <Line options={options} data={data} />
      </div>

      {/* Table Section */}
      <table className="table-compbox">
        <tbody>
          <tr>
            <th className="comp-th1">Twitter Published Posts</th>
            <th className="comp-th2">Published Twitter</th>
          </tr>
          <tr>
            <td className="comp-th3">Your Average</td>
            <td className="comp-th4">114.40</td>
          </tr>
          <tr>
            <td className="comp-th3">Competitor Average</td>
            <td className="comp-th4">{twitterPosts.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>

      <div className="comp-center">Followers</div>

      <table className="table-compbox">
        <tbody>
          <tr>
            <th className="comp-th1">Facebook Published Posts</th>
            <th className="comp-th2">Published Posts</th>
          </tr>
          <tr>
            <td className="comp-th3">Your Average</td>
            <td className="comp-th4">114.40</td>
          </tr>
          <tr>
            <td className="comp-th3">Competitor Average</td>
            <td className="comp-th4">{fbPosts.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>

      <div className="comp-center">Followers</div>

      <table className="table-compbox">
        <tbody>
          <tr>
            <th className="comp-th1">Instagram Published Posts & Reels</th>
            <th className="comp-th2">Published Posts & Reels</th>
          </tr>
          <tr>
            <td className="comp-th3">Your Average</td>
            <td className="comp-th4">114.40</td>
          </tr>
          <tr>
            <td className="comp-th3">Competitor Average</td>
            <td className="comp-th4">{instaPosts.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>

      <div className="comp-center">Followers</div>
    </div>
    </div>
  );
}
