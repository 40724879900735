import React, { useState, useEffect } from 'react'
import DummyPreview from './DummyPreview';

export default function Pinerest({ Previewimages, Previewcaptions, PostType }) {
    const [open, setOpen] = useState(true);
    const [Images, setImages] = useState()

    useEffect(() => {
        setImages(Previewimages)
    }, [Previewimages, PostType, Previewcaptions]);

    if (Previewimages.length === 0 && Previewcaptions === '') {
        return (
            <DummyPreview />
        )
    }
    else
    return (
        <div className='social_preview_box w-100'>
            <button onClick={() => { setOpen(!open) }} className='my-3 toggle_btn d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <i class="fa-brands fa-pinterest fa-lg mx-2" style={{ color: '#d62976  ' }}></i>
                    <label className='social_name'>
                        Pinterest
                    </label>
                </div>

                <div>
                    <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                </div>
            </button>
            {
                open
                    ?
                    <div className='piterest_modal_section'>
                        <div className='d-flex row justify-content-center w-100 multiple_img'>
                            <div>
                                <img src={Previewimages[0]} className="previewImgs pinterest_imgs" />
                            </div>
                        </div>

                        <div className='pinterest_cation'>
                            {
                                Previewcaptions !== null
                                    ?
                                    Previewcaptions
                                    :
                                    null
                            }
                        </div>
                    </div>
                    :
                    null
            }



        </div>
    )
}
