// src/components/PostReport.js
import React, { useState, useMemo, useCallback, useEffect } from 'react';

import Pagination from './Pagination';
import Images from '../Comman Components/Images';
import PostEngagementDetails from './Post/PostEngagment';
import { fetchInstagramEngagment } from '../services/group';

const POSTS_PER_PAGE = 5;

const PostCard = ({ Posted, setShowpreview, setPreviewType, setPreviewUrl }) => {
  const [CurrentPage, setCurrentPage] = useState(1);

  const TotalPages = useMemo(
    () => Math.ceil((Posted?.length || 0) / POSTS_PER_PAGE),
    [Posted]
  );

  const handleMediaClick = useCallback(
    (mediaUrl, mediaType) => {
      setPreviewUrl(mediaUrl);
      setPreviewType(mediaType);
      setShowpreview(true);
    },
    [setPreviewUrl, setPreviewType, setShowpreview]
  );

  const renderMediaButton = useCallback(
    (mediaUrl, mediaType) => (
      <button
        className="w-100 view_image_btn"
        onClick={() => handleMediaClick(mediaUrl, mediaType)}
      >
        {mediaType === 'image' ? (
          <img src={mediaUrl} className="post_imgs" alt="Post media" />
        ) : (
          <video src={mediaUrl} className="post_imgs" controls />
        )}
      </button>
    ),
    [handleMediaClick]
  );

  const paginatedPosts = useMemo(() => {
    const startIdx = (CurrentPage - 1) * POSTS_PER_PAGE;
    return Posted?.slice(startIdx, startIdx + POSTS_PER_PAGE) || [];
  }, [Posted, CurrentPage]);

  const renderPosts = useCallback(() => {
    if (!paginatedPosts.length) {
      return (
        <div className="text-center mt-5">
          <p className="text-gray-500">No posts available</p>
        </div>
      );
    }

    return paginatedPosts.map((elem, index) => 
       <>
         <div key={index} className="socials uploaded_posts instagram mt-2 mb-2 mx-2 box_size  ">
        <div className="d-flex align-items-center post_username pb-2">
          <div>
            {elem.platform === 'facebook' && (
              <img
                src={`https://graph.facebook.com/${elem.from.id}/picture?return_ssl_resources=true`}
                className="user_profile_image"
                alt="Facebook profile"
              />
            )}

            {elem.platform === 'instagram' && <Images Id={elem?.owner?.id} />}
          </div>

          <div className="d-flex flex-column mx-2">
            <div className="d-flex align-items-center w-100">
              <i className={`fa-brands fa-${elem.platform}`}></i>
              <span className="username">
                {elem.platform === 'facebook' && elem.from.name}
                {elem.platform === 'instagram' && elem.username}
                {elem.platform === 'pinterest' && elem.board_owner?.username}
              </span>
            </div>
            <div>
              <span className="post_date">
                {elem.created_time?.split('T')[0] || elem.timestamp?.split('T')[0]}
              </span>
            </div>
          </div>
        </div>

        <div>
          <span
            className="post_caption mt-2"
            title={elem.message || elem.caption || 'No caption'}
          >
            {elem.message || elem.caption}
          </span>

          <div>
            {elem.platform === 'facebook' && elem.attachments?.data[0] &&
              renderMediaButton(
                elem.attachments.data[0].media.image.src,
                elem.attachments.data[0].type === 'photo' ? 'image' : 'video'
              )}

            {elem.platform === 'instagram' &&
              renderMediaButton(
                elem.media_url,
                elem.media_type === 'IMAGE' ? 'image' : 'video'
              )}

            {elem.platform === 'pinterest' &&
              renderMediaButton(
                elem.media?.images['150x150']?.url || elem.media_url,
                elem.media?.media_type === 'image' ? 'image' : 'video'
              )}
          </div>

          <div>
        
          <PostEngagementDetails
        elem={elem}
     
      />
           
          </div>
        </div>
      </div>
      </>
        

    );
  }, [paginatedPosts, renderMediaButton]);

  return (
    <>

      <div className=" m-2 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 2xl:gap-4">
        {renderPosts()}
      </div>

      {TotalPages > 1 && (
        <div className="flex justify-center my-4">
          <Pagination
            CurrentPage={CurrentPage}
            setCurrentPage={setCurrentPage}
            TotalPages={TotalPages}
          />
        </div>
      )}
    </>
  );
};

export default PostCard;

// const InstagramPost = ({ elem }) => {
//     const [postDetails, setPostDetails] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
  
//     useEffect(() => {
//       const fetchPostEngagement = async () => {
//         try {
//           setLoading(true);
//           const response = await fetchInstagramEngagment({
//             insta_id:elem.owner?.id, media_id:elem.id},'instagram');
//           console.log(response);
//           setPostDetails(response);
//         } catch (err) {
//           console.error(err);
//           setError('Failed to fetch engagement details.');
//         } finally {
//           setLoading(false);
//         }
//       };
  
//       fetchPostEngagement();
//     }, [elem]);
  
//     // ⚡️ useMemo to optimize media rendering
//     const renderedMedia = useMemo(() => {
//       if (!postDetails) return null;
//       const { media_url, media_type } = postDetails.post;
  
//       return media_type === 'IMAGE' ? (
//         <img src={media_url} alt="Post media" className="post_image" />
//       ) : (
//         <video controls className="post_video">
//           <source src={media_url} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       );
//     }, [postDetails]);
  
//     // ⚡️ useMemo for total engagements calculation
//     const totalEngagements = useMemo(() => {
//       if (!postDetails) return 0;
//       return (postDetails.post.likes || 0) + (postDetails.post.comments_count || 0);
//     }, [postDetails]);
  
//   console.log(postDetails,'postDetailspostDetailspostDetailspostDetails')
//     return (
//       <div className="socials uploaded_posts instagram mt-5 mx-2 box_size">
//         <div className="d-flex align-items-center post_username">
//           <div>
//             <img
//               src={postDetails?.user.profile_picture_url}
//               className="user_profile_image"
//               alt="Instagram profile"
//             />
//           </div>
  
//           <div className="d-flex flex-column align-items-center mx-2">
//             <div className="d-flex align-items-center w-100">
//               <i className="fa-brands fa-instagram"></i>
//               <span className="username">{postDetails?.user.username}</span>
//             </div>
//             <div>
//               <span className="post_date">
//                 {postDetails?.post.timestamp?.split('T')[0]}
//               </span>
//             </div>
//           </div>
//         </div>
  
//         <div>
//           <span
//             className="post_caption"
//             title={postDetails?.post.caption || 'No caption'}
//           >
//             {postDetails?.post.caption || 'No caption'}
//           </span>
  
//           <div>{renderedMedia}</div>
  
//           <div className="post_engagement_details mt-2">
//             <table className="table post_details">
//               <tbody>
//                 <tr>
//                   <td>Public Engagements</td>
//                   <td>{totalEngagements}</td>
//                 </tr>
//                 <tr>
//                   <td>Likes</td>
//                   <td>{postDetails?.post.likes}</td>
//                 </tr>
//                 <tr>
//                   <td>Comments</td>
//                   <td>{postDetails?.post.comments_count}</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   const FaceBookPost = ({ elem }) => {
//     const [postDetails, setPostDetails] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
  
//     useEffect(() => {
//       const fetchPostEngagement = async () => {
//         try {
//           setLoading(true);
//           console.log(elem)
//           const response = await fetchInstagramEngagment({
//             insta_id:elem.owner?.id, media_id:elem.id},'facebook');
//           console.log(response);
//         //  setPostDetails(response);
//         } catch (err) {
//           console.error(err);
//           setError('Failed to fetch engagement details.');
//         } finally {
//           setLoading(false);
//         }
//       };
  
//       fetchPostEngagement();
//     }, [elem]);
  
//     // ⚡️ useMemo to optimize media rendering
//     const renderedMedia = useMemo(() => {
//       if (!postDetails) return null;
//       const { media_url, media_type } = postDetails.post;
  
//       return media_type === 'IMAGE' ? (
//         <img src={media_url} alt="Post media" className="post_image" />
//       ) : (
//         <video controls className="post_video">
//           <source src={media_url} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       );
//     }, [postDetails]);
  
//     // ⚡️ useMemo for total engagements calculation
//     const totalEngagements = useMemo(() => {
//       if (!postDetails) return 0;
//       return (postDetails.post.likes || 0) + (postDetails.post.comments_count || 0);
//     }, [postDetails]);
  
//   console.log(postDetails,'postDetailspostDetailspostDetailspostDetails')
//     return (
//       <div className="socials uploaded_posts instagram mt-5 mx-2 box_size">
//         <div className="d-flex align-items-center post_username">
//           <div>
//             <img
//               src={postDetails?.user.profile_picture_url}
//               className="user_profile_image"
//               alt="Instagram profile"
//             />
//           </div>
  
//           <div className="d-flex flex-column align-items-center mx-2">
//             <div className="d-flex align-items-center w-100">
//               <i className="fa-brands fa-instagram"></i>
//               <span className="username">{postDetails?.user.username}</span>
//             </div>
//             <div>
//               <span className="post_date">
//                 {postDetails?.post.timestamp?.split('T')[0]}
//               </span>
//             </div>
//           </div>
//         </div>
  
//         <div>
//           <span
//             className="post_caption"
//             title={postDetails?.post.caption || 'No caption'}
//           >
//             {postDetails?.post.caption || 'No caption'}
//           </span>
  
//           <div>{renderedMedia}</div>
  
//           <div className="post_engagement_details mt-2">
//             <table className="table post_details">
//               <tbody>
//                 <tr>
//                   <td>Public Engagements</td>
//                   <td>{totalEngagements}</td>
//                 </tr>
//                 <tr>
//                   <td>Likes</td>
//                   <td>{postDetails?.post.likes}</td>
//                 </tr>
//                 <tr>
//                   <td>Comments</td>
//                   <td>{postDetails?.post.comments_count}</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     );
//   };