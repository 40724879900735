import React, { useState } from 'react';
import axios from 'axios';
import HeadLogo from '../Assets/Oolook_logo.png';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async () => {
        setIsLoading(true);
        setIsEmailSent(false);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_API}/user/forgot_password`,
                { email_id: email }
            );
            toast.success('Reset link sent! Please check your email.');
            setIsEmailSent(true);
            // Navigate to the confirmation screen
            navigate('/forgot-password-confirmation');
        } catch (err) {
            toast.error(err.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 shadow-lg rounded-lg bg-white max-w-sm w-full">
                <div className="flex justify-center mb-6">
                    <img src={HeadLogo} alt="Logo" className="Framelogo" />
                </div>
                <h2 className="text-2xl font-semibold text-center mb-4">Forgot Password</h2>
                <p className="text-sm text-gray-600 text-center mb-6">
                    Let's fix that. Provide us with the email address you use to log in to Oolook,
                    and we'll send you a link to reset your password.
                </p>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Email Address
                    </label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-2 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
                    />
                </div>
                <button
                    className="mt-6 w-full bg-blue-500 text-white py-2 rounded-md text-sm font-semibold hover:bg-blue-600 focus:outline-none disabled:opacity-50"
                    onClick={handleForgotPassword}
                    disabled={!email || isLoading}
                >
                    {isLoading ? 'Sending...' : 'Send Reset Link'}
                </button>
                {isEmailSent && (
                    <p className="text-blue-500 text-sm mt-4">
                        Please check your email for the reset link.
                    </p>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;
