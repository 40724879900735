import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { REACT_APP_BACKEND_API } from '../config';

export default function Header() {

    const [isLoading, setLoading] = useState(false);

    const ProfileSelector = useSelector((state) => {
        return state.Profiles
    })
    console.log(ProfileSelector?.[0]?.is_active_plan, 'ProfileSelectorProfileSelector');

    const handleCancelSubs = async () => {
        console.log('rnning');

        setLoading(true)
        try {
            const res = await fetch(
                `${REACT_APP_BACKEND_API}/api/payment/cancel-subscription`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        email_id: ProfileSelector[0]?.email_id,
                    }), // Change as needed
                }
            );

            const data = await res.json();

            if (data) {

                setLoading(false)
                localStorage.clear()
                sessionStorage.clear()
                window.location.reload('/');
            }
        } catch (error) {
            setLoading(false)

            console.error("Error creating subscription:", error);
        }
    };
    return (
        <div className='header d-flex'>
            <div className='welcome_section d-flex'>
                <img src={require('../Assets/Avtar.png')} className='avtar' />
                <div className='welcome_msg'>
                    <h4>Welcome, {" "}
                        {
                            ProfileSelector.length !== 0
                                ?
                                ProfileSelector[0].first_name
                                :
                                null
                        }
                    </h4>
                </div>
            </div>


            <div className='d-flex'>
                <div className='trial_msg'>
                    7 days remaining in trial
                </div>
                <button className='feature_btn btn'>
                    <img src={require('../Assets/star.png')} />
                    Trial more features
                </button>
                {ProfileSelector?.[0]?.is_active_plan &&

                    <button className='subscribe_btn btn' type='button' onClick={() => handleCancelSubs()}
                        disabled={isLoading}>
                        {isLoading ? 'Cancelling...' : 'Cancel my subscription'}
                    </button>
                }
            </div>
        </div>
    )
}
