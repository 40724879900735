import { Button, message } from "antd";
import React, { useEffect, useState } from "react";

import Images from "../../Comman Components/Images";
import PinImage from "../../Comman Components/Pinterest Componets/PinImage";
import UserName from "../../Comman Components/UserName";
import PinName from "../../Comman Components/Pinterest Componets/PinName";
import { useDispatch, useSelector } from "react-redux";
import { getPostDataService, importFileService } from "../../services/publish";

const ImportPostsModal = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [dateFormat, setDateFormat] = useState("US");
    const [SelectedPlatform, setSelectedPlatform] = useState(null);
    const [getPostData, setGetPostData] = useState(null);
    const [AccountFilter, setAccountFilter] = useState(false);
    const [AvaliableAccount, setAvaliableAccount] = useState(null);
    const [ImportExcel, setImportExcel] = useState(null);
    const [ImportOpen, setImportOpen] = useState(false);

    const [PlatformType, setPlatformType] = useState([
        "FACEBOOK",
        "INSTAGRAM",
        "PINTEREST",
    ]);

    const { groups, selectedGroup, profile } = useSelector(
        (state) => state.groups
    );
    const FilterData = [
        ["Type", ["Image", "Video"]],
        ["Profiles", AvaliableAccount],
        ["Platform", ["FACEBOOK", "INSTAGRAM", "PINTEREST"]],
    ];
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleDateFormatChange = (format) => {
        setDateFormat(format);
    };



    // download sample file
    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = "/Samplefile.xlsx"; // Replace with the actual file URL or path
        link.download = "Samplefiles.xlsx"; // The name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Import File
    const ImportFile = async () => {
        if (!ImportExcel?.length) {
            message.error("Please select a file to import");
            return;
        }

        const response = await importFileService(
            ImportExcel[0],
            selectedGroup,
            SelectedPlatform,
            PlatformType
        );

        if (response.success) {
            message.success("File Imported");
            setImportOpen(false);
        } else {
            console.error("Import Error:", response.message);
            message.error(response.message);
        }
    };

    // Account Filter
    const CheckDefaultSelect = (Account) => {
        if (Account.platform === "FACEBOOK") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_pageId === Account.platform_pageId) {
                    return true;
                }
            }
        } else {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_id === Account.platform_id) {
                    return true;
                }
            }
        }
    };

    const GetPostData = async () => {
        const response = await getPostDataService(PlatformType, SelectedPlatform);

        if (response.success) {
            setGetPostData(response.data);
        } else {
            console.error("Fetch Error:", response.message);
        }
    };

    // Get particular Groups
    const GetGroups = () => {
        setAvaliableAccount(profile);

        var selePlatform;

        selePlatform = profile?.slice(0);

        const indexToRemove = selePlatform.findIndex(
            (item) => item.platform === "PINTEREST"
        );

        // If a Pinterest object is found, remove it
        if (indexToRemove !== -1) {
            selePlatform.splice(indexToRemove, 1);
        }

        setSelectedPlatform(selePlatform);
    };

    useEffect(() => {
        GetGroups();
    }, []);

    const ChangePaltform = (value, checked) => {
        if (checked) {
            SelectedPlatform.push(value);
            GetPostData();
        } else {
            if (value.platform === "FACEBOOK") {
                SelectedPlatform?.map((item) => {
                    if (item.platform_pageId === value.platform_pageId) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        GetPostData();
                        // console.log(item.platform_id);
                    }
                });
            }
            if (value.platform === "INSTAGRAM") {
                SelectedPlatform?.map((item) => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        GetPostData();
                    }
                });
            }
            if (value.platform === "PINTEREST") {
                SelectedPlatform?.map((item) => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        GetPostData();
                    }
                });
            }
        }
    };

    return (
        <div className="mx-auto ">
            <h1 className="text-xl font-bold mb-4">Import Posts</h1>
            <hr className="mb-3" />

            <div className="flex gap-6  ">
                <div className=" flex-1 min-w-[400px] ">
                    <div className="mb-6">
                        <label className="block font-medium mb-2">Please select a profile</label>
                        <div >
                            <button
                                className="d-flex align-items-center justify-content-between select_filter"
                                onClick={() => {
                                    setAccountFilter(!AccountFilter);
                                }}
                            >
                                <div className="d-flex flex-column mx-2">
                                    <div className="filter_head">
                                        {FilterData[1][0]}
                                    </div>

                                    {/* Options */}
                                    {SelectedPlatform !== null ? (
                                        <div className="filter_selcted_option">
                                            {SelectedPlatform.length !== 1
                                                ? SelectedPlatform.length -
                                                1 +
                                                "+" +
                                                " Profiles"
                                                : SelectedPlatform}{" "}
                                        </div>
                                    ) : null}
                                </div>

                                <div>
                                    <i
                                        class={
                                            AccountFilter
                                                ? "fa fa-angle-up"
                                                : "fa fa-angle-down"
                                        }
                                    ></i>
                                </div>
                            </button>

                            {AccountFilter ? (
                                <div className=" bg-white border rounded-lg p-4 mt-2 max-h-40 overflow-y-auto">
                                    <ul>
                                        {AvaliableAccount?.map((elem, index) => {
                                            return (
                                                <li>
                                                    <div className="d-flex align-items-center justify-content-between py-2 ">
                                                        <div className="d-flex align-items-center w-100">
                                                            {elem.platform === "FACEBOOK" ? (
                                                                <img
                                                                    src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`}
                                                                    className="filter_profile_img"
                                                                />
                                                            ) : elem.platform === "INSTAGRAM" ? (
                                                                <>
                                                                    {<Images Id={elem.platform_id} />}
                                                                </>
                                                            ) : (
                                                                <PinImage Id={elem.platform_id} />
                                                            )}
                                                            {elem.platform === "FACEBOOK" ? (
                                                                <i
                                                                    class="fa-brands fa-facebook mx-2"
                                                                    style={{ color: "#1877F2" }}
                                                                ></i>
                                                            ) : elem.platform === "INSTAGRAM" ? (
                                                                <i
                                                                    class="fa-brands fa-instagram mx-2"
                                                                    style={{ color: "#E1306C" }}
                                                                ></i>
                                                            ) : elem.platform === "PINTEREST" ? (
                                                                <i
                                                                    class="fa-brands fa-pinterest mx-2"
                                                                    style={{ color: "#E60023" }}
                                                                ></i>
                                                            ) : null}
                                                            {elem.platform === "FACEBOOK" ? (
                                                                <>{elem.profile}</>
                                                            ) : elem.platform === "INSTAGRAM" ? (
                                                                <UserName Id={elem.platform_id} />
                                                            ) : (
                                                                <PinName Id={elem.platform_id} />
                                                            )}
                                                        </div>

                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked={CheckDefaultSelect(
                                                                    elem
                                                                )}
                                                                onChange={(e) => {
                                                                    ChangePaltform(
                                                                        elem,
                                                                        e.target.checked
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="block font-medium mb-2">Upload .CSV File</label>
                        <div className="border-dashed border-2 rounded-lg p-6 text-center">
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                className="hidden"
                                id="fileInput"
                            />
                            <label htmlFor="fileInput" className="cursor-pointer">
                                Drag .CSV file here or <span className="text-blue-500">Choose File</span>
                            </label>
                            {selectedFile && <p className="mt-2 text-sm text-gray-600">Selected File: {selectedFile.name}</p>}
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="block font-medium mb-2">Choose date format</label>
                        <div className="flex gap-4">
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="dateFormat"
                                    value="US"
                                    checked={dateFormat === "US"}
                                    onChange={() => handleDateFormatChange("US")}
                                    className="mr-2"
                                />
                                U.S. Format (mm/dd/yyyy)
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="dateFormat"
                                    value="International"
                                    checked={dateFormat === "International"}
                                    onChange={() => handleDateFormatChange("International")}
                                    className="mr-2"
                                />
                                International Format (dd/mm/yyyy)
                            </label>
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="block font-medium mb-2">Tags (optional)</label>
                        <input
                            type="text"
                            placeholder="Add tags"
                            className="w-full p-2 border rounded-md"
                        />
                    </div>

                    <div className="mb-6 flex items-center gap-4">
                        <label className="flex items-center">
                            <input type="checkbox" className="mr-2" />
                            Schedule posts as Drafts
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" className="mr-2" />
                            Shorten Link URLs with Bit.ly
                        </label>
                    </div>

                </div>
                {/* right div which consist information test detail like formatind.csv file */}

                <div className=" border-l-2 pl-6  max-w-[600px]">
                    <h1 className="text-xl font-bold mb-4"> Formatting Your .CSV File </h1>

                    <p className="py-2" ><b className="text-gray-600" >Date:</b> US (mm/dd/yyyy) or International (dd/mm/yyyy) format.</p>
                    <p className="py-2" ><b className="text-gray-600"  >Time:</b> 24-hour format (e.g., "13:40" for 1:30pm); imports for your Personal Settings time zone.</p>
                    <p className="py-2" ><b className="text-gray-600"  >Link URL (optional):</b> Share links without including them in the post text for Facebook, and Linkedin. Insert X links in the post text field.</p>
                    <p className="py-2" ><b className="text-gray-600"  >Public Image URL (optional):</b> Link to a publicly accessible image.</p>
                    <p className="py-2" ><b className="text-gray-600"  >Tags (optional):</b> Comma separated list of existing tags in Sprout for individual posts.</p>
                    <p className="py-2 mt-2" ><b   >Read the full guide</b></p>
                    <button
                        onClick={() => {
                            handleDownload();
                        }}
                        className="py-2" ><b   >Download sample template</b></button>

                </div>
            </div>
            <hr className="my-6" />
            <div className="flex justify-end gap-4 ">
                <Button className="bg-gray-200 text-gray-700" onClick={() => alert("Cancelled")}>Cancel</Button>
                <Button className="bg-blue-500 text-white" onClick={() => {
                    ImportFile();
                }}>Start Import</Button>
            </div>
        </div>
    );
};

export default ImportPostsModal;
