import React, { useState } from "react";
import { Link, useNavigate, useNavigation,  } from "react-router-dom";
import { toast } from "react-toastify";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import HeadLogo from "../Assets/Oolook_logo.png";
import { loginApi } from "../services/auth";

export default function Login() {
  const [details, setDetails] = useState({ email_id: "", password: "" });
  const [remember, setRemember] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const navigate = useNavigate()
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setDetails((prev) => ({ ...prev, [id]: value }));
  };

  const validateInputs = () => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    if (!details.email_id) {
      toast.error("Enter Email Address");
      return false;
    }
    if (!emailRegex.test(details.email_id)) {
      toast.error("Enter Valid Email Address");
      return false;
    }
    if (!details.password) {
      toast.error("Enter Password");
      return false;
    }
    return true;
  };

  const login = async () => {
    if (!validateInputs()) {
      setIsLoader(false);
      return;
    }
    setIsLoader(true);
    try {
      //  alert('hihihihih')
      const response = await loginApi({
        email_id: details.email_id,
        password: details.password,
      });
      if (response.error) {
        alert(response.message);
        return;
      }

      const { token, user } = response.data;
      const storage = localStorage

      storage.setItem("Email", details.email_id);
      storage.setItem("user_Token", token);

      const routes = {
        created: "/Onboarding",
        "onboarding completed": "/Organization",
        "organization completed": "/AssetsData",
        "assets completed": "/ProfileConnect",
      };
      if (user) {
        console.log(user, "useruseruseruser");
        navigate("/Dashboard");
       
        window.location.href = "/Dashboard"

      } else {
    //   navigate(routes[user.status] || "/Dashboard");
       window.location.href = routes[user.status] || "/Dashboard"

      }
    } catch (error) {
      message.error("Please Check Your Credential");
      console.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <div className="max-w-[400px] mx-auto flex justify-center items-center h-screen">
      <div className="p-6 shadow-lg rounded-lg bg-white">
        <div className="flex justify-center">
          <img src={HeadLogo} alt="Logo" className="Framelogo w-1/2" />
        </div>

        <div className="form-section">
          <div className="login-line text-center mb-6 font-medium text-lg">
            Welcome back, good to see you again!
          </div>

          <div className="text-sm mb-2 px-2 font-semibold text-gray-700">
            Enter Email
          </div>
          <input
            type="text"
            id="email_id"
            placeholder="Enter Email"
            className="w-[400px] h-[34px] px-3 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            onChange={handleInputChange}
          />

          <div className="text-sm mt-4 mb-2 px-2 font-semibold text-gray-700">
            Enter Password
          </div>
          <input
            type="password"
            id="password"
            placeholder="Enter Password"
            className="w-[400px] h-[34px] px-3 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            onChange={handleInputChange}
          />

          <div className="message my-4 flex justify-between items-center text-sm text-gray-600">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={remember}
                className="h-4 w-4"
                onChange={() => setRemember((prev) => !prev)}
              />
              <span>Remember me</span>
            </div>
            <Link
              className="text-blue-600 hover:underline"
              to="/forgot-password"
            >
              Forgot your password?
            </Link>
          </div>

          <div className="subbtn-div mt-4">
            <button
              className="w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 disabled:opacity-50"
              onClick={login}
              disabled={isLoader}
            >
              {isLoader ? <Spin indicator={antIcon} /> : "Log in"}
            </button>
          </div>

          <div className="flex items-center mt-6 justify-center text-sm">
            <span className="text-gray-600">Don't have an account?</span>
            <Link
              to="/signup"
              className="text-blue-600 font-bold hover:underline ml-1"
            >
              Sign up now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
