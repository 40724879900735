import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosServices from "../../services/axios";
 // Update with your axios services path


// Async thunk to fetch groups data
export const fetchGroups = createAsyncThunk(
  "groups/fetchGroups",
  async (_, { rejectWithValue }) => {
  try {
      const response = await axiosServices.get("/group/get", {
     //   headers: { Authorization: `Bearer ${token}` },
      });
      const groups = response.data.groups;

      if (!localStorage.getItem("oolook_default_group")) {
        localStorage.setItem("oolook_default_group", groups[0]?.name);
      }

      return groups;
    } catch (error) {
      console.error("Error fetching groups:", error);
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Async thunk to create a group
export const createGroup = createAsyncThunk(
  "groups/createGroup",
  async ({ groupName, connectedAccounts }, { rejectWithValue }) => {
  try {
      const response = await axiosServices.post(
        "/group/create",
        { groupName, connectedAccounts },
       // { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating group:", error);
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Async thunk to fetch details of a specific group


export const deleteGroupDetails = createAsyncThunk(
    "groups/deleteGroupDetails",
    async (platform_id, { rejectWithValue }) => {
    try {
        const response = await axiosServices.post(
          "/group/delete-profile",
          {  platform_id: platform_id, },
       //   { headers: { Authorization: `Bearer ${token}` } }
        );
  
       
        return response.data;
      } catch (error) {
        console.error("Error fetching group details:", error);
        return rejectWithValue(error.response?.data || "An error occurred");
      }
    }
  );
  export const selectGroupAndFetchDetails = createAsyncThunk(
    "groups/selectGroupAndFetchDetails",
    async (groupName, { dispatch, rejectWithValue }) => {
      try {
        localStorage.setItem("selected_group", groupName);
  
        // Dispatch fetchGroupDetails to get group data
        const response = await dispatch(fetchGroupDetails(groupName)).unwrap();
  
        return response; // Returning response in case it's needed
      } catch (error) {
        return rejectWithValue(error.response?.data || "An error occurred");
      }
    }
  );
// Group slice
export const fetchGroupDetails = createAsyncThunk(
  "groups/fetchGroupDetails",
  async (groupName, { rejectWithValue }) => {
  try {
      const response = await axiosServices.post(
        "/group/get-particular",
        { groupName },
     //   { headers: { Authorization: `Bearer ${token}` } }
      );

      localStorage.setItem("oolook_default_group", groupName);

      if (!localStorage.getItem("selected_group")) {
        localStorage.setItem("selected_group", groupName);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching group details:", error);
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);
const groupSlice = createSlice({
  name: "groups",
  initialState: {
    groups: [],
    loading: false,
    selectedGroup: "",
    profile: [],
    error: null,
  },
  reducers: {
    clearGroups: (state) => {
      state.groups = [];
      state.error = null;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
      
      fetchGroupDetails(action.payload)
      localStorage.setItem("selected_group", action.payload);
    },
    setProfiles: (state, action) => {
      state.profile = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(selectGroupAndFetchDetails.fulfilled, (state, action) => {
        state.selectedGroup = action.meta.arg; // Set selected group
        localStorage.setItem("selected_group", action.meta.arg);
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch groups";
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.groups.push(action.payload);
      })
      .addCase(fetchGroupDetails.fulfilled, (state, action) => {
        
        const accounts = action.payload.group.connected_accounts;
      
        const uniqueAccounts = accounts.filter((account, index, self) => {
          // Determine the unique key based on platform
          let uniqueKey;
          if (account.platform === 'FACEBOOK') {
            uniqueKey = account.platform_pageId;
          } else if (account.platform === 'INSTAGRAM') {
            uniqueKey = account.platform_id; // Use `platformid` if your property name is different.
          } else {
            uniqueKey = account.user_id;
          }
      
          // Find the index of the first occurrence of this unique key.
          const firstIndex = self.findIndex(acc => {
            let key;
            if (acc.platform === 'FACEBOOK') {
              key = acc.platform_pageId;
            } else if (acc.platform === 'INSTAGRAM') {
              key = acc.platform_id;
            } else {
              key = acc.user_id;
            }
            return key === uniqueKey;
          });
      
          return index === firstIndex;
        });
      
        state.profile = uniqueAccounts;
      })
      
      
      .addCase(deleteGroupDetails.fulfilled, (state, action) => {
        fetchGroups()
      });
      
  },
});

export const { clearGroups, setSelectedGroup,setProfiles } = groupSlice.actions;

export default groupSlice.reducer;

// Persist state with localStorage
const persistState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("groupsState", serializedState);
  } catch (error) {
    console.error("Error saving state to localStorage:", error);
  }
};

const loadPersistedState = () => {
  try {
    const serializedState = localStorage.getItem("groupsState");
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error("Error loading state from localStorage:", error);
    return undefined;
  }
};

// Load persisted state on initialization
const persistedState = loadPersistedState();

export { persistState, persistedState };
