import { useEffect, useState } from "react";
import { fetchUserProfile, fetchUserProfiles } from "../services/user.profile";
import { ProfileRedu } from "../store/Slices/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { REACT_APP_BACKEND_API } from "../config";

const BuySubscription = ({ setCurrentStep }) => {
  const ProfileSelector = useSelector((state) => state.Profiles);
  const isActivePlan = ProfileSelector[0]?.is_active_plan;
  const [subscriptionId, setSubscriptionId] = useState("");
  const dispatch = useDispatch();

  const handleSubBuy = async () => {
    const email_id = sessionStorage.getItem('Email');
    const firstName = sessionStorage.getItem('firstName'); // Assuming you also store this
    const contact = sessionStorage.getItem('contact'); // If you store the contact as well
    const razorpay_customer_id = sessionStorage.getItem('razorpay_customer_id');
    try {
      const res = await fetch(
        `${REACT_APP_BACKEND_API}/api/payment/create-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            planType: "extend-trial",
            email_id,
            firstName,
            contact,
            razorpay_customer_id
          }), // Change as needed
        }
      );

      const data = await res.json();
      setSubscriptionId(data.subscription.id);
    } catch (error) {
      console.error("Error creating subscription:", error);
    }
  };

  useEffect(() => {
    if (subscriptionId) {
      handlePayment();
    }
  }, [subscriptionId]);
  useEffect(() => {
     
    if (isActivePlan) {
      setCurrentStep(2)
    }
  }, [isActivePlan])

  const handlePayment = async () => {
    if (!window.Razorpay) {
      alert("Razorpay SDK not loaded. Please refresh and try again.");
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: subscriptionId,
      name: "Your App Name",
      description: "Monthly Subscription",
      handler: async (response) => {
        console.log("Payment Successful", response);
        alert("Subscription Activated!");
        const user = await fetchUserProfiles();
        dispatch(ProfileRedu(user));
        setCurrentStep(2)
        try {
          const user = await fetchUserProfile();
          dispatch(ProfileRedu(user));
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      },
      prefill: { email: ProfileSelector[0]?.email_id },
      theme: { color: "#3399cc" },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  return (
    <div className="subscription-container">
      <h1>Subscribe to Our Premium Plan</h1>
      <p>Get access to exclusive features for just ₹2499/month.</p>
      <button onClick={handleSubBuy} className="buy-button">
        Buy Subscription
      </button>
    </div>
  );
};

export default BuySubscription;
