import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StoreSideBar } from '../store/Slices/SIdeSlice';
import Logo from '../Assets/Icons/brandlogo.png';
import Dash from '../Assets/Dash_icon.png';
import inbox from '../Assets/inbox.png';
import publishing from '../Assets/publishing.png';
import reports from '../Assets/reports.png';
import Assets from '../Assets/Cloud.png';
import Profile from '../Assets/profile.png';
import { Modal } from 'antd'
import ConnectModal from './ConnectModal'
import { fetchGroups, selectGroupAndFetchDetails } from '../store/Slices/GroupSlice';


export default function Sidebar() {
  const selector = useSelector((state) => state.SideBarName[0]);
  const [showGroups, setShowGroups] = useState(false);

  const [dropdowns, setDropdowns] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const { groups, selectedGroup } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(fetchGroups());

  }, [dispatch]);
  useEffect(() => {
    if (!selectedGroup) {

      dispatch(selectGroupAndFetchDetails(groups[0]?.name));
    }
  }, [groups]);


  const toggleDropdown = (name) => {
    setDropdowns((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  const sidebarItems = [
    { name: 'Dashboard', icon: Dash, path: '/Dashboard' },
    // {
    //   name: 'Inbox',
    //   icon: inbox,
    //   subItems: [
    //     { name: 'All Messages', path: 'Inbox' },
    //     { name: 'Completed', path: 'Inbox' },

    //   ],
    // },
    {
      name: 'Reports', icon: reports, path: '/Reports',
      subItems: [
        { name: 'Profile Reports', path: 'Reports' },
        { name: 'Post Reports', path: 'Postreports' },
        { name: 'Competitor Reports', path: 'Competitor' },
      ],
    },
    {
      name: 'Publishing', icon: publishing, path: '/Publishing',
      subItems: [
        { name: 'Calender ', path: 'Publishing' },
        { name: 'Queue', path: 'Approval' },
        // { name: 'Rejected', path: 'Rejected' },
      ],
    },
    {
      name: 'Assets', icon: Assets, path: '/Library',
      subItems: [
        { name: 'Business Profile', path: 'Businessprofile' },
        { name: 'Branding', path: 'Branding' },
        { name: 'Topics', path: 'Topics' },
        { name: 'Media', path: 'Assets' },
        { name: 'Profiles', path: 'Profile' },
        { name: 'Groups Profile', path: 'GroupsProfile' },

      ],
    },
    {
      name: 'Profile',
      icon: Profile,
      path: '/Profile',
      onClick: () => setIsModalOpen(true)  // Trigger modal open on click
    },

  ];
  const logOut = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload('/');
  };







  return (
    <>
      <div className="w-64 bg-[#273333] text-white h-screen flex flex-col">
        {/* Sidebar Header */}
        <img src={Logo} alt="Logo" className="
        object-contain
        h-16
        w-56
        p-2
        border-b border-gray-700
        " />
        {/* <div
          className="flex items-center justify-between p-3 bg-gray-800 rounded-lg cursor-pointer"
          onClick={() => setShowGroups((prev) => !prev)}
        >
          <button
            className="text-gray-400 hover:text-white transition-all duration-200"
            title="Select Business Profile"
          >
            <i className="fa fa-folder fa-lg mr-1"></i>
          </button>
          <h3 className="text-white text-lg font-semibold">Select Business Profile</h3>
        </div> */}

        <ul className="mt-4">
          <li>
            <div>
              <div
                className={`flex items-center px-4 py-2 rounded-lg cursor-pointer ${localStorage.getItem('oolook_default_group')
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                onClick={() => setShowGroups((prev) => !prev)}
              >
                <span className="flex-grow">
                  {localStorage.getItem('oolook_default_group') !== 'No Group Selected' ? (
                    <div className="flex items-center justify-between ">
                      {localStorage.getItem('oolook_default_group')}
                      <i className="fa fa-chevron-down mr-2"></i>

                    </div>
                  ) : (
                    'Click to select a group'
                  )}
                </span>
              </div>
            </div>
          </li>
        </ul>

        {/* Sidebar Menu */}
        <ul className="flex-grow space-y-2 mt-6">
          {sidebarItems.map((item, index) => (
            <li key={index}>
              <div>
                <div
                  className={`flex items-center px-4 py-2 rounded-lg hover:bg-gray-700 cursor-pointer ${selector === item.name ? 'bg-gray-700' : ''}`}
                  onClick={() => {
                    if (item.name === 'Dashboard') {
                      navigate(item.path);  // Programmatically navigate to Dashboard
                    } else if (item.subItems) {
                      toggleDropdown(item.name);
                    } else if (item.onClick) {
                      item.onClick();
                    }
                    else {
                      dispatch(StoreSideBar(item.name));
                    }
                  }}
                >
                  <img src={item.icon} alt={`${item.name} Icon`} className="h-4 w-4 mr-4" />
                  <span>{item.name}</span>
                  {item.subItems && (
                    <i className={`fa fa-chevron-down ml-auto transform ${dropdowns[item.name] ? 'rotate-180' : ''}`} />
                  )}
                </div>

                {item.subItems && dropdowns[item.name] && (
                  <ul className="ml-8 mt-2 space-y-2">
                    {item.subItems.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          onClick={() => dispatch(StoreSideBar(subItem.name))}
                          className="block px-4 py-2 rounded-lg hover:bg-gray-600  "
                        >
                          {subItem.name}


                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ul>




        {/* Sidebar Footer */}
        <div className="p-2 border-t border-gray-700 relative">
          <button
            className="flex items-center w-full px-2 py-2 rounded-lg hover:bg-gray-700"
            onClick={() => setShowSubMenu((prev) => !prev)}
          >
            <i className="fa fa-cog h-4 w-4 mr-4"></i>
            <span>Settings</span>
            <i className={`fa fa-chevron-${showSubMenu ? 'up' : 'down'} ml-auto`}></i>
          </button>

          {/* Dropdown Menu */}
          {showSubMenu && (
            <ul className="mt-2 bg-gray-800 text-white rounded-lg shadow-lg absolute left-0 right-4 z-10 bottom-full w-full">
              <li className="border-b border-gray-600">
                <Link
                  to="https://oolook.in/privacy-policy-2/"
                  className="block px-4 py-2 hover:bg-gray-700 rounded-t-lg"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="border-b border-gray-600">
                <Link
                  to="AccountSetting"
                  className="block px-4 py-2 hover:bg-gray-700"
                >
                  Account Billing
                </Link>
              </li>
              <li>
                <button
                  onClick={() => logOut()}
                  className="w-full text-left px-4 py-2 hover:bg-gray-700 rounded-b-lg"
                >
                  Log Out
                </button>
              </li>
            </ul>
          )}
        </div>


      </div>
      {/* Group Modal */}
      {showGroups && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
          <div className="bg-white text-black rounded-lg p-4 w-96 z-[9000] ">
            <div className="flex justify-between items-center">
              <h4 className="font-bold">Select Business Profile</h4>


              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onClick={() => setShowGroups(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            {/* add business profile button */}
            <div className="flex justify-between items-center mt-4">
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex "
                onClick={() => navigate('GroupsProfile')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                Add New Business Profile
              </button>
            </div>
            <ul className="mt-4 space-y-2">
              {groups?.map((group, index) => (
                <li
                  key={index}

                  onClick={() => {
                    localStorage.setItem('oolook_default_group', group.name);
                    dispatch(selectGroupAndFetchDetails(group.name))
                    setShowGroups(false);

                  }}
                  className="p-2 cursor-pointer hover:bg-gray-200 rounded-lg
                  border border-gray-300
                  "
                >
                  {group.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <Modal title="Connect a Profile" open={isModalOpen} footer={null} onCancel={() => { setIsModalOpen(false); }}>
        <ConnectModal />
      </Modal>
    </>

  );
}