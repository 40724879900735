import React from 'react'
import FrameImg from '../Assets/Icons/frame.png'
import HeadLogo from '../Assets/Icons/sign-logo.png'

export default function Frame() {
  return (
    <div>
        <div className='row frame-part'>
            <div className='col-4 part-1'>
                <div className='img-div'>
                    <img src={HeadLogo} className='Framelogo'/>
                </div>

                <div className='line'>Branding & Visualisation?</div>
             

                <div className='lable-text'>Brand Name</div>
                <div className='input-div'>
                    <input type='text' className='input-field'></input>
                </div>

                <div className='lable-text'>Brand Address</div>
                <div className='input-div'>
                    <input type='text' className='input-field'></input>
                </div>

                <div className='lable-text'>Brand Phone</div>
                <div className='input-div'>
                    <input type='password' className='input-field'></input>
                </div>

                <div className='input-div1'>
                    <div className='lable-text1'>Brand Logo</div>
                    <div className='lable-text2'>Brand Logo</div>
                </div>

                <div className='input-div2'>
                    <input type='text' className='input-field1'></input>
                    <input type='text' className='input-field2'></input>
                </div>

                <div className='subbtn-div'>
                    <button className='submit-btn'>Sumbit</button>
                </div>

                <div className='setting'>Terms of Service  .  Privacy Policy  .  Security</div>
            </div>

            <div className='col-8 part-2'>
                <div>
                    <img src={FrameImg} className='frame-img'/>
                </div>
            </div>

        </div>
    </div>
  )
}
