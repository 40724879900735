
import axiosServices from './axios';

export const getGroupAnalytics = async ( groupName, since, until) => {
    try {
        const response = await axiosServices.post(
            `${process.env.REACT_APP_BACKEND_API}/group/group-analytics`,
            {
                groupName,
                selectedProfiles: [],
                since: since.toISOString().split('T')[0],
                until: until.toISOString().split('T')[0],
            },
         
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching group analytics:', error);
        throw error;
    }
};

export const processFBData = (FBImpression) => {
    let FBImpressions = [];
    if (FBImpression.length > 1) {
        FBImpressions = FBImpression[0].concat(FBImpression[1]);
    }
    return FBImpressions.reduce((acc, item) => acc + item.value, 0);
};
