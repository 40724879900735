import React from 'react'

export default function Billing() {
  return (
    <div>
      
      <div className='bill-box'>
        <div className='bill-head'>Business Information</div>
        <hr/>

        <div className='bill-text'>Brand Name</div>
        <div className='field-div'>
          <input type='text' className='bill-field'></input>
        </div>
        <hr/>
        
        <div className='bill-text'>Brand Address</div>
        <div className='field-div'>
          <input type='text' className='bill-field'></input>
        </div>
        <hr/>
          
        <div className='bill-text'>Brand Phone</div>
        <div className='field-div'>
          <input type='text' className='bill-field'></input>
        </div>
        <hr/>

        <div className='short-lable'>
          <div className='short-lable1'>Brand Logo</div>
          <div className='short-lable2'>Brand Colors</div>
        </div>

        <div className='short-field'>
          <input type='text' className='short-field1'></input>
          <input type='text' className='short-field2'></input>
        </div>
        <hr/>
      
      </div>
    </div>
  )
}
