import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Images from '../Comman Components/Images';
import PinImage from '../Comman Components/Pinterest Componets/PinImage';
import { fetchDrafts } from '../services/group';
import { useSelector } from 'react-redux';

export default function Approval() {
    const navigate = useNavigate();
    const [draftData, setDraftData] = useState([]);
  const { selectedGroup } = useSelector(
    (state) => state.groups
  );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchDrafts(selectedGroup);
                console.log(res);
                setDraftData(res || []);
            } catch (error) {
                console.error('Error fetching drafts:', error);
            }
        };
        fetchData();
    }, [selectedGroup]);

    const Post = (data) => {
        navigate('/Dashboard/PublishPost', { state: { PublishData: data } });
    };

    return (
        <div>
            <div className='approval_header'>
                <h4>Need Approval</h4>
            </div>

            <table className='filter_table'>
                <tbody>
                    <tr>
                        <td>
                            <label>Draft Type</label>
                            <select>
                                <option>Viewing all</option>
                            </select>
                        </td>
                        <td>
                            <label>Profiles</label>
                            <select>
                                <option>Viewing all</option>
                            </select>
                        </td>
                        <td>
                            <label>Tags</label>
                            <select>
                                <option>Viewing all</option>
                            </select>
                        </td>
                        <td>
                            <label>Authors</label>
                            <select>
                                <option>Viewing all</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className='sort_menu'>
                <label>Sort by</label>
                <select>
                    <option>Last Modified Newest</option>
                </select>
            </div>

            {/* Display Drafts */}
            {draftData.length > 0 ? (
                draftData.map((draft) => (
                    <div key={draft._id} className='post_section'>
                        <div className='post_header align-items-center'>
                            <div className='d-flex align-items-center w-75'>
                                <div>
                                    {draft.platforms.includes("FACEBOOK") ? (
                                        <img
                                            src={`https://graph.facebook.com/${draft.selectedProfiles[0]}/picture?return_ssl_resources=true`}
                                            className='filter_profile_img'
                                            alt="Profile"
                                        />
                                    ) : draft.platforms.includes("INSTAGRAM") ? (
                                        <Images Id={draft.selectedProfiles[0]} />
                                    ) : (
                                        <PinImage Id={draft.selectedProfiles[0]} />
                                    )}
                                </div>

                                <div style={{ width: '70%', marginLeft: '20px' }}>
                                    {draft.platforms.map((platform) => (
                                        <i key={platform} className={`fa-brands fa-${platform.toLowerCase()}`} style={{ marginRight: '10px' }}></i>
                                    ))}
                                    <label>{draft.groupName}</label>
                                    <label className='status'>{draft.isDraft ? "Draft" : "Scheduled"}</label>
                                </div>
                            </div>

                            <div className='date_section'>
                                <label>
                                    {new Date(draft.scheduledTime).toISOString().split("T")[0]}
                                </label>
                            </div>
                        </div>

                        <div className='post_details'>
                            <p>{draft.message}</p>
                            {draft.media_url && (
                                <div className='post_photo'>
                                    {/* <img src="https://dummyimage.com/600x400/000/fff" alt="Default Placeholder" /> */}
                                    <img src={draft.media_url} alt="Draft Media" />
                                </div>
                            )}
                        </div>

                        <div className='post_footer d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <img src={require('../Assets/Postfooter.png')} alt="Footer" />
                                <label>by Author</label>
                            </div>

                            <div>
                                <button className='btn' onClick={() => Post(draft)}>
                                    <i className="fa-solid fa-share"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No drafts available</p>
            )}
        </div>
    );
}
