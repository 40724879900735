import { createSlice } from "@reduxjs/toolkit";

const UntilDateSlice=createSlice({
    name:"UntilDateSlice",
    initialState:[],
    reducers:{
        ChangeUntilDate(state,action){
            state.length=0
            state.push(action.payload)
        }
    }
})

export {UntilDateSlice}

export const  {ChangeUntilDate}=UntilDateSlice.actions;