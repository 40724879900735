import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

// Import slices
import { DateSlice } from "./Slices/DateSlice";
import { UntilDateSlice } from "./Slices/UntilDateSlice";
import { SideSlice } from "./Slices/SIdeSlice";
import { ProfileSlice } from "./Slices/ProfileSlice";
import { FilterSlice } from "./Slices/FilterSlice";
import { AudienceSlice } from "./Slices/AudienceSlice";
import { ImpressionSlice } from "./Slices/ImpressionSlice";
import { EngagmentSlice } from "./Slices/EngagementSlice";
import { CountrySlice } from "./Slices/CountrySlice";
import { TimezoneSlice } from "./Slices/TimezoneSlice";
import onboardingReducer from "./Slices/OnboardingSlice";
import groupReducer from "./Slices/GroupSlice"; // Import group slice
import { AnalyticsSlice } from "./Slices/AnalyticsSlice";

// Persist configs
const onboardingPersistConfig = {
  key: "onboarding",
  storage,
};

const groupsPersistConfig = {
  key: "groups",
  storage,
};

export const onboardingPersistedReducer = persistReducer(onboardingPersistConfig, onboardingReducer);
export const groupsPersistedReducer = persistReducer(groupsPersistConfig, groupReducer);

const rootReducer = combineReducers({
  SinceDates: DateSlice.reducer,
  UntilDates: UntilDateSlice.reducer,
  SideBarName: SideSlice.reducer,
  Profiles: ProfileSlice.reducer,
  Filter: FilterSlice.reducer,
  AudienceGrow: AudienceSlice.reducer,
  Impression: ImpressionSlice.reducer,
  Engagement: EngagmentSlice.reducer,
  Country: CountrySlice.reducer,
  Timezone: TimezoneSlice.reducer,
  Analytics: AnalyticsSlice.reducer,
  

  onboarding: onboardingPersistedReducer, // Persisted onboarding reducer
  groups: groupsPersistedReducer, // Persisted groups reducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "groups"], // Add 'groups' to whitelist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
