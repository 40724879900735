import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadLogo from '../Assets/Oolook_logo.png';

const ResetPassword = () => {
    const { token } = useParams(); // Get the token from the URL
    const navigate = useNavigate(); // Initialize useNavigate
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Function to evaluate password strength
    const evaluatePasswordStrength = (password) => {
        const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const mediumRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;

        if (strongRegex.test(password)) {
            setPasswordStrength('Strong');
        } else if (mediumRegex.test(password)) {
            setPasswordStrength('Medium');
        } else {
            setPasswordStrength('Weak');
        }
    };

    // Password validation function
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleResetPassword = async () => {
        if (!isPasswordValid(newPassword)) {
            toast.error(
                'Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.'
            );
            return;
        }

        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/user/reset-password`, {
                token,
                newPassword,
            });

            toast.success(response.data.message || 'Password reset successfully. Redirecting to login...');
            setTimeout(() => {
                navigate('/'); // Navigate to login page
            }, 1500); // Delay for user to see success message
        } catch (err) {
            toast.error(err.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 shadow-lg rounded-lg bg-white max-w-sm w-full">
                <div className="flex justify-center mb-6">
                    <img src={HeadLogo} alt="Logo" className="Framelogo" />
                </div>
                <h2 className="text-2xl font-semibold text-center mb-4">Reset Password</h2>
                <p className="text-sm text-gray-600 text-center mb-6">
                    Enter a new password below to reset your account.
                </p>
                <div className="mb-4">
                    <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                        New Password
                    </label>
                    <input
                        type="password"
                        id="newPassword"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            evaluatePasswordStrength(e.target.value);
                        }}
                        className="w-full px-2 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
                    />
                    {newPassword && (
                        <div className="mt-2">
                            <p
                                className={`text-sm ${passwordStrength === 'Strong'
                                    ? 'text-green-500'
                                    : passwordStrength === 'Medium'
                                        ? 'text-yellow-500'
                                        : 'text-red-500'
                                    }`}
                            >
                                Strength: {passwordStrength}
                            </p>
                            <div className="h-2 w-full bg-gray-200 rounded-full mt-1">
                                <div
                                    className={`h-full rounded-full ${passwordStrength === 'Strong'
                                        ? 'bg-green-500 w-full'
                                        : passwordStrength === 'Medium'
                                            ? 'bg-yellow-500 w-2/3'
                                            : 'bg-red-500 w-1/3'
                                        }`}
                                ></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mb-4">
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full px-2 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
                    />
                </div>
                <button
                    className="mt-6 w-full bg-blue-500 text-white py-2 rounded-md text-sm font-semibold hover:bg-blue-600 focus:outline-none disabled:opacity-50"
                    onClick={handleResetPassword}
                    disabled={!newPassword || !confirmPassword || isLoading}
                >
                    {isLoading ? 'Resetting...' : 'Reset Password'}
                </button>
            </div>
        </div>
    );
};

export default ResetPassword;
