import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeadLogo from '../Assets/Oolook_logo.png';

const ForgotPasswordConfirmation = () => {
    const navigate = useNavigate();

    const handleResendEmail = () => {
        // Logic to resend the email
        console.log('Resend email logic here.');
        navigate('/forgot-password');
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 shadow-lg rounded-lg bg-white max-w-md w-full">
                <div className="flex justify-center mb-6">
                    <img src={HeadLogo} alt="Logo" className="Framelogo" />
                </div>
                <h2 className="text-2xl font-semibold text-center mb-4">We Miss You Already</h2>
                <p className="text-sm text-gray-600 text-center mb-6">
                    We just sent you an email with instructions to get back into Oolook.
                    It should be arriving any moment.
                </p>
                <p className="text-sm text-gray-600 text-center mb-6">
                    Didn’t receive one? <button
                        className="text-blue-500 underline hover:text-blue-700 focus:outline-none"
                        onClick={handleResendEmail}>
                        Resend email
                    </button>
                </p>
                <button
                    className="mt-6 w-full bg-blue-500 text-white py-2 rounded-md text-sm font-semibold hover:bg-blue-600 focus:outline-none"
                    onClick={() => navigate('/')}
                >
                    Back to Home
                </button>
            </div>
        </div>
    );
};

export default ForgotPasswordConfirmation;
