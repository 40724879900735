import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import WebFont from "webfontloader";
import { uploadFileToS3, getGoogleFonts, generateUniqueID } from '../services/assets';
import { handleOnboarding } from '../services/user.profile';
import { useSelector } from 'react-redux';

export default function AssetsData({ dataOrganization, Mobile }) {
    // Get answers from Redux instead of props
    const onboardingState = useSelector((state) => state.onboarding);
    const answers = {
        Ans1: onboardingState.Ans1,
        Ans2: onboardingState.Ans2,
        Ans3: onboardingState.Ans3
    };

    const colorPalette = [
        ["#737272", "#E58888", "#36A769"],
        ["#643434", "#8888E5", "#3654A7"],
        ["#C56592", "#E588E3", "#85A736"],
        ["#36C490", "#1F2746", "#351BFC"],
        ["#AAFFAF", "#DEF6AA", "#A1C2B0"],
        ["#4CA9E7", "#194B36", "#FFFDC4"],
        ["#FFA0A0", "#EAEAEA", "#B2F0CE"],
        ["#FF3C3C", "#E58888", "#D1B3FF"]
    ];
    const selector = useSelector((state) => state);
    //   const navigate = useNavigate();
    // const [isLoader, setLoader] = useState(false);

    const LogoRef = useRef(null);
    const navigate = useNavigate();
    const headshotref = useRef(null);

    const [LogoProgess, setLogoProgess] = useState(0);
    const [opencolor, setOpenColor] = useState(false);
    const [openBgcolor, setOpenBgcolor] = useState(false);
    const [opentextcolor, setOpenTextcolor] = useState(false);
    const [showPalette, setShowpalette] = useState(false);
    const [FontList, setFontList] = useState(null);
    const [selectedFont, setSelectedFont] = useState();
    const [bodySelectedFont, setBodySelectedFont] = useState();
    const [showHeaderFont, setShowHeaderFont] = useState(false);
    const [showBodyFont, setShowbodyFont] = useState(false);

    const [Headshot, setHeadshot] = useState(null);
    const [LogoPreview, setLogoPeview] = useState();
    const [HeadFont, setHeadFont] = useState('');
    const [BodyFont, setBodyFont] = useState('');
    const [brandColor, setBrandcolor] = useState("#ffffff");
    const [bgColor, setBgColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#ffffff");
    const [uniqueID, setUniqueID] = useState();
    const [isLoader, setLoader] = useState(false);

    // Add new state for managing color picker visibility
    const [activeColorPicker, setActiveColorPicker] = useState(null);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    // useEffect(() => {
    //     console.log('AssetsData Component Props:', {
    //         answers,
    //         dataOrganization,
    //         Mobile
    //     });
    // }, [answers, dataOrganization, Mobile]);



    useEffect(() => {
        generateUniqueID(setUniqueID);
        getGoogleFonts(setFontList, setSelectedFont, setBodySelectedFont);
    }, []);

    useEffect(() => {
        if (selectedFont) {
            WebFont.load({
                google: {
                    families: [selectedFont, bodySelectedFont],
                },
            });
        }
    }, [selectedFont, bodySelectedFont]);

    const OrganizationDataSend = async (e) => {
        e.preventDefault();

        console.log('Starting OrganizationDataSend with data:', {
            answers,
            dataOrganization,
            Mobile,
            onboardingState
        });

        try {
            // Validate that answers exists and has the correct shape
            if (!onboardingState.Ans1 || !onboardingState.Ans2 || !onboardingState.Ans3) {
                console.error('Missing answer fields:', onboardingState);
                throw new Error('Please complete all questions first');
            }

            if (!dataOrganization || !dataOrganization.Brandname) {
                console.error('Missing organization data:', dataOrganization);
                throw new Error('Please complete organization details first');
            }

            if (!Mobile) {
                console.error('Missing mobile number');
                throw new Error('Please provide a mobile number');
            }

            setLoader(true);

            const onboardingData = {
                describes: onboardingState.Ans1,
                industry: onboardingState.Ans2,
                top_goal: onboardingState.Ans3,
                brand_name: dataOrganization.Brandname,
                brand_address: dataOrganization.Brandaddress,
                brand_contact: Mobile,
                brand_size: dataOrganization.Brandsize || '',
                website: dataOrganization.Brandwebsite || '',
                country: selector?.Country?.[0] || '',
                time_zone: selector?.Timezone?.[0] || '',
                LogoPreview: LogoPreview || '',
                brandColor: brandColor || '#ffffff',
                Headshot: Headshot || '',
                HeadFont: HeadFont || '',
                BodyFont: BodyFont || '',
                bgColor: bgColor || '#ffffff',
                textColor: textColor || '#ffffff',
                uniqueID: uniqueID || ''
            };

            console.log('Sending onboarding data:', onboardingData);

            const response = await handleOnboarding(onboardingData);

            if (!response || !response.success) {
                throw new Error(response?.data?.message || 'Onboarding request failed');
            }

            message.success("Onboarding completed successfully!");
//            navigate('/dashboard')

        } catch (error) {
            console.error("Error during onboarding:", error);
            message.error(error.message || "Failed to complete onboarding. Please try again.");
        } finally {
            navigate('/dashboard')
            setLoader(false);
        }
    };

    // Modified color selection handlers
    const handleColorChange = (color, type) => {
        switch (type) {
            case 'brand':
                setBrandcolor(color.hex);
                break;
            case 'background':
                setBgColor(color.hex);
                break;
            case 'text':
                setTextColor(color.hex);
                break;
        }
        setActiveColorPicker(null); // Close picker after selection
    };

    // Modified palette selection handler
    const handlePaletteSelection = (colors) => {
        setBrandcolor(colors[0]);
        setBgColor(colors[1]);
        setTextColor(colors[2]);
        setShowpalette(false); // Close palette after selection
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="w-100 max-w-xl my-4 ">
                <div className="text-start mb-4">
                    <h2 className="text-2xl font-bold text-gray-800">Your Brand</h2>
                    <p className="text-gray-600">Choose how your business presents itself. Oolook uses your brand to give a unique look to the posts it generates.</p>
                </div>

                <hr />

                <div className="d-flex justify-content-start gap-8 w-full mb-8">
                    <div className="flex flex-col gap-2">
                        <label className="input_label text-sm font-semibold">Logo</label>
                        <div className="logo_section rounded-lg hover:opacity-90 cursor-pointer transition-all"
                            onClick={() => LogoRef.current.click()}>
                            {LogoPreview ?
                                <img src={LogoPreview} className="object-contain w-[76px] h-[76px] rounded-lg" alt="Logo" />
                                : <i className="fa-solid fa-pencil text-white text-xl" />
                            }
                        </div>
                        <input type="file" accept="image/*" onChange={e => uploadFileToS3(e, setLogoPeview, setLogoProgess)}
                            className="hidden" ref={LogoRef} />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className="input_label text-sm font-semibold">Headshot</label>
                        <div className="logo_section rounded-lg hover:opacity-90 cursor-pointer transition-all"
                            onClick={() => headshotref.current.click()}>
                            {Headshot ?
                                <img src={Headshot} className="object-contain w-[76px] h-[76px] rounded-lg" alt="Headshot" />
                                : <i className="fa-solid fa-pencil text-white text-xl" />
                            }
                        </div>
                        <input type="file" accept="image/*" onChange={e => uploadFileToS3(e, setHeadshot)}
                            className="hidden" ref={headshotref} />
                    </div>
                </div>

                <div className="mb-8">
                    <label className="input_label text-sm font-semibold mb-4 block">Colors</label>
                    <div className="flex flex-wrap gap-4">
                        <button className="color_boxs hover:shadow-md transition-all"
                            onClick={() => setActiveColorPicker(activeColorPicker === 'brand' ? null : 'brand')}>
                            <span className="color_box" style={{ backgroundColor: brandColor }}></span>
                            <span className="color_label ml-2">Brand Color</span>
                        </button>
                        {activeColorPicker === 'brand' && (
                            <div className="color_picker shadow-lg">
                                <SketchPicker color={brandColor}
                                    onChangeComplete={(color) => handleColorChange(color, 'brand')} />
                            </div>
                        )}

                        <button className="color_boxs hover:shadow-md transition-all"
                            onClick={() => setActiveColorPicker(activeColorPicker === 'background' ? null : 'background')}>
                            <span className="color_box" style={{ backgroundColor: bgColor }}></span>
                            <span className="color_label ml-2">Background Color</span>
                        </button>
                        {activeColorPicker === 'background' && (
                            <div className="color_picker shadow-lg">
                                <SketchPicker color={bgColor}
                                    onChangeComplete={(color) => handleColorChange(color, 'background')} />
                            </div>
                        )}

                        <button className="color_boxs hover:shadow-md transition-all"
                            onClick={() => setActiveColorPicker(activeColorPicker === 'text' ? null : 'text')}>
                            <span className="color_box" style={{ backgroundColor: textColor }}></span>
                            <span className="color_label ml-2">Text Color</span>
                        </button>
                        {activeColorPicker === 'text' && (
                            <div className="color_picker shadow-lg">
                                <SketchPicker color={textColor}
                                    onChangeComplete={(color) => handleColorChange(color, 'text')} />
                            </div>
                        )}
                    </div>

                    <button className="add_palette_btn mt-4 hover:bg-gray-50 transition-all"
                        onClick={() => setShowpalette(!showPalette)}>
                        <span>Add a Palette</span>
                        <img className="w-4 h-4" alt="palette" src={require('../Assets/color_pallet.png')} />
                    </button>

                    {showPalette && (
                        <div className="palette_container mt-2">
                            <ul className="colorpalette_list shadow-lg">
                                {colorPalette.map((colors, index) => (
                                    <li key={index}>
                                        <button className="color_palette_box hover:bg-gray-50 transition-all w-full p-2"
                                            onClick={() => handlePaletteSelection(colors)}>
                                            <div className="flex gap-2">
                                                {colors.map((color, idx) => (
                                                    <span key={idx} className="color_box"
                                                        style={{ backgroundColor: color }}></span>
                                                ))}
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <div>
                    <label className="input_label">Font</label>
                    <label className="input_sub_label ml-2">Choose fonts to use on all your posts.</label>
                    <div className="flex flex-col">
                        <label className="font_head_label text-lg font-semibold mb-2">Header Font</label>
                        <div className="relative w-full">
                            <button
                                className="font_select_btn w-full py-2 px-4 bg-white border border-gray-300 rounded-md flex justify-between items-center cursor-pointer transition-all hover:bg-gray-100"
                                onClick={() => setShowHeaderFont(!showHeaderFont)}
                            >
                                <label className="text-base">{HeadFont || 'Select Header Font'}</label>
                                <i className="fa-solid fa-chevron-down"></i>
                            </button>
                            {showHeaderFont && (
                                <div className="absolute z-10 w-full bg-white shadow-lg border border-gray-300 rounded-md mt-1">
                                    {FontList && FontList.map((font, idx) => (
                                        <button
                                            key={idx}
                                            className="py-2 px-4 w-full text-left hover:bg-gray-100"
                                            onClick={() => { setHeadFont(font); setShowHeaderFont(false); }}
                                        >
                                            {font}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Body Font Section */}
                        <label className="font_head_label text-lg font-semibold mb-2 mt-4">Body Font</label>
                        <div className="relative w-full">
                            <button
                                className="font_select_btn w-full py-2 px-4 bg-white border border-gray-300 rounded-md flex justify-between items-center cursor-pointer transition-all hover:bg-gray-100"
                                onClick={() => setShowbodyFont(!showBodyFont)}
                            >
                                <label className="text-base">{BodyFont || 'Select Body Font'}</label>
                                <i className="fa-solid fa-chevron-down"></i>
                            </button>
                            {showBodyFont && (
                                <div className="absolute z-10 w-full bg-white shadow-lg border border-gray-300 rounded-md mt-1">
                                    {FontList && FontList.map((font, idx) => (
                                        <button
                                            key={idx}
                                            className="py-2 px-4 w-full text-left hover:bg-gray-100"
                                            onClick={() => { setBodyFont(font); setShowbodyFont(false); }}
                                        >
                                            {font}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <hr className="mt-6" />

                <div className="d-flex justify-content-between mt-3">
                    <button className="next_btn" onClick={OrganizationDataSend}>
                        {isLoader ? <Spin indicator={antIcon} /> : "Next"}
                    </button>
                </div>
            </div>
        </div>
    );
}
