export const TimeZone = [
    { "name": "UTC-12:00", "offset": "-12:00", "city": "Baker Island" },
    { "name": "UTC-11:00", "offset": "-11:00", "city": "Pago Pago" },
    { "name": "UTC-10:00", "offset": "-10:00", "city": "Honolulu" },
    { "name": "UTC-09:30", "offset": "-09:30", "city": "Marquesas Islands" },
    { "name": "UTC-09:00", "offset": "-09:00", "city": "Anchorage" },
    { "name": "UTC-08:00", "offset": "-08:00", "city": "Los Angeles" },
    { "name": "UTC-07:00", "offset": "-07:00", "city": "Denver" },
    { "name": "UTC-06:00", "offset": "-06:00", "city": "Chicago" },
    { "name": "UTC-05:00", "offset": "-05:00", "city": "New York" },
    { "name": "UTC-04:00", "offset": "-04:00", "city": "Santiago" },
    { "name": "UTC-03:30", "offset": "-03:30", "city": "St. John's" },
    { "name": "UTC-03:00", "offset": "-03:00", "city": "Buenos Aires" },
    { "name": "UTC-02:00", "offset": "-02:00", "city": "South Georgia" },
    { "name": "UTC-01:00", "offset": "-01:00", "city": "Azores" },
    { "name": "UTC+00:00", "offset": "+00:00", "city": "London" },
    { "name": "UTC+01:00", "offset": "+01:00", "city": "Berlin" },
    { "name": "UTC+02:00", "offset": "+02:00", "city": "Cairo" },
    { "name": "UTC+03:00", "offset": "+03:00", "city": "Moscow" },
    { "name": "UTC+03:30", "offset": "+03:30", "city": "Tehran" },
    { "name": "UTC+04:00", "offset": "+04:00", "city": "Dubai" },
    { "name": "UTC+04:30", "offset": "+04:30", "city": "Kabul" },
    { "name": "UTC+05:00", "offset": "+05:00", "city": "Karachi" },
    { "name": "UTC+05:30", "offset": "+05:30", "city": "New Delhi" },
    { "name": "UTC+05:45", "offset": "+05:45", "city": "Kathmandu" },
    { "name": "UTC+06:00", "offset": "+06:00", "city": "Dhaka" },
    { "name": "UTC+06:30", "offset": "+06:30", "city": "Yangon" },
    { "name": "UTC+07:00", "offset": "+07:00", "city": "Bangkok" },
    { "name": "UTC+08:00", "offset": "+08:00", "city": "Beijing" },
    { "name": "UTC+08:45", "offset": "+08:45", "city": "Eucla" },
    { "name": "UTC+09:00", "offset": "+09:00", "city": "Tokyo" },
    { "name": "UTC+09:30", "offset": "+09:30", "city": "Adelaide" },
    { "name": "UTC+10:00", "offset": "+10:00", "city": "Sydney" },
    { "name": "UTC+10:30", "offset": "+10:30", "city": "Lord Howe Island" },
    { "name": "UTC+11:00", "offset": "+11:00", "city": "Honiara" },
    { "name": "UTC+12:00", "offset": "+12:00", "city": "Auckland" },
    { "name": "UTC+12:45", "offset": "+12:45", "city": "Chatham Islands" },
    { "name": "UTC+13:00", "offset": "+13:00", "city": "Nuku'alofa" },
    { "name": "UTC+14:00", "offset": "+14:00", "city": "Kiritimati" }
]