const { REACT_APP_BACKEND_API } = require("../config");
const { default: axiosServices } = require("./axios");

export const loginApi = async (data) => {
  try {
    const response = await axiosServices.post(
      `${REACT_APP_BACKEND_API}/user/login/`,
      data
    );
    // setTermsAndConditionsAccepted(response.data)
    return {error:false,data:response?.data?.data};
  } catch (error) {
    return {error:true,message:error?.response?.data?.message|| error?.message};
  }
};

