import React, { useEffect, useState } from 'react';
import { fetchInstagramProfile } from '../services/group';

export default function Images({ Id }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const getInstagramDetails = async () => {
      try {
        const data = await fetchInstagramProfile(Id);
       // console.log("imagess", data);
        setImageUrl(data.profile_picture_url);
      } catch (error) {
        console.error('Failed to fetch image details:', error);
      }
    };
    getInstagramDetails();
  }, [Id]);

  return (
    imageUrl ? <img src={imageUrl} alt="Profile" className='post_header_img' /> : <p>Loading...</p>
  );
}
