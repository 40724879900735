import axios from 'axios';
import AWS from 'aws-sdk';
import { message } from 'antd';
import { REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_REGION } from '../config';
import axiosServices from './axios';

export const uploadFileToS3 = async (e, setFileUrl, setProgress) => {
    try {
        
        const file = e.target.files[0];
        if (!file) return;
        
        const s3 = new AWS.S3({
            accessKeyId: REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: REACT_APP_AWS_SECRET_KEY,
            region: REACT_APP_REGION
        });

        const fileName = `${Date.now()}_${file.name.replace(/\s+/g, '')}`;
        const params = {
            Bucket: 'assets-libraries',
            Key: fileName,
            Body: file
        };

        const upload = s3.upload(params, {
            partSize: 10 * 1024 * 1024,
            queueSize: 1
        });

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            setProgress(percentageProgress);
        });

        const uploadData = await upload.promise();
        setFileUrl(uploadData.Location);
    } catch (error) {
        message.error('Upload failed');
        console.error(error);
    }
};

export const getGoogleFonts = async (setFontList, setSelectedFont, setBodySelectedFont) => {
    try {
        const response = await axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT}`);
        const fontList = response.data.items.map(item => item.family);
        setFontList(fontList);
        setSelectedFont(fontList[0]);
        setBodySelectedFont(fontList[0]);
    } catch (error) {
        message.error('Failed to fetch fonts');
        console.error(error);
    }
};

export const uploadBrandAssets = async (data) => {
  //  const token = localStorage.getItem('user_Token') || sessionStorage.getItem('user_Token');
    try {
        await axiosServices.patch(`/onboarding/add-onboarding3`, data, 
        );
    } catch (error) {
        console.error(error);
        throw new Error('Error submitting brand assets');
    }
};

export const generateUniqueID = async (setUniqueID) => {
    try {
        const response = await axiosServices.get(`/unique/generate-id`);
        setUniqueID(response.data.id);
    } catch (error) {
        console.error(error);
        message.error('Error generating unique ID');
    }
};

export const updateUserStatus = async () => {
  //  const token = localStorage.getItem('user_Token') || sessionStorage.getItem('user_Token');
    try {
        await axiosServices.patch(`${process.env.REACT_APP_BACKEND_API}/user/update-status`, { status: 'completed' });
    } catch (error) {
        console.error(error);
        message.error('Error updating user status');
    }
};


 