import axiosServices from "./axios";

// Fetch user profile
export const fetchUserProfile = async () => {
  try {
    const response = await axiosServices.get(`/user/profile`);
  
    return response.data.user;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    // Throw a standardized error message for better handling in the UI
    throw new Error(
      error?.response?.data?.message || "Error fetching user profile."
    );
  }
};

// Fetch user profiles
export const fetchUserProfiles = async () => {
  try {
    const response = await axiosServices.get(`/profiles/get-profile`);
    return response.data.profiles;
  } catch (error) {
    console.error("Error fetching user profiles:", error);
    // Throw a standardized error message for better handling in the UI
    throw new Error(
      error?.response?.data?.message || "Error fetching user profiles."
    );
  }
};

// Delete user data
export const deleteUserData = async (storage) => {
  try {
    const response = await axiosServices.get(`/user/profile`);

    if (response.data.user.status !== "completed") {
      // Remove tokens and email based on storage type
      if (storage === "localStorage") {
        localStorage.removeItem("Email");
        localStorage.removeItem("user_Token");
      } else if (storage === "sessionStorage") {
        sessionStorage.removeItem("Email");
        sessionStorage.removeItem("user_Token");
      }
    }
  } catch (error) {
    console.error("Error deleting user data:", error);
    // Throw a standardized error message for better handling in the UI
    throw new Error(
      error?.response?.data?.message || "Error deleting user data."
    );
  }
};

// Service to fetch Facebook pages details
export const getPagesDetails = async (accessToken) => {
  try {
    const response = await axiosServices.post("/facebook/pages", {
      accessToken,
    });
    return response;
  } catch (error) {
    console.error("Error in getPagesDetails:", error);
    throw error;
  }
};

// Service to fetch user profile
export const getProfile = async () => {
  try {
    const response = await axiosServices.post(
      "/profiles/get-platform-profile",
      {
        platform: "FACEBOOK",
      }
    );
    return response;
  } catch (error) {
    console.error("Error in getProfile:", error);
    throw error;
  }
};

// Service for Facebook authentication callback
export const facebookCallback = async (code) => {
  try {
    const token = localStorage.getItem('user_Token') || sessionStorage.getItem('user_Token');
    console.log(token,'tokentokentokentoken')
    const response = await axiosServices.get("/facebook/auth/callback", {
      params: {
        code,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in facebookCallback:", error);
    throw error;
  }
};
export const instagramCallback = async (code) => {
  try {
   
    const response = await axiosServices.post(`${process.env.REACT_APP_BACKEND_API}/instagram/auth/callback`, {
     url:code,
    });
    return response;
  } catch (error) {
    console.error("Error in facebookCallback:", error);
    throw error;
  }
};

// Service to save profiles in a group
export const saveProfiles = async (groupName, selectedPages) => {
  try {
    const response = await axiosServices.post("/group/create", {
      groupName,
      connectedAccounts: selectedPages,
    });
    return response;
  } catch (error) {
    console.error("Error in saveProfiles:", error);
    throw error;
  }
};

// Service to save page details
export const savePageDetails = async (facebookACId, pageDetails) => {
  try {
    const response = await axiosServices.post("/profiles/update-page-details", {
      platform: "FACEBOOK",
      user_id: facebookACId,
      pageDetails,
    });
    return response;
  } catch (error) {
    console.error("Error in savePageDetails:", error);
    throw error;
  }
};
export const getGroups = async () => {
  // const token = getAuthToken();
  try {
    const response = await axiosServices.get(`/group/get`);
    return response.data.groups;
  } catch (error) {
    console.error("Error fetching groups:", error);
    throw new Error("Error fetching groups");
  }
};

export const handleOnboarding = async (data) => {
  try {
    // Step 1: Call the onboarding API
    console.log(data);
    await axiosServices.post("/onboarding/add-onboarding", {
      ...data,
    });

    // On successful completion, navigate to the organization page
    return { success: true };
  } catch (error) {
    console.error("Error in onboarding process:", error);
    return error.response
  }
};
export const createGroup = async (data) => {
  try {
    // Step 1: Call the onboarding API
    // console.log(data)
    await axiosServices.post(`/group/create`, {
      ...data
    });

    // On successful completion, navigate to the organization page
    return { success: true };
  } catch (error) {
    console.error("Error in onboarding process:", error);
    throw new Error(
      "An error occurred during the onboarding process. Please try again later."
    );
  }
};
