import React, { useEffect, useState } from 'react'
import Dropdown from './Filter Dropdown/Dropdown'
import axios from 'axios'
import { ChangeSinceDate } from '../store/Slices/DateSlice';
import { ChangeUntilDate } from '../store/Slices/UntilDateSlice';
import { useNavigate } from 'react-router-dom';
import Tagmenu from '../Comman Components/Tagmenu';
import Images from '../Comman Components/Images';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from 'antd';


export default function Inbox() {

    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/DD/MM';

    const d = new Date();
    var months = (d.getMonth() + 1).toString().padStart(2, "0");
    var day = d.getDate().toString().padStart(2, "0");

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    });

    const [PostData, setPostData] = useState(null);

    const [TagModal, setTagModal] = useState(false);

    const [tagComment, setTagComment] = useState(null);

    const [FilterTag, setFilterTag] = useState(false);

    const [searchTag, setSearchTag] = useState(null);

    const [Tags, setTags] = useState(null);


    const FilterData = [
        [
            "Sources", [
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Viewing All"
            ]
        ],
        [
            "Message Types", [
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Viewing All"
            ]
        ],
        [
            "More Filters", [
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Viewing All"
            ]
        ],
    ]

    const getPostComments = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        const since = new Date(Sinceselector[0] * 1000);
        const until = new Date(untilSelector[0] * 1000);


        axios.post(`${process.env.REACT_APP_BACKEND_API}/posts/get-filtered`, {
            groupName: localStorage.getItem("oolook_default_group"), // Replace with the group name you want to filter by
            startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            endDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0"),
            mediaType: "image", // Replace with your desired media type
            page: 1, // Replace with your desired page number
            pageSize: 100, // Replace with your desired page size
            platforms: ["FACEBOOK", "INSTAGRAM"] // Replace with platforms you want to fetch data from
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                var CommentData = [];
                (res.data.posts).forEach(element => {
                    if (element.comments) {
                        if (element.platform === "facebook") {
                            (element.comments.data).map((elem) => {
                                CommentData.push({
                                    "post_id": element.id,
                                    "caption": element.message,
                                    "time": element.created_time,
                                    "platform": element.platform,
                                    "user_name": element.from.name,
                                    "post_url": element.attachments.data[0].type === "photo" ? element.attachments.data[0].media.image.src : element.attachments.data[0].media.source,
                                    "comments": elem.message,
                                    "comment_time": elem.created_time,
                                    "Page_Id": element.from.id,
                                    "Total_like":element.likes.summary.total_count,
                                    "Total_comments":element.comments.summary.total_count
                                })
                            })
                        }
                        else if (element.platform === "instagram") {
                            (element.comments.data).map((elem) => {
                                CommentData.push({
                                    "post_id": element.id,
                                    "caption": element.caption,
                                    "time": element.timestamp,
                                    "platform": element.platform,
                                    "user_name": element.username,
                                    "post_url": element.media_type === "IMAGE" ? element.media_url : element.media_url,
                                    "comments": elem.text,
                                    "comment_time": elem.timestamp,
                                    "Page_Id": element.owner.id,
                                    "Total_like":element.like_count,
                                    "Total_comments":element.comments_count
                                })
                            })
                        }
                    }
                });

                const CommentSort = [...CommentData].sort((a, b) => new Date(b.comment_time) - new Date(a.comment_time));
                setPostData(CommentSort)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const ReplyPage = (commentData) => {
        navigate("/Dashboard/Message", { state: { data: commentData } })
    }

    // Get All Tag Names

    const getTags = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/tag/get-tagname`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setTags(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const FilterTags = () => {
        return (
            <div className='filter_list_option'>
                <div className='tag_header w-100 d-flex justify-content-between align-item-center'>
                    <span>
                        Tags
                    </span>

                    <div>
                        <button className='tag_remove_btn'>
                            Remove All
                        </button>
                    </div>
                </div>

                <div className='m-2 Filter_list'>

                    {
                        Tags !== null
                            ?
                            Tags.map((elem) => {
                                return (
                                    <div className='my-2'>
                                        <input type="checkbox" defaultChecked={searchTag === elem} className='mx-2' onClick={(e) => { if (e.target.checked === true) { setSearchTag(elem); GetComments(e, elem); } else { getPostComments(); setSearchTag(null) } }} />
                                        {elem}
                                    </div>
                                )
                            })
                            :
                            null
                    }

                    <div>

                    </div>
                </div>
            </div>
        )
    }

    const GetComments = (checked, Tagname) => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }
        if (checked.target.checked) {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/tag/get-comments`, {
                tag_name: Tagname
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    var CommentData = [];

                    (res.data).forEach((element) => {
                        CommentData.push({
                            "post_id": element.comment_id,
                            "platform": element.platform,
                            "user_name": element.username,
                            "post_url": element.post_url,
                            "comments": element.text,
                            "comment_time": element.date
                        })
                    })

                    const CommentSort = [...CommentData].sort((a, b) => new Date(b.comment_time) - new Date(a.comment_time));
                    setPostData(CommentSort)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    useEffect(() => {
        getPostComments()
        getTags()
    }, [Sinceselector,untilSelector])
    return (
        <div>
            {/* Header */}
            <div className='approval_header message_header align-items-center'>
                <h4>All Message</h4>


                <div>
                    <span className='Inbox_remaining'>
                        <i class="fa fa-calendar" style={{ marginRight: '5px' }} aria-hidden="true"></i>
                        <RangePicker
                            format={dateFormat}
                            style={{ backgroundColor: 'transparent', border: 'none' }}
                            defaultValue={[dayjs(d.getFullYear() + "/" + '01' + "/" + months, dateFormat), dayjs(d.getFullYear() + "/" + day + "/" + months, dateFormat)]}
                            onChange={(e) => { dispatch(ChangeSinceDate(Date.parse(e[0].$d) / 1000)); dispatch(ChangeUntilDate(Date.parse(e[1].$d) / 1000)); }} />
                    </span>

                    <span className='Inbox_remaining'>
                        <i class="fa fa-ellipsis-h"></i>
                    </span>

                    <span className='Inbox_remaining'>
                        <i class="fa fa-upload" style={{ marginRight: '5px' }}></i>
                        Export
                    </span>

                    <span className='Inbox_remaining Filter_btn'>
                        <img src={require('../Assets/Filter.png')} />
                        Filters
                    </span>
                </div>
            </div>

            {/* Filter table */}
            <div className='d-flex align-items-center justify-content-between filter_section w-100'>
                {/* <Dropdown FilterOption={FilterData[0]} />

                <Dropdown FilterOption={FilterData[1]} />

                <Dropdown FilterOption={FilterData[2]} /> */}

                <div className='w-25'>
                    <button className='Tag_filter' onClick={() => { setFilterTag(!FilterTag) }}>
                        <div>
                            <div className='filter_name'>
                                Tags
                            </div>

                            <div className='filter_selected_tags'>
                                {
                                    searchTag !== null
                                        ?
                                        searchTag
                                        :
                                        "none Selected"
                                }

                            </div>
                        </div>

                        <i className={FilterTag ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                    </button>

                    {
                        FilterTag
                            ?
                            <FilterTags />
                            :
                            null
                    }
                </div>

                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <label className='reset_btn mx-2'>
                            Reset
                        </label>
                    </div>

                    <button className='save_btn'>
                        Save As...
                    </button>
                </div>
            </div>


            {/* Message */}
            <div>
                {
                    TagModal
                    &&
                    <>
                        <div id="tag_overlay" onClick={() => { setTagModal(false) }}></div>
                        <Tagmenu CommentData={tagComment} />
                    </>
                }

                {
                    PostData !== null
                        ?
                        <>
                            {
                                PostData.map((elem) => {
                                    return (
                                        <div className='detail_message_section'>
                                            <div className='post_header message_detail_header'>
                                                {
                                                    elem.platform === "facebook"
                                                        ?
                                                        <img src={`https://graph.facebook.com/${elem.Page_Id}/picture?return_ssl_resources=true`} className='post_header_img' />
                                                        :
                                                        <Images Id={elem.Page_Id} />
                                                }

                                                <div style={{ width: '70%' }}>
                                                    <div>
                                                        {
                                                            elem.platform === "facebook"
                                                                ?
                                                                <i class="fa-brands fa-facebook"></i>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            elem.platform === "instagram"
                                                                ?
                                                                <i class="fa-brands fa-instagram"></i>
                                                                :
                                                                null
                                                        }
                                                        <label style={{ marginLeft: '10px' }}>
                                                            {elem.user_name}
                                                        </label>
                                                    </div>

                                                    {/* <label className='status'>
                                                        Direct Message to Space Owl
                                                    </label> */}
                                                </div>

                                                <div className='date_section'>
                                                    <label>
                                                        {
                                                            (elem.comment_time).split("T")[0] + ", " + ((elem.comment_time).split("T")[1]).split("+")[0]
                                                        }
                                                    </label>

                                                    <input type="checkbox" style={{ marginLeft: '20px' }} />
                                                </div>

                                            </div>

                                            <div className='message_detail'>
                                                <div className='text-center'>
                                                    <img src={elem.post_url} className='comment_post_img' />
                                                </div>

                                                <span>
                                                    {elem.comments}
                                                </span>

                                                <div className='message_footer'>
                                                    <button className='btn view_thread_btn' onClick={() => { ReplyPage(elem) }}>
                                                        View Thread
                                                    </button>

                                                    <div>

                                                        <button className='btn' onClick={() => { ReplyPage(elem) }}>
                                                            <i class="fa-solid fa-share fa-flip-horizontal"></i>
                                                        </button>

                                                        <button className='btn' onClick={() => { setTagModal(true); setTagComment(elem) }}>
                                                            <i class="fa fa-tag" aria-hidden="true"></i>
                                                        </button>

                                                        <button className='btn'>
                                                            <i class="fa-regular fa-circle-check"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        null
                }
            </div>
        </div>
    )
}
