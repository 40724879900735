import React,{useEffect} from 'react'
import axios from 'axios';


export default function Twitter() {
    useEffect(() => {
        console.log("enter")
        const getQueryParam = (name) => {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        };

        const codes = getQueryParam('code');

        let token;

        if(localStorage.getItem("user_Token"))
        {
            token=localStorage.getItem("user_Token");
        }
        else{
            token=sessionStorage.getItem("user_Token");
        }

        if (codes) {
            console.log('code',codes)
            axios.get(`${process.env.REACT_APP_BACKEND_API}/twitter/auth/callback`, {
                params: {
                    code: codes,
                },
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    console.log(res.data)
                    window.close()
                })
                .catch((err) => {
                    console.log(err)
                    window.close()
                })
        }
    },[])

    return (
        <div>Redirecting.......</div>
    )
}
