import { createSlice } from "@reduxjs/toolkit";

const ProfileSlice=createSlice({
    name:"ProfileSlice",
    initialState:[],
    reducers:{
        ProfileRedu(state,action){
            state.length=0          
            state.push(action.payload)
        },
    }
})

export {ProfileSlice}

export const {ProfileRedu}=ProfileSlice.actions