import { useEffect, useState, useMemo } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileRedu } from "./store/Slices/ProfileSlice";
import { ChangeSinceDate } from "./store/Slices/DateSlice";
import { ChangeUntilDate } from "./store/Slices/UntilDateSlice";
import { fetchUserProfile, fetchUserProfiles } from "./services/user.profile";

import ConnectProfile from "./Components/ConnectProfile";
import Notification from "./Components/Notification";
import CreateCampagin from "./Components/CreateCampagin";
import ErrorModal from "./Components/Error";
import Signup from "./Components/Signup";
import Frame from "./Components/Frame";
import Onboarding from "./Components/Onboarding";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Photographer from "./Components/Photographer";
import Organization from "./Components/Organization";
import ProfileConnect from "./Components/ProfileConnect";
import AssetsData from "./Components/AssetsData";
import ForgotPassword from "./Components/ForgotPassword";
import ResetPassword from "./Components/ResetPassword";
import ForgotPasswordConfirmation from "./Components/ForgotPasswordConfirmation";
import Facebook from './Components/Redirectpage/Facebook';
import Linkedin from './Components/Redirectpage/Linkedin';
import Youtube from './Components/Redirectpage/Youtube';
import Instagram from './Components/Redirectpage/Instagram';
import Pinterest from './Components/Redirectpage/pinterest';
import Preview from './Components/Preview';
import Twitter from './Components/Redirectpage/Twitter';

function App() {
  const dispatch = useDispatch();
  const ProfileSelector = useSelector((state) => state.Profiles);
  const [token, setToken] = useState(localStorage.getItem("user_Token"));
  const [isActivePlan, setActivePlan] = useState(true);

  useEffect(() => {
    const setDates = () => {
      const d = new Date();
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const day = d.getDate().toString().padStart(2, "0");
      const sinceDate = Date.parse(`${d.getFullYear()}/${month}/01`) / 1000;
      const untilDate = Date.parse(`${d.getFullYear()}/${month}/${day}`) / 1000;
      dispatch(ChangeSinceDate(sinceDate));
      dispatch(ChangeUntilDate(untilDate));
    };

  

    setDates();
   
  }, [dispatch, token]);

  const protectedRoutes = useMemo(
    () => [
      { path: "/Dashboard", component: Dashboard },
      { path: "/Dashboard/*", component: Dashboard },
      { path: "/Organization", component: Organization },
      { path: "/AssetsData", component: AssetsData },
      { path: "/Photographer", component: Photographer },
      { path: "/ProfileConnect", component: ProfileConnect },
      { path: "/ConnectProfile", component: ConnectProfile },
      { path: "/Notification", component: Notification },
      { path: "/Frame", component: Frame },
    ],
    []
  );

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer position="top-right" autoClose={5000} />
        <Routes>
          <Route path="/" element={token ? (isActivePlan ? <Navigate to="/Dashboard" /> : <Onboarding />) : <Login />} />
          <Route path="/Signup" element={token ? (isActivePlan ? <Navigate to="/Dashboard" /> : <Signup />) : <Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/forgot-password-confirmation" element={<ForgotPasswordConfirmation />} />
          <Route path="/Onboarding" element={<Onboarding />} />
          {protectedRoutes.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={token ? (isActivePlan ? <Component /> : <Navigate to="/Onboarding" />) : <Navigate to="/" />}
            />
          ))}
          <Route path="/Preview" element={<Preview />} />
          <Route path="/FBRedirect" element={<Facebook />} />
          <Route path="/LinkRedirect" element={<Linkedin />} />
          <Route path="/YTRedirect" element={<Youtube />} />
          <Route path="/instaRedirect" element={<Instagram />} />
          <Route path="/PintRedirect" element={<Pinterest />} />
          <Route path="/twitterRedirect" element={<Twitter />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
